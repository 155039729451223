
import store from "../store/index";
import { updateCPLTableColumns } from "@/apiClient/campaignEndpoints";
var moment = require("moment");

async function deleteAllTrackers(id:string) {
    fetch(`${store.state.serverAddress}api/v1/campaign/deleteAllTrackers/${id}`, {
        method: "PUT",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.auth.key}`,
        }

})
.then(res => {
    console.log('response', res)
})
.catch(err => {
    console.log('error',err)
})
}


 async function addAcctNum () {
              fetch(`${store.state.serverAddress}api/v1/campaign/addAcctNum`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.key}`,
                },
                body: JSON.stringify({
                    acctNum: await store.state.crAcctNum,
                    campaignId: await store.state.campaignNumber,
                }),
            })
                // PARSE RESPONSE BODY
                .then((res) => {
                    store.state.crAcctMessage = res.statusText
                    store.state.acctNum = store.state.crAcctNum
                })
        
                .catch((err) => {
                    console.log(
                        "failed to update account number on campaign",
                        err
                    );
                })
            }

           function updateCRThisYear() {
                fetch(`${store.state.serverAddress}api/v1/updateSingleColumnTY`, {
                method: "POST",
                mode: "cors",
                headers: {
                    Authorization: `Bearer ${store.state.auth.key}`,
                    "Content-Type": "application/json"
    
                },
                body: JSON.stringify({
                    id: store.state.campaignNumber,
                   
                })
            })
    
                }
    
                   async function sureDeleteTracker(trackerId:any) {
                       store.state.trackerDeleteSure.trackerId = trackerId
                   }
    
            async function getNewCRData() {
                    updateCRThisYear()
                    updateCPLTableColumns()
                    getNewData()
                   }
    
    
            async function deleteTracker(trackerId:any) {
                let campaignId = await store.state.campaignNumber
                            await fetch(
                    `${store.state.serverAddress}api/v1/campaign/deleteTracker`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                        body: JSON.stringify({
                            id: campaignId,
                            trackerId: trackerId
                        }),
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
                        getTrackerInfo()
                            getNewCRData()
                           console.log(data)
                    
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                store.state.addCampaign.loading = false;
            }
    
            
    
    
    
            async function openPaused() {
                store.state.paused = true;
    
            }
    
            async function sortedThirty() {
                store.state.customers = store.state.regSorterHolder;
                let negative:any = [];
                let positive:any = [];
                for (let i = 0; i < store.state.customers.length; i++) {
                    let currentNumber = store.state.customers[i]['thirtyOverThirty'];
                    let currentCampaign = store.state.customers[i];
                    if (currentNumber < 0) {
                        negative.push(currentCampaign);
                    } else {
                        positive.push(currentCampaign);
                    }
                }
                let negSort = null;
                let posSort = null;
    
                if (store.state.thirtyOverThirtySort === false) {
    
                    posSort = positive.sort((a:any, b:any) => {
                        return a.thirtyOverThirty - b.thirtyOverThirty;
                    });
                    negSort = negative.sort((a:any, b:any) => {
                        return a["thirtyOverThirty"] - b["thirtyOverThirty"];
                    });
    
                    store.state.customers = negSort.concat(posSort);
                    store.state.thirtyOverThirtySort = true;
                } else {
                    posSort = positive.sort((a:any, b:any) => {
                        if (b.thirtyOverThirty > a.thirtyOverThirty) {
                            return -1;
                        } else {
                            return 1;
                        }
                        return 0;
                    });
                    negSort = negative.sort((a:any, b:any) => {
                        return b["thirtyOverThirty"] - a["thirtyOverThirty"];
                    });
    
                    store.state.customers = await posSort.concat(negSort);
                    store.state.thirtyOverThirtySort = false;
                }
            }
    
            async function getNewData() {
            fetch(`${store.state.serverAddress}api/v1/campaign/readallForKpi`, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: `Bearer ${store.state.auth.key}`,
                },
            })
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((list) => {
                    let arr:any = [];
                    store.state.customers = list.data;
                    store.state.regSorterHolder = arr;
                })
    
                .catch((err) => {
                    console.log(err);
                });
            }
    
            async function setToPaused() {
                const id = store.state.campaignNumber;
    
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/setToPaused/${id}`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 202) {
                            store.state.isPaused = true;
                            getNewData()
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
             async function setHasCallRailToNo() {
                const id = await store.state.campaignNumber;
    console.log('ID', id)
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/setHasCallRailNo/${id}`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 202) {
                            
                            getNewData()
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
                     async function setHasCallRailToYes() {
                const id = await store.state.campaignNumber;
    console.log('ID', id)
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/setHasCallRailYes/${id}`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 202) {
                            
                            getNewData()
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
    
             async function setHasCallRailToPending() {
                const id = await store.state.campaignNumber;
    console.log('ID', id)
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/setHasCallRailPending/${id}`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 202) {
                            
                            getNewData()
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
    
    
    
            async function addToAuditTable() {
                const id = await store.state.campaignNumber;
    
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/addToAuditTable/${id}`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 202) {
                            store.state.addToAudit = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
            async function deleteCrInfo() {
                const id = store.state.campaignNumber;
    
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/deleteCr/${id}`,
                    {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => {
                        if (res.status === 202) {
                            store.state.deleteSuccess = true;
                        } else {
                            store.state.deleteWarning = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
            async function pauseCampaign(id:any) {
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/updateStatus/${id}`,
                    {
                        method: "PUT",
                        mode: "cors",
                        headers: {
                            Authorization: `Bearer ${store.state.auth.key}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            status: 2,
                        }),
                    }
                );
    
                // store.state.idStatus = "";
                store.state.status = "";
            }
    
            async function openCampaignModal() {
                store.state.addCampaign.modal = true;
                store.state.addCampaign.search = true;
                getNewCampaigns();
            }
    
            async function closeCampaignModal() {
                store.state.addCampaign.modal = false;
                store.state.addCampaign.campaignName = "";
                store.state.addCampaign.resourceName = "";
                store.state.addCampaign.id = "";
                store.state.campaignSuccess = false;
                store.state.campaignWarning = false;
            }
    
            async function addNewCampaign() {
                store.state.addCampaign.loading = await true;
                const id = await store.state.addCampaign.id;
                await fetch(
                    `${store.state.serverAddress}api/v1/campaign/addNewCampaignManually`,
                    {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                        body: JSON.stringify({
                            id: id,
                        }),
                    }
                )
                    .then((res) => {
                        if (res.status === 201) {
                            store.state.campaignSuccess = true;
                        } else {
                            store.state.campaignWarning = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                store.state.addCampaign.loading = false;
            }
    
            async function getNewCampaigns() {
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/getNewAccounts`,
                    {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    // PARSE RESPONSE BODY
                    .then((res) => res.json())
                    .then((data) => {
                        store.state.addCampaign.list = data;
                    });
            }
    
            async function setNewCampaign(id:any, name:any, resourcName:any) {
                store.state.addCampaign.campaignName = await name;
                store.state.addCampaign.id = await id;
                store.state.addCampaign.resourceName = await resourcName;
                store.state.addCampaign.search = false;
                store.state.addCampaign.chooseCampaign = "";
            }
    
            async function setTrackerInfo(tracking:any, name:any) {
                store.state.trackerId = await tracking;
                store.state.callRailName = await name;
                console.log('WORKING')
            }
    
            async function areYouSure() {
                store.state.sure = true;
            }
    
           async function getCampaignTracker(acctNum:any) {
            console.log('NUMBER', acctNum)
                store.state.isTrackingLoading = true;
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/getTrackers/${acctNum}`,
                    {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    // PARSE RESPONSE BODY
                    .then((res) => res.json())
                    .then((list) => {
                        // store.state.trakers = list.trackers;
                        let allTracks = list.trackers;
                        let finarlArr:any = [];
                        for (let i = 0; i < allTracks.length; i++) {
                            let currObj = allTracks[i];
                            for (let key in currObj) {
                                let currPage = currObj[key];
                                for (let item in currPage) {
                                    let currAccount = currPage[item];
                                    finarlArr.push([
                                        currAccount.name,
                                        currAccount.id,
                                        currAccount.tracking_numbers,
                                        currAccount.company.name,
                                    ]);
                                }
                            }
                        }
                        
                        store.state.trackers = finarlArr;
                        store.state.isTrackingLoading = false;
                        store.state.addTracker = true;
                        store.state.choice = false;
                    })
    
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
           async function getTracker() {
                store.state.choice = true;
                store.state.getData = false;
                store.state.addCrAccount = false;
                store.state.crAcctNum = "";
               await getTrackerInfo();
    
               await getCampaignTracker(store.state.acctNum);
                store.state.closeHasCallRail = false
            }
    
           async function addAccount() {
                store.state.choice = false;
                store.state.getData = false;
                store.state.addTracker = false;
                store.state.addCrAccount = true;
                store.state.closeHasCallRail = false
            }
    
           async function getPastData() {
                store.state.addTracker = false;
                store.state.choice = false;
                store.state.getData = true;
              store.state.closeHasCallRail = false
    
            }
    
    
    
           async function customFormatter(date:any) {
                return moment(date).format("MMMM Do YYYY, h:mm:ss a");
            }

           async function reset() {
                store.state.isValidated = false;
                store.state.snippet = "";
            }

          async function resetData() {
                store.state.calls = "";
                store.state.imp = "";
                store.state.ctr = "";
                store.state.cost = "";
            }
    
           async function generateSnippet() {
                fetch(`${store.state.serverAddress}api/v1/keycodes/create`, {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                })
                    // PARSE RESPONSE BODY
                    .then((res) => res.json())
                    .then((key) => {
                        store.state.snippet = `<${key.data}>`;
                    })
    
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
            async function validateSnippet() {
                try {
                    fetch(`${store.state.serverAddress}api/v1/keycodes/create`, {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                        body: JSON.stringify({
                            data: store.state.snippet,
                        }),
                    })
                        .then((res) => {
                            if (res.status === 201) {
                                store.state.isValidated = true;
                            } else {
                                store.state.warning = true;
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } catch (err) {
                    console.log(err);
                }
            }
    
            async function addTrackerId() {
                fetch(`${store.state.serverAddress}api/v1/campaign/addTracker`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                    body: JSON.stringify({
                        tracker: await store.state.trackerId,
                        campaignId: await store.state.campaignNumber,
                    }),
                })
                    // PARSE RESPONSE BODY
                    .then((res) => res.json())
                    .then((data) => {
                        store.state.trackerSuccess = true;
                   getTrackerInfo()
                    updateCRThisYear()
                    updateCPLTableColumns()
                    })
    
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
            async function getTrackerInfo() {
                let id = store.state.campaignNumber;
                console.log("ID at Tracker", id)
                let trackData = null
               await fetch(
                    `${store.state.serverAddress}api/v1/campaign/getTrackerInfo/${id}`,
                    {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    .then((res) => res.json())
                    .then((data) => {
    console.log(data)
                   trackData = data
                        // store.state.trackerInfo.campaignName = data.name;
                        // store.state.trackerInfo.campaingCreationDate = data.created_at;
                        // store.state.trackerInfo.destinationNumber = data.destination_number;
                        // store.state.trackerInfo.trackingNumber = data.tracking_numbers;
                        // store.state.trackerInfo.status = data.status;
                        // store.state.trackerInfo.companyName = data.company.name;
                    })
                    .catch((err) => {
                        console.log("error getting tracking info", err);
                    });
                    console.log('trackData', trackData)
                    store.state.currTrackerInfo = await trackData
                    console.log('INFO',store.state.currTrackerInfo)
            }
    
    
    
           async function getLastThirtdays(campaignId:any, startDate:any, endDate:any) {
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/getAllDataByDate`,
                    {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    // PARSE RESPONSE BODY
                    .then((res) => res.json())
                    .then((data) => {
                        //
    
                    })
    
                    .catch((err) => {
                        console.log(err);
                    });
            }
    
             function  filteredClients(customers:Array<string>) {
                const search = store.state.searchHolder.toLowerCase().trim();
    
                if (!search) return customers;
    
                return customers.filter(
                    (c:any) =>
                        c.customer.customerName.toLowerCase().indexOf(search) > -1
                );
            }
        
           async function filteredCrAccounts(account:any) {
                const search = store.state.searchAccount.toLowerCase().trim();
    
                if (!search) return account;
    
                return account.filter(
                    (c:any) => c[0].toLowerCase().indexOf(search) > -1
                );
            }
    
           async function filteredNewCamps(camps:any) {
                const search = store.state.addCampaign.chooseCampaign.toLowerCase().trim();
    
                if (!search) return camps;
    
                return camps.filter(
                    (c:any) => c.descriptive_name.toLowerCase().indexOf(search) > -1
                );
            }
           async function filteredByName() {
                if (!store.state.sortedAlph) {
                    store.state.sortedAlph = true;
                    return store.state.customers.sort((a:any, b:any) => {
                        if (a.customer.customerName < b.customer.customerName)
                            return -1;
                        if (a.customer.customerName > b.customer.customerName)
                            return 1;
                        return 0;
                    });
                } else {
                    store.state.sortedAlph = false;
                    return store.state.customers.sort((a:any, b:any) => {
                        if (a.customer.customerName > b.customer.customerName)
                            return -1;
                        if (a.customer.customerName < b.customer.customerName)
                            return 1;
                        return 0;
                    });
                }
            }
    
           async function filterDataByValues(type:any, limit:any) {
                return (store.state.customers = store.state.customers.filter((item) => {
                    if (
                        typeof item[type] === "number" &&
                        Number(item[type]) > limit &&
                        item[type] !== null
                    ) {
                        return item;
                    }
                }));
            }
    
            async function filteredByKpi1() {
                if (!store.state.sortedByKpi1) {
                    store.state.sortedByKpi1 = true;
    
                    return store.state.customers.sort((a:any, b:any) => {
    
                        return Number(b['costType']) - Number(a['costType']);
                    });
                } else {
                    store.state.sortedByKpi1 = false;
    
                    return store.state.customers.sort((a:any, b:any) => {
                        return Number(a['costType']) - Number(b['costType']);
                    });
                }
            }
            async function filteredByCost(costType:any) {
                // store.state.customers = [...store.state.regSorterHolder];
                if (costType === "thirtyOverThirty") {
    
    return store.state.customers.sort((a,b)=> (b[costType] - a[costType]) )
                } else {
                    if (!store.state.sortedByCost1 && costType) {
                        store.state.sortedByCost1 = true;
                        return store.state.customers.sort((a, b) => {
                            if (a[costType] < b[costType]) return -1;
    
                            if (a[costType] > b[costType]) return 1;
                            return 0;
                        });
                    } else {
                        store.state.sortedByCost1 = false;
    
                        return store.state.customers.sort((a, b) => {
                            if (a[costType] > b[costType]) return -1;
                            if (a[costType] < b[costType]) return 1;
                            return 0;
                        });
                    }
                }
            }
    
            async function filteredByKpi() {
                if (!store.state.kpi1) {
                    store.state.kpi1 = true;
                    return store.state.customers.sort((a, b) => {
                        return (
                            Number(a["lastOneDayCost"]) / Number(a["oneDayCalls"]) -
                            Number(b["lastOneDayCost"]) / Number(b["oneDayCalls"])
                        );
                    });
                } else {
                    store.state.kpi1 = false;
                    return store.state.customers.sort((a, b) => {
                        return (
                            Number(b["lastOneDayCost"]) / Number(b["oneDayCalls"]) -
                            Number(a["lastOneDayCost"]) / Number(a["oneDayCalls"])
                        );
                    });
                }
            }
    
            async function getCamaignByDate(startDate:any, endDate:any) {
                fetch(
                    `${store.state.serverAddress}api/v1/campaign/readbydateNew/${store.state.campaignNumber}/${startDate}/${endDate}`,
                    {
                        method: "GET",
                        mode: "cors",
                        headers: {
                            Authorization: `Bearer ${store.state.auth.key}`,
                        },
                    }
                )
                    // PARSE RESPONSE BODY
                    .then((res) => res.json())
                    .then((data) => {
                        store.state.imp = data.imp;
                        store.state.cost = data.cost;
                        store.state.calls = data.calls;
                        store.state.cpc = data.cpc;
                        store.state.ctr = data.ctr;
                    })
    
                    .catch((err) => {
                        console.log(err);
                    });
            }

           async function sortByName(list:any, name:string){
              console.log(list)
              console.log(name)

            if (name !== 'customerName') {
              if (store.state.sortListDesc === false) {
               await list.sort(function(a:any, b:any) {
                    var nameA = a[name].toLowerCase(); // ignore upper and lowercase
                    var nameB = b[name].toLowerCase(); // ignore upper and lowercase
                    if (nameA < nameB) //sort string ascending
                    return -1 
                if (nameA > nameB)
                      return 1;
                    })
                    store.state.sortListDesc = true 

                } else if(store.state.sortListDesc === true) {
                    await list.sort(function(a:any, b:any) {
                        var nameA = a[name].toLowerCase(); // ignore upper and lowercase
                        var nameB = b[name].toLowerCase(); // ignore upper and lowercase
                        if (nameA > nameB) //sort string ascending
                    return -1 
                if (nameA < nameB)
                    return 1
                    
                    })
                    store.state.sortListDesc = false 
                }
            } else {

                //nested customer object - optomize later
                if (store.state.sortListDesc === false) {
                    await list.sort(function(a:any, b:any) {
                         var nameA = a['customer'][name].toLowerCase(); // ignore upper and lowercase
                         var nameB = b['customer'][name].toLowerCase(); // ignore upper and lowercase
                         if (nameA < nameB) //sort string ascending
                         return -1 
                     if (nameA > nameB)
                           return 1;
                         })
                         store.state.sortListDesc = true 
     
                     } else if(store.state.sortListDesc === true) {
                         await list.sort(function(a:any, b:any) {
                             var nameA = a['customer'][name].toLowerCase(); // ignore upper and lowercase
                             var nameB = b['customer'][name].toLowerCase(); // ignore upper and lowercase
                             if (nameA > nameB) //sort string ascending
                         return -1 
                     if (nameA < nameB)
                         return 1
                         
                         })
                         store.state.sortListDesc = false 
                     }
     
            }
            }
            
            
                
    

 export {
     sortByName,
     addAcctNum,
     updateCPLTableColumns,
     updateCRThisYear,
     getCamaignByDate,
     getCampaignTracker,
     getLastThirtdays,
     getNewCRData,
     getNewCampaigns,
     getNewData,
     getPastData,
     getTracker,
     getTrackerInfo,
     deleteCrInfo,
     deleteTracker,
     sureDeleteTracker,
     openCampaignModal,
     openPaused,
     sortedThirty,
     setToPaused,
     setHasCallRailToNo,
     setHasCallRailToYes,
     setHasCallRailToPending,
     setNewCampaign,
     setTrackerInfo,
     generateSnippet,
     validateSnippet,
     filterDataByValues,
     filteredByCost,
     filteredByKpi,
     filteredByName,
     filteredClients,
     filteredCrAccounts,
     customFormatter,
     filteredByKpi1,
     addTrackerId,
     addAccount,
     areYouSure,
     addNewCampaign,
     closeCampaignModal,
     pauseCampaign,
     addToAuditTable,
     deleteAllTrackers

 }