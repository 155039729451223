












































































































import { Component, Prop, Vue } from "vue-property-decorator";

import {
    customerMagicUpdate,
    customerReadAllPretty,
} from "@/apiClient/customerEndpoints";
import {
    searchTermYesterday,
    searchTermRunRecent,
    searchTermProcess,
} from "../../apiClient/searchTermEndpoints";
import { adGroupTypeReadAll } from "@/apiClient/adGroupTypeEndpoints";
import { campaignTypeReadAll } from "@/apiClient/campaignTypeEndpoints";
import { categoryReadAll } from "@/apiClient/categoryEndpoints";
import { newestCampaigns, auditList } from "@/apiClient/campaignEndpoints";
import { highestAutoRepair } from "../../apiClient/nightwatchEndpoints";

// import API

@Component
export default class UserView extends Vue {
    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;

    // BUTTON
    private btnLoad?: HTMLButtonElement;

    // MOUNTED
    public async mounted() {
        console.log("mounted()");

        async function getDashData() {
            await newestCampaigns();
            await highestAutoRepair();
            await auditList();
        }
        getDashData();
        console.log(this.$store.state.highestAutoRepair);
        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement>document.getElementById("btnLoad");

        // let allDataPromises = [];
        // allDataPromises.push(campaignTypeReadAll());
        // allDataPromises.push(categoryReadAll());
        // allDataPromises.push(customerReadAllPretty());
        // allDataPromises.push(adGroupTypeReadAll());
        // @ts-ignore
        // await Promise.all(allDataPromises);

        // this.readUser();
    }

    // BUTTON BINDS
    public async magicUpdate() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            await customerMagicUpdate();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
    public async searchTermYesterday() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            await searchTermYesterday();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
    public async searchTermProcess() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            await searchTermProcess();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
}
