






































































































































































// import vue-property-decorator
import { 
    Component, 
    Prop, 
    Vue 
} from 'vue-property-decorator';
// imprort CONSTANTS
import { CONSTANTS } from "../../apiClient/constants";

// import API
import { adGroupQuickSetType, adGroupRead, adGroupSetType, adGroupUnsetType } from '@/apiClient/adGroupEndpoints';
import { customerReadAllPretty, customerReadPretty } from '@/apiClient/customerEndpoints';
import { adGroupTypeReadAll } from '@/apiClient/adGroupTypeEndpoints';
import { negativeActionApplyToAdGroup, negativeActionGetForAdGroup, negativeActionValidateForAdGroup } from '@/apiClient/negativeActionEndpoints';

@Component
export default class AdGroupView extends Vue {

    @Prop()
    private adGroupId!: any;

    // ATTACH CONSTANTS
    private CONSTANTS = CONSTANTS;

    private adGroupData: any = null;

    // Button loaders
    private quickSetTypeButtonLoading: boolean = false;
    private setTypeButtonLoading: boolean = false;
    private unsetTypeButtonLoading: boolean = false;

    private negativeActionsExpanded: boolean = false;
    private advancedNegativeActions: any = {};

    // NEGATIVE ACTIONS
    private negativeActionsList = [];
    private hash: any = null;

    private applying: boolean = false;
    private applied: boolean = false;
    private validating: boolean = false;
    private validated: boolean = false;


    // MOUNTED
    public async mounted() {
        console.log("mounted()");
        this.adGroupData = await adGroupRead(this.adGroupId);
    };

    // BUTTON BINDS
    public async setAdGroupType(adGroupId: string, adGroupTypeId: number) {
        this.setTypeButtonLoading = true;
        await adGroupSetType(adGroupId, adGroupTypeId);
        await customerReadAllPretty();
        await customerReadPretty(this.$store.state.customerCache.customer.customerId);

        for (let campaign of this.$store.state.customerCache.customer.campaigns) {
            for (let adGroup of campaign.adGroup) {
                if (adGroup.adGroupId === this.adGroupId) {
                    this.adGroupData = adGroup;
                }
            }
        }
        this.setTypeButtonLoading = false;
    };
    public async quickSetAdGroupType(adGroupId: string, adGroupTypeName: string) {
        this.quickSetTypeButtonLoading = true;
        await adGroupQuickSetType(adGroupId, adGroupTypeName);
        await customerReadAllPretty();
        await customerReadPretty(this.$store.state.customerCache.customer.customerId);
        await adGroupTypeReadAll();

        for (let campaign of this.$store.state.customerCache.customer.campaigns) {
            for (let adGroup of campaign.adGroup) {
                if (adGroup.adGroupId === this.adGroupId) {
                    this.adGroupData = adGroup;
                }
            }
        }
        this.quickSetTypeButtonLoading = false;
    };
    public async unsetAdGroupType(adGroupId: string) {
        console.log("UNSET");
        this.unsetTypeButtonLoading = true;

        let promiseList = [];
        await adGroupUnsetType(adGroupId);
        promiseList.push(customerReadAllPretty());
        promiseList.push(customerReadPretty(this.$store.state.customerCache.customer.customerId));
        promiseList.push(adGroupTypeReadAll());
        // @ts-ignore
        await Promise.all(promiseList);

        // await adGroupUnsetType(adGroupId);
        // await customerReadAllPretty();
        // await customerReadPretty(this.$store.state.customerCache.customer.customerId);
        // await adGroupTypeReadAll();

        for (let campaign of this.$store.state.customerCache.customer.campaigns) {
            for (let adGroup of campaign.adGroup) {
                if (adGroup.adGroupId === this.adGroupId) {
                    this.adGroupData = adGroup;
                }
            }
        }


        this.unsetTypeButtonLoading = false;

        this.$forceUpdate;
        console.log("UNSET");
    };

    public toggleAdGroupNegativeActions() {
        this.negativeActionsExpanded = !this.negativeActionsExpanded;
    };

    public async readAdGroupNegativeActions() {
        this.advancedNegativeActions = await negativeActionGetForAdGroup(this.adGroupId);
        if (this.advancedNegativeActions.negativeActions.length > 0) {
            this.negativeActionsList = this.advancedNegativeActions.negativeActions;
        }
    };
    public async hideAdGroupNegativeActions() {
        this.negativeActionsList = [];
    };

    public async negativeKeywordsValidate() {
        this.validating = true;
        // @ts-ignore
        let result = await negativeActionValidateForAdGroup(this.adGroupId, this.advancedNegativeActions.hash);
        if (result) {
            console.log(result);
            this.hash = result;
            this.validated = true;
        }
        else {
            this.validated = false;
        }
        this.validating = false;
    };
    public async negativeKeywordsHandle() {
        this.applying = true;
        let result = await negativeActionApplyToAdGroup(this.adGroupId, this.hash);
        if (result) {
            this.applied = true;
        }
        else {
            this.applied = false;
        }
        this.applying = false;
    };
    public getRelevantAdGroupType(adGroupTypeId: any) {
        for (let adGroupType of this.$store.state.adGroupTypeCache.adGroupTypeList) {
            if (adGroupType.id === parseInt(adGroupTypeId)) {
                return adGroupType.name;
            }
        }
    };

};
