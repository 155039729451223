









import { Component, Vue } from 'vue-property-decorator';

// import components
import MasterListCreate from '@/components/masterList/MasterListCreate.vue';
import MasterListView from '@/components/masterList/MasterListView.vue';
import MasterListList from '@/components/masterList/MasterListList.vue';

@Component({
    components: {
        MasterListList,
        MasterListView,
        MasterListCreate,
    },
})
export default class MasterListPage extends Vue {};
