






































import { Component, Vue } from 'vue-property-decorator';

import { customerMagicUpdate } from '@/apiClient/customerEndpoints';
import { searchTermYesterday, searchTermProcess } from "../../apiClient/searchTermEndpoints";
import { campaignGetNegativeKeywordsCount } from '@/apiClient/campaignEndpoints';
import { adGroupGetNegativeKeywordsCount } from '@/apiClient/adGroupEndpoints';
import { statusMultiplayerResetCache } from '@/apiClient/statusEndpoints';

// import API

@Component
export default class SystemControls extends Vue {

    // LOADING
    private loadingMagicUpdate: boolean = false;
    private loadingSearchTermYesterday: boolean = false;
    private laodingSearchTermProcess: boolean = false;
    private loadingGetCampaignNegativeKeywords: boolean = false;
    private loadingGetAdGroupNegativeKeywords: boolean = false;
    private loadingMultiplayerResetCache: boolean = false;

    // MOUNTED
    public mounted() {
        console.log("mounted()");
    };

    // BUTTON BINDS
    public async magicUpdate() {
        this.loadingMagicUpdate = true;
        try {
            await customerMagicUpdate();
        } catch (error) {
            console.log("EW");
        }
        this.loadingMagicUpdate = false;
    };
    public async searchTermYesterday() {
        this.loadingSearchTermYesterday = true;
        try {
            await searchTermYesterday();
        } catch (error) {
            console.log("EW");
        }
        this.loadingSearchTermYesterday = false;
    };
    public async searchTermProcess() {
        this.laodingSearchTermProcess = true;
        try {
            await searchTermProcess();
        } catch (error) {
            console.log("EW");
        }
        this.laodingSearchTermProcess = false;
    };
    public async getCampaignNegativeKeywords() {
        this.loadingGetCampaignNegativeKeywords = true;
        try {
            await campaignGetNegativeKeywordsCount();
        } catch (error) {
            console.log("EW");
        }
        this.loadingGetCampaignNegativeKeywords = false;
    };
    public async getAdGroupNegativeKeywords() {
        this.loadingGetAdGroupNegativeKeywords = true;
        try {
            await adGroupGetNegativeKeywordsCount();
        } catch (error) {
            console.log("EW");
        }
        this.loadingGetAdGroupNegativeKeywords = false;
    };
    public async resetMultiplayerCache() {
        this.loadingMultiplayerResetCache = true;
        try {
            await statusMultiplayerResetCache();
        }   
        catch (error) {
            console.log(error);
        }
        this.loadingMultiplayerResetCache = false;
    }

};
