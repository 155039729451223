
















































import { Component, Vue } from 'vue-property-decorator';

// import API
import { categoryReadAll } from '@/apiClient/categoryEndpoints';

@Component
export default class CategoryList extends Vue {

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        categoryReadAll();

    };
    public async readCategoryList() {
        await categoryReadAll();
    };

    public initEdit(categoryId: number) {
        this.$store.commit("updateCategoryCache", {
            categoryId: categoryId
        })
    };
};
