<template>
    <!-- THIS DIV IS SACRED -->

    <div>

        <!-- CONTAINER -->
        <div class="container mb-2">

            <div class="box">

                <!-- HEADING -->
                <p class="title is-4">Bad List</p>
                <p class="subtitle is-6">A list of all the Bad actions in the system</p>
                    <button class="button is-danger is-pulled-right" v-on:click="getAllGoodList">
                        All of Good List
                    </button>

                <div v-if="badList.length > 0" class="">

                    <!-- TABLE -->
                    <table class="table is-fullwidth is-hoverable is-narrow">
                        <thead>
                            <tr>
                                <th title="Sort alphabetically by Text" @click="sortColumnText()">text*</th>
                                <th>destination</th>
                                <th>created</th>

                                <!-- <th>match type</th>
                                <th>status</th>
                                <th>manage</th> -->
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th title="Sort alphabetically by Text" @click="sortColumnText()">text*</th>
                                <!-- <th>match type</th>
                                <th>action type</th>
                                <th>destination</th>
                                <th>status</th>
                                <th>manage</th> -->
                            </tr>
                        </tfoot>
                        
                        <tbody>
                            <!-- TR FOR NEGATIVE ACTION -->
                            <tr v-for="list of badList" v-bind:key="list.id">
                                <!-- TEXT -->
                                <!-- <th> {{ goodList.text }} </th> -->
                                <th> [{{ list.searchTerm.text }}] </th>
                                <!-- MATCH TYPE -->
                               <th> [{{ list.destination }}] </th>
                                   <th> [{{ list.createdAt }}] </th>

                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <p>It look's like there aren't any <strong>Bad Lists </strong></p>
                </div>

            </div>

            

        </div>

    </div>

</template>

<script>

import store from "../../store/index";

// import API


export default  ({
methods: {
    getAllGoodList() {
            fetch(`${store.state.serverAddress}api/v1/searchterm/goodlistAll`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(list => {
          this.badList = list.data
        
            })
        
        .catch(err => {
            console.log(err)
        })

        

    }
},

    data() {

       return {
           badList: []
       }

},
         mounted() {

        // MAKE REQUEST
       fetch(`${store.state.serverAddress}api/v1/searchterm/badlist`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(list => {
          this.badList = list.data
        
            })
        
        .catch(err => {
            console.log(err)
        })


    }


})


</script>

<style scoped>
</style>