// import vue
import Vue from 'vue';
// import app
import App from './App.vue';
// import router
import router from './router';
// import store
import store from './store';
import  {deleteAllTrackers, sortByName, deleteTracker, addAcctNum, updateCRThisYear, getCamaignByDate, generateSnippet, getCampaignTracker, getLastThirtdays, getNewCRData, getNewCampaigns, getNewData, getPastData, getTracker, getTrackerInfo, setHasCallRailToNo, setHasCallRailToPending, setHasCallRailToYes, setNewCampaign, setToPaused, setTrackerInfo, sortedThirty, sureDeleteTracker, areYouSure, addAccount, addNewCampaign, addToAuditTable, addTrackerId, openCampaignModal, openPaused, validateSnippet, filterDataByValues, filteredByCost, filteredByKpi, filteredByKpi1, filteredByName, filteredClients, filteredCrAccounts, closeCampaignModal, customFormatter} from './apiClient/mixinEndpointsAndFunc'


Vue.mixin({
    methods: {
        deleteAllTrackers: deleteAllTrackers,
        deleteTracker: deleteTracker,
        sortByName: sortByName,
         addAcctNum: addAcctNum,      
         updateCRThisYear: updateCRThisYear,
            getCamaignByDate: getCamaignByDate,
            generateSnippet: generateSnippet,
            getCampaignTracker: getCampaignTracker,
            getLastThirtdays: getLastThirtdays,
            getNewCRData: getNewCRData,
            getNewCampaigns: getNewCampaigns,
            getNewData: getNewData,
            getPastData: getPastData,
            getTracker: getTracker,
            getTrackerInfo: getTrackerInfo,
            setHasCallRailToNo: setHasCallRailToNo,
            setHasCallRailToPending: setHasCallRailToPending,
            setHasCallRailToYes: setHasCallRailToYes,
            setNewCampaign: setNewCampaign,
            setToPaused: setToPaused,
            setTrackerInfo: setTrackerInfo,
            sortedThirty: sortedThirty,
            sureDeleteTracker: sureDeleteTracker,
            areYouSure: areYouSure,
            addAccount: addAccount,
            addNewCampaign: addNewCampaign,
            addToAuditTable: addToAuditTable,
            addTrackerId: addTrackerId,
            openCampaignModal: openCampaignModal,
            openPaused: openPaused,
            validateSnippet: validateSnippet,
            filterDataByValues: filterDataByValues,
            filteredByCost: filteredByCost,
            filteredByKpi: filteredByKpi,
            filteredByKpi1: filteredByKpi1,
           filteredByName: filteredByName,
           filteredCrAccounts: filteredCrAccounts,
           filteredClients: filteredClients,
           closeCampaignModal: closeCampaignModal,
            customFormatter: customFormatter

        }    
  })

// setup
Vue.config.productionTip = false;

// create new Vue
new Vue({
    router,
    store,  
    render: h => h(App),
    beforeCreate () {
        // AUTH_INIT
        this.$store.commit("AUTH_INIT");
    },
    async created() {
        router.push("/");
    }
}).$mount('#app');