<template>
    <!-- THIS DIV IS SACRED -->

    <div>
<div>

<h1>UPDATE CALLRAIL COLUMNS</h1>
             <input
                    v-model="campaignForCallRailLY"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter campaignId of column update for LAST YEAR AND THIS YEAR"
                />
                <button @click="updateCRLastYear()">Last Year</button>
                             <input
                    v-model="campaignForCallRailTY"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter campaignId of column update for LAST YEAR AND THIS YEAR"
                />

                <button @click="updateCRThisYear()">This Year</button>

</div>

<div>
<h1>ADD TRACKER AND ACCOUNT # FOR CALLRAIL</h1>
             <input
                    v-model="trackNum"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter client trackNum"
                />
             <input
                    v-model="acctNum"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter client call rail acct#"
                />
             <input
                    v-model="campaignId"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter client id"
                />

<button @click="processCallInfo()">Update Account and Tracker #s Columns</button>
</div>
<div class="single">
<h1>UPDATE Single Campaign</h1>
             <input
                    v-model="idForOne"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter campaignId of column update for LAST YEAR AND THIS YEAR"
                />
                <button @click="updateCols()">Update This Column</button>

</div>

<div>
      

        <div class="ads-flex">
            
            <div>
        <header>GoogleAds21</header>
        <button v-on:click="process21">Send</button>
        <input v-model="key21" />
<textarea v-model="data21" class="textarea" placeholder="10 lines of textarea" rows="10"></textarea> 

            </div>
            <div class="format">
                <h3>Must be in this format</h3>
                <p>Day,Currency code,Impr.,CTR,Avg. CPC,Cost,Clicks
2022-01-12,USD,140,1.43%,7.85,15.70,2
2022-01-03,USD,164,8.54%,7.36,103.05,14
2022-01-10,USD,158,3.16%,8.48,42.41,5
2022-01-11,USD,106,7.55%,7.19,57.53,8
2022-01-05,USD,158,5.70%,7.71,69.41,9
2022-01-04,USD,153,4.58%,7.38,51.65,7
2022-01-06,USD,107,5.61%,8.26,49.57,6
2022-01-07,USD,74,8.11%,8.89,53.34,6
2022-01-14,USD,1,0.00%, --,0.00,0
2022-01-13,USD,146,6.85%,8.06,80.59,10
</p>
            </div>
            <div>
        <header>GoogleAds22</header><button v-on:click="process22">Send</button>
                <input v-model="key22" />
<textarea v-model="data22" class="textarea" placeholder="10 lines of textarea" rows="10"></textarea> 
            </div>
</div>
</div>
<div class="status">
    <h3>Update Campaign Status</h3>

<input v-model="idStatus" placeholder="campaign id" />
<input v-model="status" placeholder="status" />
<button @click="updateStatus()">Update campaign status</button>
</div>
<div class="status">
    <h3>Update Thirty Over Thirty</h3>

<input v-model="thirtyOverThirtyId" placeholder="campaign id" />
<button @click="updateThirtyOverThirty()">Update Thirty Over Thirty</button>
</div>
<div class="status">
    <h3>Update Year Over Year</h3>

<input v-model="yearOverYearId" placeholder="campaign id" />
<button @click="updateYearOverYear()">Update Year Over Year</button>
</div>

<div class="status">
    <h3>Update Clicks</h3>

<input v-model="idForClicks" placeholder="campaign id for clicks" />
<textarea v-model="clicks" class="textarea" placeholder="10 lines of textarea" rows="10"></textarea> 

</div>
<!-- <button class="click-button" @click="getClicks()">Update clicks</button> -->

<!-- <button class="click-button" @click="yearOverYearFunc()">Initialize Year over Year</button> -->
<div class="status">
    <h3>Add Employee</h3>

<input v-model="email" placeholder="email" />
<input v-model="issuer" placeholder="issuer" />
<input v-model="publicAddress" placeholder="publicAddress" />

<button @click="addEmployee()">Add employee</button>
</div>
<!-- <div class="status">
    <h3>Additional Clients</h3>

<button @click="initalizeAdditionalClients()">Add additional Clients</button>
</div> -->
<div>
    <button @click="updateAccess()">Update user access</button>

    <select name="fruit" @change="accessClicked($event)" v-model="access">
        <option value="" disabled selected>Select what <strong>{{isClicked}}</strong> should have access to</option>
        <option value="0">{{formatAccess[0]}}</option>
        <option value="1">{{formatAccess[1]}}</option>
        <option value="2">{{formatAccess[2]}}</option>
        <option value="3">{{formatAccess[3]}}</option>
                        <option value="5">{{formatAccess[5]}}</option>



    </select>
</div>
<div>
    <ul>
        <li v-for="user of allUsers" v-bind:key="user.id" class="users" @click="clientClicked(user.id, user.email)" v-bind:class="[Number(user.id) === Number(accessId) ? 'users clicked' : 'users']"  > {{user.email}} {{formatAccess[user.access]}}</li>
    </ul>
</div>
   </div>
</template>

<script>

import { searchTermYesterday } from "../../apiClient/searchTermEndpoints";
import store from "../../store/index";
// import API

export default  ({

methods: {
    accessClicked(event) {
        console.log('event', event)
      this.access = event.target.value
    },
    async getNewUsers() {
      fetch(`${store.state.serverAddress}api/v1/auth/readUsers`)
                .then(res => res.json())
                .then(res => {
                    this.allUsers = res.data
                    console.log('ALL USERS', res.data)

                }) 
                .catch(err => {
                    console.log('error reading users')
                })

    },

    async clientClicked(id, email) {
        this.accessId = await id
        this.isClicked = await email

    },


       async updateAccess() {
            let id = await this.accessId
            let access = await this.access
            fetch(`${store.state.serverAddress}api/v1/auth/updateAccess`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                id: id,
                access: access
               
            })
        })
                    .then(res => {
 this.getNewUsers()

            })


        
this.access = ''
this.accessId = ''
this.isClicked = '(no user selected)'
    },


    updateCRThisYear() {
            fetch(`${store.state.serverAddress}api/v1/updateSingleColumnTY`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                id: this.campaignForCallRailTY,
               
            })
        })

        
this.campaignForCallRailTY = ''
    },

      updateCRLastYear() {

                   fetch(`${store.state.serverAddress}api/v1/updateSingleColumnLY`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                id: this.campaignForCallRailLY,
               
            })
        })

        
this.campaignForCallRailLY = ''

    },



 filteredClients(customers) {
    const search = this.searchHolder.toLowerCase().trim();

   if (!search) return customers;

   return customers.filter(c => c.customerName.toLowerCase().indexOf(search) > -1);
},
        
            
        
  

                changeHandlerInput22(e) {
            e.preventDefault()
            this.key20 = e.target.value
            console.log(this.key22)
     

        },

                changeHandlerInput21(e) {
            e.preventDefault()
            this.key21 = e.target.value
            console.log(this.key21)
     

        },
   changeHandler22(e) {
            e.preventDefault()
            this.data20 = e.target.value
            console.log(this.data22)
     

        },
   changeHandler21(e) {
            e.preventDefault()
            this.data21 = e.target.value
            console.log(this.data21)
     

        },


    process22() {
            fetch(`${store.state.serverAddress}api/v1/process20`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                key: this.key22,
                data: this.data22
            })
        })

this.data22 = ''
         this.key22 = ''
        

    },

    process21() {
            fetch(`${store.state.serverAddress}api/v1/process21`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                key: this.key21,
                data: this.data21
            })
        })

        
this.data21 = ''
         this.key21 = ''
console.log('hi')
    },

        addEmployee() {
            fetch(`${store.state.serverAddress}api/v1/auth/addEmployee`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                email: this.email,
                issuer: this.issuer,
                publicAddress: this.publicAddress
            })
        })

        
this.email = ''
         this.issuer = ''
                  this.publicAddress = ''

console.log('hi')
    },



        yearOverYearFunc() {
            fetch(`${store.state.serverAddress}api/v1/campaign/yearOverYearThirtyOver`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
        })

        

    },


            initalizeAdditionalClients() {
            fetch(`${store.state.serverAddress}api/v1/nightwatch/addAditionalClientsInitialize`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
        })

        

    },





   
    getCamaignByDate(campaignId, date) {
          date = '2021-01-21'

        console.log('hi')
       fetch(`${store.state.serverAddress}api/v1/campaign/readbydate/${campaignId}/${date}`, {
            method: "GET",
            endpoint:"api/v1/campaign/readbydate",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(data => {
            // 
            console.log('data', data)
           data.data.map(item => {
               if (Array.isArray(item)) {
                   this.filteredGoogleAdsData.push(item)
               }
           })
            // this.filteredGoogleAdsData = data.data
            })
        
        .catch(err => {
            console.log(err)
        })
    },

        processCallInfo() {
            fetch(`${store.state.serverAddress}api/v1/processCallInfo`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                acctNum: this.acctNum,
                tracker: this.trackNum,
                campaignId: this.campaignId
            })
        })

this.data22 = ''
         this.key22 = ''
        

    },
        updateCols() {
            fetch(`${store.state.serverAddress}api/v1/updateOneCampaign`, {
            method: "PUT",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                id: this.idForOne,
            })
        })

this.idForOne = ''
      
        

    },

    updateStatus() {
        const id = this.idStatus
            fetch(`${store.state.serverAddress}api/v1/campaign/updateStatus/${id}`, {
            method: "PUT",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            },
            body: JSON.stringify({
                status: this.status,
              
            })
        })

this.idStatus = ''
         this.status = ''
        

    },
   async updateThirtyOverThirty() {
        const id = await this.thirtyOverThirtyId
            fetch(`${store.state.serverAddress}api/v1/campaign/thirtyOverThirty/${id}`, {
            method: "PUT",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            }
            
        })

this.thirtyOverThirtyId = ''
         this.thirtyOverThirty = ''
        

    },

   async updateYearOverYear() {
        const id = await this.yearOverYearId
            fetch(`${store.state.serverAddress}api/v1/campaign/yearOverYear/${id}`, {
            method: "PUT",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"

            }
            
        })

this.yearOverYearId = ''
         this.yearOverYear = ''
        

    },


//    async getClicks() {
//         const id = await this.idForClicks
//             fetch(`${store.state.serverAddress}api/v1/clicks/`, {
//             method: "POST",
//             mode: "cors",
//             headers: {
//                 Authorization: `Bearer ${store.state.auth.key}`,
//                 "Content-Type": "application/json"

//             },
//                         body: JSON.stringify({
//                 id: id,
//                 data: this.clicks

              
//             })

            
//         })

// // this.clicks = ''
// //          this.idForClicks = ''
        

//     },


},

    data() {

       return {
       
        data22: '',
        key21: '',
        key22: '',
          data21: '',
          trackNum: '',
          acctNum: '',
          campaignId: '',
          campaignForCallRailTY: '',
          campaignForCallRailLY: '',
          idForOne: '',
          idStatus: '',
          status: '',
          thirtyOverThirty: "",
        thirtyOverThirtyId: "",
        yearOverYear: "",
        yearOverYearId: "",

                idForClicks: "",
                clicks: "",
                email: "",
                issuer: "",
                publicAddress: "",
        access: "",
        accessId: "",
        allUsers: null,
        isClicked: '(no user selected)',
    formatAccess : {
        0: 'None',
        1: 'All',
        2: 'Negative Keywords',
        3: 'KPI & Local SEO',
        5:  'Developer'
    }



       }
},
         mounted() {
console.log('filt', this.data)

            fetch(`${store.state.serverAddress}api/v1/auth/readUsers`)
                .then(res => res.json())
                .then(res => {
                    this.allUsers = res.data
                    console.log('ALL USERS', res.data)

                }) 
                .catch(err => {
                    console.log('error reading users')
                })

    }

})

</script>

<style scoped>

.click-button {
    width: 500px;
    height: 200px;
}

.status {
    display: flex;
    margin-top: 100px;
    margin-bottom: 100px;
}

.status input {
    margin-right: 20px;
}

.single {
    margin-top: 100px;
}
#dropdown-input.dropdown-input{
   margin-left: 10px;
   height: 35px;

}

#dropdown-input.dropdown-input.inactive{
    display: none;
}

.dropdown-menu {
    max-height: 500px;
    overflow: scroll;
}

thead {
    position: sticky;
    top: 0;
    background: white;

}

th {
    text-align: center;
}


.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover {
    background-color: rgb(239,80,50);
    color: white;
        border: 1.2px white solid;

}
.table.is-hoverable.is-striped tbody tr:not(.is-selected):hover th{
    background-color: rgb(239,80,50);
    color: white;
    font-weight: bolder;
    border: 1.2px white solid;
}

.google {
    background: lightyellow;
}

.call-rail {
    background: lightpink;
}

.kpi {
background: white;
color: #1a1a1a;
}

.ads-flex {
    display: flex;
    justify-content: space-around;
    margin-top: 200px;
}

textarea {
    width: 450px;
}

.format {
    width: 300px;
    border: 1px dashed black;
    padding: 15px;
}

.format h4 {
    font-weight: bolder;
}

.format p {
    font-size: 12px;
}

.users {
    padding: 20px;
    cursor: pointer;
}

.clicked {
    font-weight: bolder;
    color: dodgerblue;
}

</style>