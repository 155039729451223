







import { Component, Vue } from 'vue-property-decorator';

// import components
import Nekeyto from '@/components/nekeyto/Nekeyto.vue';

@Component({
    components: {
        Nekeyto,
    },
})
export default class HomePage extends Vue {};
