




















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class ErrorBasic extends Vue {

    // MOUNTED
    public mounted() {
        console.log("mounted()");
    }

};
