<template>
    <!-- THIS DIV IS SACRED -->

    <div class="kpi-container">
        <!-- CONTAINER -->
        <div class="container mb-2">
            <div v-lazy v-if="$store.state.filteredGoogleAdsData.length > 0">
                <div
                    v-for="ads in $store.state.filteredGoogleAdsData"
                    v-bind:key="ads[0].cost_micros"
                >
                    <p>
                        {{
                            (ads[0].metrics.cost_micros / 1000000)
                                .toFixed(2)
                                .toString()
                                .split(",")
                                .reduce((total, num) => {
                                    return total + Math.round(num);
                                })
                        }}
                    </p>
                </div>
            </div>

            <div class="input-container" id="input-container">
                <div class="addCampaignButton-container">
                    <button
                        id="addCampaignButton"
                        class="addCampaignButton"
                        @click="openCampaignModal()"
                    >
                        Add new Campaign/Account
                    </button>
                </div>
                <div
                    v-if="$store.state.addCampaign.modal === true"
                    class="custom-modal"
                >
                    <div class="modal-box campaign">
                        <div class="modal-box__top">
                            <h3>Add new campaign</h3>

                            <button
                                @click="closeCampaignModal()"
                                class="delete is-large"
                            ></button>
                        </div>

                        <div
                            class="campaign-container"
                            v-if="$store.state.addCampaign.list.length > 0"
                        >
                            <input
                                v-if="$store.state.addCampaign.search === true"
                                class="choose-campaign"
                                v-model="
                                    $store.state.addCampaign.chooseCampaign
                                "
                                placeholder="choose campaign"
                            />

                            <ul
                                v-if="
                                    $store.state.addCampaign.list.length > 0 &&
                                    $store.state.addCampaign.campaignName
                                        .length <= 0 &&
                                    $store.state.campaignSuccess === false &&
                                    $store.state.campaignWarning === false
                                "
                                class="dd-menu campaign"
                            >
                                <li
                                    v-for="campaign in filteredNewCamps(
                                        $store.state.addCampaign.list
                                    )"
                                    :key="campaign.id"
                                    @click="
                                        setNewCampaign(
                                            campaign.id,
                                            campaign.descriptive_name,
                                            campaign.resource_name
                                        )
                                    "
                                >
                                    {{ campaign.descriptive_name }}
                                </li>

                                <li class="divider"></li>
                            </ul>

                            <div
                                class="campaign-text"
                                v-else-if="
                                    $store.state.addCampaign.campaignName
                                        .length > 0 &&
                                    $store.state.campaignSuccess === false &&
                                    $store.state.campaignWarning === false
                                "
                            >
                                <p>
                                    Click button to add campaign:
                                    {{ $store.state.addCampaign.campaignName }}
                                </p>
                            </div>

                            <article
                                v-else-if="
                                    $store.state.campaignSuccess === true
                                "
                                class="message is-success"
                            >
                                <div class="message-header">
                                    <p>Success</p>
                                </div>
                                <div class="message-body">
                                    You did it! Refresh the screen and you'll
                                    see the new campaign is set in Nekeyto.
                                </div>
                            </article>

                            <article
                                v-else-if="
                                    $store.state.campaignWarning === true
                                "
                                class="message is-warning"
                            >
                                <div class="message-header">
                                    <p>Warning</p>
                                </div>
                                <div class="message-body">
                                    Whoops. Looks like this account does not
                                    have any campaigns set up in Google Ads yet.
                                </div>
                            </article>

                            <button
                                v-if="
                                    $store.state.campaignSuccess === false &&
                                    $store.state.campaignWarning === false
                                "
                                v-bind:class="{
                                    'is-loading':
                                        $store.state.addCampaign.loading,
                                }"
                                class="button addCampaign-button"
                                @click="addNewCampaign()"
                            >
                                Add Campaign
                            </button>
                        </div>
                        <div class="loading-container" v-else>
                            <div id="loading"></div>
                        </div>
                    </div>
                </div>

                <!-- <p class="kpi-header">LNM KPI</p> -->

                <input
                    @click="checking()"
                    v-model="$store.state.searchHolder"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter client name"
                />
                <div class="generator">
                    <div
                        class="key-generator"
                        v-if="$store.state.isValidated === false"
                    >
                        <div v-if="$store.state.snippet.length === 0">
                            <button @click="generateSnippet()">
                                Generate Key
                            </button>
                        </div>
                        <div class="snippet-container" v-else>
                            <p class="snippet">{{ $store.state.snippet }}</p>
                            <button v-on:click="validateSnippet()">
                                Validate
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <p class="snippet-text">
                            <span class="snippet-text__span"
                                >Add
                                <span class="snippet">{{
                                    $store.state.snippet
                                }}</span>
                                to <strong>CallRail</strong> and
                                <strong>GoogleAds</strong> campaign</span
                            >
                            <button class="close" @click="reset()">
                                Close
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <p
                class="delete-notification"
                v-if="$store.state.deleteNotification.length > 0"
            >
                {{ $store.state.deleteNotification }}
            </p>
            <div class="box">
                <div v-if="$store.state.customers.length > 0">
                    <!-- TABLE -->
                    <div class="table-container">
                        <table class="table has-sticky-header is-striped">
                            <thead>
                                <tr>
                                    <th
                                        @click="
                                            sortByName(
                                                $store.state.customers,
                                                'customerName'
                                            )
                                        "
                                    >
                                        Account
                                    </th>

                                    <th
                                        @click="
                                            sortByName(
                                                $store.state.customers,
                                                'campaignName'
                                            )
                                        "
                                    >
                                        Campaign Name
                                    </th>

                                    <th @click="filteredByCost('kpiOne')">
                                        CPL 1
                                    </th>
                                    <th @click="filteredByCost('kpiTwo')">
                                        CPL 7
                                    </th>
                                    <th @click="filteredByCost('kpiThree')">
                                        CPL 30
                                    </th>

                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('lastOneDayCost')
                                        "
                                    >
                                        1 Day Cost
                                    </th>
                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('lastSevenDayCost')
                                        "
                                    >
                                        7 Day Cost
                                    </th>
                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('lastThirtyDayCost')
                                        "
                                    >
                                        30 Day Cost
                                    </th>

                                    <th
                                        class="cost"
                                        @click="filteredByCost('oneDayCalls')"
                                    >
                                        1 Day Calls
                                    </th>
                                    <th
                                        class="cost"
                                        @click="filteredByCost('sevenDayCalls')"
                                    >
                                        7 Day Calls
                                    </th>
                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('thirtyDayCalls')
                                        "
                                    >
                                        30 Day Calls
                                    </th>
                                    <th
                                        @click="
                                            filteredByCost('thirtyOverThirty')
                                        "
                                    >
                                        30/30
                                    </th>
                                    <th @click="filteredByCost('yearOverYear')">
                                        Year/Year
                                    </th>

                                    <th @click="filteredByCost('cpc')">CPC</th>
                                    <th @click="filteredByCost('impressions')">
                                        IMP
                                    </th>
                                    <th @click="filteredByCost('ctr')">CTR</th>
                                    <th @click="filteredByCost('realClicks')">
                                        CLICKS
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <div v-if="$store.state.showModal === true">
                                    <div class="custom-modal">
                                        <div
                                            v-if="
                                                $store.state.choice === true &&
                                                $store.state.paused === false
                                            "
                                            class="modal-box"
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign:
                                                    {{
                                                        $store.state.currClient
                                                    }}
                                                </h3>
                                                <button
                                                    @click="closeModal()"
                                                    class="delete is-large"
                                                ></button>
                                            </div>

                                            <button
                                                @click="getPastData()"
                                                class="get-data"
                                            >
                                                Get Data
                                            </button>
                                            <button @click="confirmData()">
                                                Validate data
                                            </button>

                                            <div
                                                class="has-callrail"
                                                v-if="
                                                    $store.state
                                                        .closeHasCallRail ===
                                                    true
                                                "
                                            >
                                                <div class="radio_container">
                                                    <input
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail !==
                                                            1
                                                        "
                                                        @click="
                                                            setHasCallRailToYes()
                                                        "
                                                        type="radio"
                                                        name="radio"
                                                        id="one"
                                                    />
                                                    <label
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail !==
                                                            1
                                                        "
                                                        @click="
                                                            setHasCallRailToYes()
                                                        "
                                                        for="one"
                                                        >Yes</label
                                                    >

                                                    <input
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail ===
                                                            1
                                                        "
                                                        @click="
                                                            setHasCallRailToYes()
                                                        "
                                                        type="radio"
                                                        name="radio"
                                                        id="one"
                                                        checked
                                                    />
                                                    <label
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail ===
                                                            1
                                                        "
                                                        @click="
                                                            setHasCallRailToYes()
                                                        "
                                                        for="one"
                                                        >Yes</label
                                                    >

                                                    <!-- ------ -->

                                                    <input
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail !==
                                                            2
                                                        "
                                                        @click="
                                                            setHasCallRailToPending()
                                                        "
                                                        type="radio"
                                                        name="radio"
                                                        id="two"
                                                    />
                                                    <label
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail !==
                                                            2
                                                        "
                                                        @click="
                                                            setHasCallRailToPending()
                                                        "
                                                        for="two"
                                                        >Wait</label
                                                    >

                                                    <input
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail ===
                                                            2
                                                        "
                                                        @click="
                                                            setHasCallRailToPending()
                                                        "
                                                        type="radio"
                                                        name="radio"
                                                        id="two"
                                                        checked
                                                    />
                                                    <label
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail ===
                                                            2
                                                        "
                                                        @click="
                                                            setHasCallRailToPending()
                                                        "
                                                        for="two"
                                                        >Wait</label
                                                    >

                                                    <!-- ----- -->

                                                    <input
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail !==
                                                            3
                                                        "
                                                        @click="
                                                            setHasCallRailToNo()
                                                        "
                                                        type="radio"
                                                        name="radio"
                                                        id="three"
                                                    />
                                                    <label
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail !==
                                                            3
                                                        "
                                                        @click="
                                                            setHasCallRailToNo()
                                                        "
                                                        for="three"
                                                        >No</label
                                                    >

                                                    <input
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail ===
                                                            3
                                                        "
                                                        @click="
                                                            setHasCallRailToNo()
                                                        "
                                                        type="radio"
                                                        name="radio"
                                                        id="three"
                                                        checked
                                                    />
                                                    <label
                                                        v-if="
                                                            $store.state
                                                                .hasCallRail ===
                                                            3
                                                        "
                                                        @click="
                                                            setHasCallRailToNo()
                                                        "
                                                        for="three"
                                                        >No</label
                                                    >
                                                </div>
                                                <p>
                                                    Should this campaign have a
                                                    tracker?
                                                </p>
                                            </div>
                                            <img
                                                @click="openPaused()"
                                                class="pause"
                                                src="/img/pause.svg"
                                                alt="paused"
                                            />
                                            <img
                                                @click="
                                                    $store.state.deleteModal = true
                                                "
                                                class="trash"
                                                src="/img/trash.svg"
                                                alt="trash"
                                            />
                                            <article
                                                v-if="
                                                    $store.state.deleteModal ===
                                                    true
                                                "
                                                class="
                                                    message
                                                    is-success
                                                    deleting
                                                "
                                            >
                                                <div class="message-header">
                                                    <p>Deleting Campaign!</p>
                                                </div>
                                                <div class="message-body">
                                                    Are you sure you want to
                                                    delete this campaign inside
                                                    of Nekeyto?
                                                </div>
                                                <button
                                                    @click="
                                                        setCampaignToTrash(
                                                            $store.state
                                                                .campaignNumber
                                                        )
                                                    "
                                                >
                                                    Yes
                                                </button>
                                                <button @click="closeModal()">
                                                    No
                                                </button>
                                            </article>

                                            <div class="status">
                                                <p
                                                    v-if="
                                                        $store.state
                                                            .addToAudit === true
                                                    "
                                                >
                                                    Added to audit table
                                                </p>
                                            </div>

                                            <div class="modal-button-container">
                                                <!-- <button v-bind:class="{'is-loading': laodingSearchTermProcess}" class="button is-link is-fullwidth my-2" @click="searchTermProcess()"> -->

                                                <button
                                                    @click="getTracker()"
                                                    v-bind:class="{
                                                        'is-loading':
                                                            $store.state
                                                                .isTrackingLoading,
                                                    }"
                                                    class="
                                                        callrail-buttons
                                                        first
                                                    "
                                                >
                                                    <div
                                                        class="tracker-icons"
                                                        v-if="
                                                            $store.state
                                                                .isTrackingLoading
                                                        "
                                                    >
                                                        <button
                                                            class="
                                                                button
                                                                is-loading
                                                            "
                                                        >
                                                            Loading
                                                        </button>
                                                    </div>

                                                    <div
                                                        class="tracker-icons"
                                                        v-if="
                                                            !$store.state
                                                                .isTrackingLoading
                                                        "
                                                    >
                                                        <img
                                                            class="
                                                                tracker-icon
                                                                view
                                                            "
                                                            src="/img/binoculars.svg"
                                                            alt="binoculars"
                                                        />

                                                        <img
                                                            class="
                                                                tracker-icon
                                                                update
                                                            "
                                                            src="/img/pencil.svg"
                                                            alt="pencil"
                                                        />
                                                    </div>

                                                    Tracking Number
                                                    <br />View/Update
                                                </button>
                                                <button
                                                    @click="addAccount()"
                                                    class="
                                                        callrail-buttons
                                                        second
                                                    "
                                                >
                                                    <div class="tracker-icons">
                                                        <img
                                                            class="
                                                                tracker-icon
                                                                view
                                                            "
                                                            src="/img/binoculars.svg"
                                                            alt="binoculars"
                                                        />

                                                        <img
                                                            class="
                                                                tracker-icon
                                                                update
                                                            "
                                                            src="/img/pencil.svg"
                                                            alt="pencil"
                                                        />
                                                    </div>

                                                    Account Number
                                                    <br />View/Update
                                                </button>
                                            </div>
                                        </div>

                                        <div
                                            v-else-if="
                                                $store.state.paused === true
                                            "
                                            class="modal-box"
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign:
                                                    {{
                                                        $store.state.currClient
                                                    }}
                                                </h3>
                                                <button
                                                    @click="closeModal()"
                                                    class="delete is-large"
                                                ></button>
                                            </div>
                                            <h3
                                                class="paused-text"
                                                v-if="
                                                    $store.state.isPaused ===
                                                    false
                                                "
                                            >
                                                Are you sure you want to pause
                                                this campaign? <br />(Will not
                                                have an effect in GoogleAds)<br />
                                                This will take this account out
                                                of the KPI table and place it in
                                                the "Paused" table.
                                            </h3>
                                            <div
                                                v-if="
                                                    $store.state.isPaused ===
                                                    false
                                                "
                                                class="sure-buttons paused"
                                            >
                                                <button @click="setToPaused()">
                                                    Yes
                                                </button>
                                                <button @click="closeModal()">
                                                    No
                                                </button>
                                            </div>
                                            <div
                                                class="pause-success"
                                                v-if="
                                                    $store.state.isPaused ===
                                                    true
                                                "
                                            >
                                                <h3>
                                                    Campaign has been succesfull
                                                    paused inside Nekeyto.
                                                </h3>
                                            </div>
                                        </div>
                                        <div
                                            v-if="
                                                $store.state.addTracker === true
                                            "
                                            class="modal-box trackers"
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign:
                                                    {{
                                                        $store.state.currClient
                                                    }}
                                                </h3>
                                                <div class="right-buttons">
                                                    <img
                                                        src="/img/back.svg"
                                                        alt="back"
                                                        @click="
                                                            back(
                                                                $store.state
                                                                    .currClient,
                                                                $store.state
                                                                    .campaignNumber,
                                                                $store.state
                                                                    .acctNum
                                                            )
                                                        "
                                                        class="back-button"
                                                    />

                                                    <button
                                                        @click="closeModal()"
                                                        class="delete is-large"
                                                    ></button>
                                                </div>
                                            </div>
 <button @click="deleteAllTrackers($store.state.campaignNumber)">Delete All Trackers</button>
                                            <label
                                                v-if="
                                                    $store.state.sure === false
                                                "
                                                class="dropdown-list"
                                            >
                                                <div
                                                    v-if="
                                                        $store.state
                                                            .currAccountNum > 0
                                                    "
                                                    @click="
                                                        $store.state.searchAccount =
                                                            ''
                                                    "
                                                    class="dd-button"
                                                >
                                                    Select new tracker

                                                    
                                                </div>
                                               
                                                <article
                                                    v-else
                                                    class="message is-warning"
                                                >
                                                    <div class="message-header">
                                                        <p>Warning</p>
                                                    </div>
                                                    <div class="message-body">
                                                        Whoops. No account
                                                        number is hooked up to
                                                        this Google ads campaign
                                                        yet.
                                                    </div>
                                                </article>
                                                <!-- <button>Back</button> -->
                                                <div
                                                    v-if="
                                                        $store.state.sure ===
                                                        false
                                                    "
                                                >
                                                    <input
                                                        type="checkbox"
                                                        class="dd-input"
                                                        id="test"
                                                    />

                                                    <ul
                                                        v-if="
                                                            $store.state
                                                                .trackers
                                                                .length > 0
                                                        "
                                                        class="dd-menu"
                                                    >
                                                        <input
                                                            v-model="
                                                                $store.state
                                                                    .searchAccount
                                                            "
                                                            id="dropdown-input"
                                                            class="
                                                                dropdown-input
                                                                small
                                                            "
                                                            placeholder="Search tracking numbers by number name"
                                                        />

                                                        <li
                                                            v-for="tracker in filteredCrAccounts(
                                                                $store.state
                                                                    .trackers
                                                            )"
                                                            :key="tracker[1]"
                                                            @click="
                                                                setTrackerInfo(
                                                                    tracker[1],
                                                                    tracker[0]
                                                                )
                                                            "
                                                        >
                                                            <strong
                                                                >Company:</strong
                                                            >
                                                            {{ tracker[3] }} |
                                                            <strong
                                                                >Name:</strong
                                                            >
                                                            {{ tracker[0] }} |
                                                            <strong
                                                                >Track #</strong
                                                            >
                                                            {{ tracker[2] }}
                                                        </li>

                                                        <li
                                                            class="divider"
                                                        ></li>
                                                    </ul>
                                                    <div
                                                        class=""
                                                        v-if="
                                                            $store.state
                                                                .trackerId
                                                                .length > 0
                                                        "
                                                    >
                                                        <p class="tracker-text">
                                                            You are connecting
                                                            tracking number
                                                            {{
                                                                $store.state
                                                                    .trackerInfo
                                                                    .trackingNumber
                                                            }}
                                                            from ({{
                                                                callRailName
                                                            }}) campaign to this
                                                            Google Ads campaign
                                                            <br />
                                                            <strong>
                                                                Are you
                                                                sure?</strong
                                                            >
                                                        </p>
                                                        <div
                                                            class="sure-buttons"
                                                        >
                                                            <button
                                                                @click="
                                                                    $store.state.sure = true
                                                                "
                                                            >
                                                                Yes
                                                            </button>
                                                            <button
                                                                @click="
                                                                    closeModal()
                                                                "
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>

                                            <div
                                                v-if="
                                                    $store.state.sure === true
                                                "
                                            >
                                                <button
                                                    v-if="
                                                        $store.state
                                                            .trackerSuccess ===
                                                        false
                                                    "
                                                    class="send"
                                                    @click="addTrackerId()"
                                                >
                                                    Send Tracker
                                                </button>
                                                <article
                                                    v-if="
                                                        $store.state
                                                            .trackerSuccess ===
                                                        true
                                                    "
                                                    class="message is-success"
                                                >
                                                    <div class="message-header">
                                                        <p>Success</p>
                                                    </div>
                                                    <div class="message-body">
                                                        CallRail tracking number
                                                        has been updated
                                                    </div>
                                                </article>
                                            </div>

                                            <div
                                                v-if="
                                                    $store.state.currTrackerInfo
                                                "
                                                class="trackers-container"
                                            >
                                                <div
                                                    class="tracker-container"
                                                    v-for="track in $store.state
                                                        .currTrackerInfo"
                                                    v-bind:key="track.id"
                                                >
                                                    <p>Current Info</p>
                                                    <div class="tracking-info">
                                                        <div>
                                                            <p>
                                                                Number Name:
                                                                {{ track.name }}
                                                            </p>
                                                            <p>
                                                                Tracking
                                                                Numbers:
                                                                {{
                                                                    track.tracking_numbers
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p>
                                                                Forwards To:
                                                                {{
                                                                    track.destination_number
                                                                }}
                                                            </p>
                                                            <p>
                                                                Status:
                                                                {{
                                                                    track.status
                                                                }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <img
                                                        v-if="
                                                            $store.state
                                                                .trackerDeleteSure
                                                                .trackerId !==
                                                            track.id
                                                        "
                                                        @click="
                                                            sureDeleteTracker(
                                                                track.id
                                                            )
                                                        "
                                                        class="tracker-trash"
                                                        src="/img/tracker-trash.svg"
                                                        alt="trash"
                                                    />
                                                    <div v-else>
                                                        <p>Are you sure</p>
                                                        <div>
                                                            <button
                                                                @click="
                                                                    deleteTracker(
                                                                        track.id
                                                                    )
                                                                "
                                                            >
                                                                Yes</button
                                                            ><button
                                                                @click="
                                                                    $store.state.trackerDeleteSure.trackerId =
                                                                        null
                                                                "
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <!--"
                                                class="tracker-trash"
                                                src="/img/tracker-trash.svg"
                                                alt="trash" -->
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="modal-box trackers"
                                            v-if="
                                                $store.state.addCrAccount ===
                                                true
                                            "
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign:
                                                    {{
                                                        $store.state.currClient
                                                    }}
                                                </h3>
                                                <div class="right-buttons">
                                                    <img
                                                        src="/img/back.svg"
                                                        alt="back"
                                                        @click="
                                                            back(
                                                                $store.state
                                                                    .currClient,
                                                                $store.state
                                                                    .campaignNumber,
                                                                $store.state
                                                                    .acctNum
                                                            )
                                                        "
                                                        class="back-button"
                                                    />

                                                    <button
                                                        @click="closeModal()"
                                                        class="delete is-large"
                                                    ></button>
                                                </div>
                                            </div>
                                            <p
                                                v-if="
                                                    $store.state
                                                        .currAccountNum > 0 &&
                                                    $store.state.crAcctMessage
                                                        .length <= 0
                                                "
                                            >
                                                Current account number:
                                                {{
                                                    $store.state.currAccountNum
                                                }}
                                            </p>
                                            <button
                                                @click="
                                                    $store.state.areYouSureDeleteCr = true
                                                "
                                                v-if="
                                                    $store.state
                                                        .areYouSureDeleteCr ===
                                                        false &&
                                                    !$store.state
                                                        .deleteSuccess &&
                                                    !$store.state
                                                        .deleteWarning &&
                                                    $store.state.crAcctMessage
                                                        .length <= 0
                                                "
                                            >
                                                Remove
                                            </button>
                                            <article
                                                v-else-if="
                                                    $store.state
                                                        .deleteSuccess === true
                                                "
                                                class="message is-success"
                                            >
                                                <div class="message-header">
                                                    <p>Success</p>
                                                </div>
                                                <div class="message-body">
                                                    CallRail Number info has
                                                    been deleted
                                                </div>
                                            </article>

                                            <article
                                                v-else-if="
                                                    $store.state
                                                        .deleteWarning === true
                                                "
                                                class="message is-warning"
                                            >
                                                <div class="message-header">
                                                    <p>Warning</p>
                                                </div>
                                                <div class="message-body">
                                                    Whoops. Couldn't delete
                                                    CallRail info.
                                                </div>
                                            </article>

                                            <div v-else>
                                                <button
                                                    v-if="
                                                        $store.state
                                                            .crAcctMessage
                                                            .length <= 0
                                                    "
                                                    @click="deleteCrInfo()"
                                                >
                                                    Yes</button
                                                ><button
                                                    v-if="
                                                        $store.state
                                                            .crAcctMessage
                                                            .length <= 0
                                                    "
                                                    @click="closeModal()"
                                                >
                                                    No
                                                </button>
                                            </div>
                                            <p
                                                v-if="
                                                    $store.state.currAccountNum
                                                        .length <= 0
                                                "
                                            >
                                                No account number on this
                                                campaign is set
                                            </p>
                                            <input
                                                v-if="
                                                    $store.state.crAcctMessage
                                                        .length <= 0
                                                "
                                                v-model="$store.state.crAcctNum"
                                                class="add-account"
                                                placeholder="Add Call Rail Account"
                                            />
                                            <div
                                                v-if="
                                                    $store.state.crAcctMessage
                                                        .length > 0
                                                "
                                            >
                                                <article
                                                    class="message is-success"
                                                >
                                                    <div class="message-header">
                                                        <p>Success</p>
                                                    </div>
                                                    <div class="message-body">
                                                        CallRail account number
                                                        has been added
                                                    </div>
                                                </article>
                                            </div>
                                            <button
                                                v-if="
                                                    $store.state.crAcctNum
                                                        .length === 9 &&
                                                    $store.state.crAcctMessage
                                                        .length <= 0
                                                "
                                                class="send"
                                                @click="addAcctNum()"
                                            >
                                                Update
                                            </button>
                                            <p class="update-text" v-else>
                                                Located in "My Account"
                                            </p>
                                        </div>
                                        <div
                                            v-if="$store.state.getData === true"
                                        >
                                            <div
                                                v-if="
                                                    $store.state.imp.length ===
                                                        0 &&
                                                    $store.state.cost.length ===
                                                        0 &&
                                                    $store.state.calls
                                                        .length === 0
                                                "
                                                class="modal-box"
                                            >
                                                <div class="modal-box__top">
                                                    <h3>
                                                        Campaign:
                                                        {{
                                                            $store.state
                                                                .currClient
                                                        }}
                                                    </h3>
                                                    <div class="right-buttons">
                                                        <img
                                                            src="/img/back.svg"
                                                            alt="back"
                                                            @click="
                                                                back(
                                                                    $store.state
                                                                        .currClient,
                                                                    $store.state
                                                                        .campaignNumber,
                                                                    $store.state
                                                                        .acctNum
                                                                )
                                                            "
                                                            class="back-button"
                                                        />

                                                        <button
                                                            @click="
                                                                closeModal()
                                                            "
                                                            class="
                                                                delete
                                                                is-large
                                                            "
                                                        ></button>
                                                    </div>
                                                </div>
                                                <h3>Pick a date range</h3>
                                                <date-picker
                                                    class="datepicker"
                                                    v-model="$store.state.time3"
                                                    confirm
                                                    range
                                                ></date-picker
                                                ><button
                                                    v-bind:class="{
                                                        'is-loading':
                                                            $store.state
                                                                .dataSpinner,
                                                    }"
                                                    class="search"
                                                    @click="
                                                        getDaysBetweenDates()
                                                    "
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            <div v-else class="modal-box small">
                                                <div class="modal-box__top">
                                                    <h3>
                                                        Campaign:
                                                        {{
                                                            $store.state
                                                                .currClient
                                                        }}
                                                    </h3>
                                                    <div class="right-buttons">
                                                        <img
                                                            src="/img/back.svg"
                                                            alt="back"
                                                            @click="
                                                                back(
                                                                    $store.state
                                                                        .currClient,
                                                                    $store.state
                                                                        .campaignNumber,
                                                                    $store.state
                                                                        .acctNum
                                                                )
                                                            "
                                                            class="back-button"
                                                        />

                                                        <button
                                                            @click="
                                                                closeModal()
                                                            "
                                                            class="
                                                                delete
                                                                is-large
                                                            "
                                                        ></button>
                                                    </div>
                                                </div>

                                                <table class="small">
                                                    <thead class="small">
                                                        <tr class="small">
                                                            <th>Cost</th>
                                                            <th>Calls</th>
                                                            <th>Cpc</th>
                                                            <th>Impr.</th>
                                                            <th>Ctr</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="small">
                                                            <th>
                                                                ${{
                                                                    $store.state.cost.toFixed(
                                                                        2
                                                                    )
                                                                }}
                                                            </th>
                                                            <th>
                                                                {{
                                                                    $store.state
                                                                        .calls
                                                                }}
                                                            </th>
                                                            <th
                                                                v-if="
                                                                    $store.state
                                                                        .cpc > 0
                                                                "
                                                            >
                                                                ${{
                                                                    $store.state.cpc.toFixed(
                                                                        2
                                                                    )
                                                                }}
                                                            </th>
                                                            <th v-else>0</th>
                                                            <th>
                                                                {{
                                                                    $store.state
                                                                        .imp
                                                                }}
                                                            </th>
                                                            <th>
                                                                {{
                                                                    (
                                                                        $store
                                                                            .state
                                                                            .ctr *
                                                                        100
                                                                    ).toFixed(
                                                                        2
                                                                    )
                                                                }}%
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <tr
                                    class="campaign-row"
                                    v-for="list in filteredClients(
                                        $store.state.customers
                                    )"
                                    :key="list.id"
                                    @click="
                                        openModal(
                                            list.campaignName,
                                            list.campaignId,
                                            list.acctNum,
                                            list.status,
                                            list.hasCallRail
                                        )
                                    "
                                >
                                    <th>{{ list.customer.customerName }}</th>
                                    <th class="campaign-name">
                                        <span
                                            v-if="list.hasCallRail === 1"
                                            class="hasTracker"
                                            ><img
                                                src="/img/greenphone.svg"
                                                alt="green phone"
                                            /> </span
                                        ><span
                                            v-if="list.hasCallRail === 2"
                                            class="hasTracker"
                                            ><img
                                                src="/img/waitphone.svg"
                                                alt="green phone" /></span
                                        ><span
                                            v-if="list.hasCallRail === 3"
                                            class="hasTracker"
                                            ><img
                                                src="/img/nophone.svg"
                                                alt="green phone" /></span
                                        >{{ list.campaignName }}
                                    </th>
                                    <th class="kpi">
                                        <div v-if="Number(list.kpiOne) > 0">
                                            ${{ list.kpiOne.toFixed(2) }}
                                        </div>
                                        <div v-else>
                                            <p>$0</p>
                                        </div>
                                    </th>
                                    <th class="kpi">
                                        <div v-if="Number(list.kpiTwo) > 0">
                                            ${{ list.kpiTwo.toFixed(2) }}
                                        </div>
                                        <div v-else>
                                            <p>$0</p>
                                        </div>
                                    </th>

                                    <th class="kpi">
                                        <div v-if="Number(list.kpiThree) > 0">
                                            ${{ list.kpiThree.toFixed(2) }}
                                        </div>
                                        <div v-else>
                                            <p>$0</p>
                                        </div>
                                    </th>

                                    <th class="google">
                                        ${{
                                            list.lastOneDayCost
                                                ? list.lastOneDayCost.toFixed(2)
                                                : null
                                        }}
                                    </th>
                                    <th class="google">
                                        ${{
                                            list.lastSevenDayCost
                                                ? list.lastSevenDayCost.toFixed(
                                                      2
                                                  )
                                                : null
                                        }}
                                    </th>
                                    <th class="google">
                                        ${{
                                            list.lastThirtyDayCost
                                                ? list.lastThirtyDayCost.toFixed(
                                                      2
                                                  )
                                                : null
                                        }}
                                    </th>
                                    <th class="call-rail">
                                        {{ list.oneDayCalls }}
                                    </th>
                                    <th class="call-rail">
                                        {{ list.sevenDayCalls }}
                                    </th>
                                    <th class="call-rail">
                                        {{ list.thirtyDayCalls }}
                                    </th>
                                    <th v-if="list.thirtyOverThirty < 3000">
                                        {{ list.thirtyOverThirty }}%
                                    </th>
                                    <th v-else>n/a</th>
                                    <th
                                        v-if="
                                            list.yearOverYear < 3000 &&
                                            typeof list.yearOverYear ===
                                                'number'
                                        "
                                    >
                                        {{ list.yearOverYear }}%
                                    </th>
                                    <th v-else>n/a</th>
                                    <th class="google">
                                        ${{ Number(list.cpc).toFixed(2) }}
                                    </th>
                                    <th class="google">
                                        {{ list.impressions }}
                                    </th>
                                    <th class="google">
                                        {{ (list.ctr * 100).toFixed(2) }}%
                                    </th>
                                    <th class="google">
                                        {{ list.realClicks }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="loading-container full" v-else>
                    <div id="loading"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {

    getAcctNum
} from "@/apiClient/campaignEndpoints";

import store from "../../store/index";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
// import API
var moment = require("moment");

export default {
    components: { DatePicker },

    computed: {
        sortedArray: function () {
            function compare(a, b) {
                if (
                    Number(a.lastOneDayCost) / Number(a.oneDayCalls) <
                    Number(b.lastOneDayCost) / Number(b.oneDayCalls)
                )
                    return -1;

                if (
                    Number(a.lastOneDayCost) / Number(a.oneDayCalls) >
                    Number(b.lastOneDayCost) / Number(b.oneDayCalls)
                )
                    return 1;
                return 0;
            }
            return store.state.customers.sort(compare);
        },
    },


    methods: {


        

        confirmData() {
            this.loading = true
            console.log("WORKING");
            fetch(
                `${store.state.serverAddress}api/v1/campaign/confirmData/${store.state.campaignNumber}`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((data) => {
                    console.log("data", data);
                    this.getNewData();
                    this.loading = false
                })

                .catch((err) => {
                    console.log("failed to get new campaign data", err);
                });
        },

        filteredCrAccounts(account) {
            const search = store.state.searchAccount.toLowerCase().trim();

            if (!search) {
                return account;
            } else {
                return account.filter(
                    (c) =>
                        c[0]
                            .toLowerCase()
                            .replace(/- /g, "")
                            .trim()
                            .indexOf(search) > -1
                );
            }
        },

        filteredClients(customers) {
            const search = store.state.searchHolder.toLowerCase().trim();

            if (!search) {
                return customers;
            } else {
                return customers.filter(
                    (c) =>
                        c.campaignName
                            .toLowerCase()
                            .replace(/- /g, "")
                            .trim()
                            .indexOf(search) > -1 ||
                        c.customer.customerName
                            .toLowerCase()
                            .replace(/- /g, "")
                            .trim()
                            .indexOf(search) > -1
                );
            }
        },

        async checking() {
            console.log("checking", store.state.searchHolder);
        },

        async openModal(client, number, acctNum, status, hasCallRail) {
            store.state.showModal = true;
            store.state.currClient = client;
            store.state.campaignNumber = number;
            store.state.acctNum = acctNum;
            store.state.status = status;
            store.state.hasCallRail = hasCallRail;
            store.state.closeHasCallRail = true;
            getAcctNum();

            var inputContainer = document.getElementById("input-container");
            inputContainer.style.zIndex = "1";

            var navbarContainer = document.getElementById("navbar");

            navbarContainer.style.zIndex = "1";

            var campaignButton = document.getElementById("addCampaignButton");

            navbarContainer.style.zIndex = "1";
            campaignButton.style.zIndex = "1";
        },

        async closeModal() {
            var navbarContainer = document.getElementById("navbar");
            var campaignButton = document.getElementById("addCampaignButton");

            navbarContainer.style.zIndex = "30";
            campaignButton.style.zIndex = "30";
            var navbarContainer = document.getElementById("navbar");
            store.state.closeHasCallRail = false;

            navbarContainer.style.zIndex = "5";
            store.state.currTrackerInfo = null;
            store.state.showModal = false;
            store.state.choice = true;
            store.state.addTracker = false;
            store.state.getData = false;
            store.state.acctNum = null;
            store.state.campaignId = null;
            store.state.sure = false;
            store.state.callRailName = "";
            store.state.addCrAccount = false;
            store.state.crAcctNum = "";
            store.state.crAcctMessage = "";
            store.state.currAccountNum = "";
            (store.state.currTracker = ""), (store.state.cost = "");
            store.state.cpc = "";
            store.state.ctr = "";
            store.state.imp = "";
            store.state.calls = "";
            store.state.areYouSureDeleteCr = false;
            store.state.deleteSuccess = false;
            store.state.deleteWarning = false;
            store.state.trackerId = "";
            for (let key in store.state.trackerInfo) {
                store.state.trackerInfo[key] = "";
            }
            store.state.trackerSuccess = false;
            store.state.addToAudit = false;
            store.state.isPaused = false;
            store.state.paused = false;
            store.state.deleteModal = false;
            store.state.hasCallRail = null;
        },

        async setCampaignToTrash(id) {
            console.log("IDS", id);
            fetch(
                `${store.state.serverAddress}api/v1/campaign/setCampaignToTrash/${id}`,
                {
                    method: "PUT",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log("DATA", data);
                    store.state.deleteNotification = data.data;
                    this.getNewData();
                    this.closeModal();
                    store.state.deleteModal = false;
                })
                .catch((err) => {
                    console.log("err", err);
                });
        },

        async back(client, number, acctNum) {
            this.closeModal();
            this.openModal(client, number, acctNum);
        },

        async getDaysBetweenDates() {
            let startDate = moment(
                store.state.time3[0].toISOString().substring(0, 10)
            )
                .clone()
                .format("YYYY-MM-DD");
            let endDate = moment(
                store.state.time3[1].toISOString().substring(0, 10)
            )
                .clone()
                .format("YYYY-MM-DD");
            getCamaignByDate(startDate, endDate);
        },
    },

    mounted() {
        store.state.addToAudit = false;

        fetch(`${store.state.serverAddress}api/v1/campaign/readallForKpi`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        })
            // PARSE RESPONSE BODY
            .then((res) => res.json())
            .then((list) => {
                let arr = [];
                store.state.customers = list.data;
                store.state.regSorterHolder = arr;
                console.log("ALL Customers", list.data);
            })

            .catch((err) => {
                console.log(err);
            });
    },
};
</script>

<style scoped>
.navBarStack {
    display: none;
}

button {
    position: relative;
    z-index: 99999;
}

.loading-container button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    outline: unset;
}

.button {
    outline: none;
    border: none;
    background: black;
    cursor: pointer;

    padding: 5px;
    border-radius: 50px;

    color: #fff;
    font-size: 13px;
    /* bottom: 0;
 left: 50%; */
    width: 70px;
    height: 22px;
    margin-right: 5px;
    margin-top: 5px;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: none;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 0 auto;
}

.custom-modal::before {
    background: black;
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    content: "";
    height: 100%;
    width: 100%;
}

.modal-box {
    background-color: white;
    width: 40vw;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    border-radius: 10px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .modal-box {
        margin-top: 150px;
    }
}

.modal-box select {
    z-index: 999999999;
}

.modal-box.campaign {
    overflow: hidden;
    max-height: 40vw;
    min-height: 400px;
}

@media screen and (max-width: 600px) {
    .modal-box {
        width: 95vw;
    }

    .modal-box.campaign {
        width: 95vw;
    }

    .modal-box.campaign li {
        font-size: 13px;
    }
}

.addCampaign-button {
    height: 40px;
    padding: 8px;
}

.modal-box__top {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 70px;
    background: #6f7074;
}

.modal-box__top h3 {
    color: white;
    margin-left: 15px;
}

.modal-box__top button {
    margin-right: 8px;
}

.modal-box.small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.modal-box .datepicker {
    z-index: 9999999;
    border-radius: 50px;
}

.search {
    background: #6f7074;
    color: white;
}

.modal-box::after {
    content: "";
    position: absolute;
    opacity: 0.3;
    /* background: url('/img/google-ads-01.png'), white; */
    filter: opacity(0.5);
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 10px;
}

.modal-box.small::after {
    content: "";
    position: absolute;
    opacity: 0.3;
    background: white;
}

.modal-box button {
    height: 35px;
    width: 180px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    /* z-index: 9999999; */
    margin-bottom: 8px;
}

.custom-modal h1 {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 15px;
    color: black;
    z-index: 9999999;
    text-align: center;
    width: 90%;
}

thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 9;
}

th {
    text-align: center;
}

.kpi {
    /* background: white; */
    /* color: #ef5032; */
}

.thirtyRed {
    color: red;
    font-weight: bolder;
}

.thirtyYellow {
    color: orange;
}

.thirtyGreen {
    color: green;
    font-weight: bolder;
}

.table-container {
    width: 100vw;
    overflow: unset;
}

@media screen and (max-width: 1000px) {
    .table-container {
        overflow: scroll;
    }
}

table {
    background-color: white;
    z-index: 2;
}

.table {
    position: relative;
}

.table::before {
    content: "";
    width: 100%;
    height: 15px;
    position: absolute;
    background: #6f7074;
    z-index: 9;
    top: -8px;
    border-radius: 100px;
}

thead {
    background: #6f7074;
    border-radius: 100px;
}

#app > div:nth-child(2) > div > div > div.box {
    padding: 0;
}

thead > tr > th {
    padding-top: 28px;
}

thead th {
    color: white;
}

tbody tr {
    border-right: 0.2px solid gray;
    /* position: relative; */
}

tbody tr:hover {
    border-left: 4px solid black;
    position: relative;
    color: black;
}

tbody tr.small:hover {
    position: unset;
    color: unset;
}

tbody tr::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    left: -2px;
    height: 1px;
    background: rgba(136, 108, 108, 0.05);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

tbody tr.small::after {
    content: "";
    position: unset;
    display: unset;
    width: unset;
    bottom: unset;
    left: unset;
    height: unset;
    display: unset;
    justify-content: unset;
    align-items: unset;
}

tbody tr:hover::before {
    height: 100%;
}

tbody tr:hover::after {
    content: url("/img/info.svg");
    height: 100%;
    color: black;
    font-style: italic;
    text-decoration: underline;
}

tbody tr.small:hover::after {
    content: unset;
    color: unset;
}

table.small {
    position: relative;
    z-index: 99999999999999;
    overflow: hidden;
    height: 100%;
    margin-top: 8px;
}

table.small tr {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

button.small {
    margin-bottom: 8px;
}

th {
    font-size: 13px;
    max-width: 208px;
    font-weight: 600;
}

tr {
    height: 70px !important;
    cursor: pointer;
}

.input-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    background: whitesmoke;
    border-radius: 20px;
    padding: 15px;
    position: relative;
}

.addCampaignButton-container {
    width: 30%;
    display: flex;
    justify-content: flex-start;
}

.addCampaignButton {
    border-radius: 100px;
    padding: 15px;
    font-size: 12px;
    font-weight: 400px;
    color: white;
    background: #000;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    float: right;
    margin-top: 5px;
}

.addCampaignButton:hover {
    background: rgb(239, 80, 50);
    color: white;
}

@media screen and (max-width: 1000px) {
    .input-container {
        flex-direction: column-reverse;
    }

    .input-container input#dropdown-input {
        width: 100%;
    }

    .addCampaignButton-container {
        width: 70%;
        margin-top: 20px;
    }

    .addCampaignButton {
        width: 100%;
    }
}

.kpi-header {
    width: 30%;
    font-size: 30px;
    padding-left: 5px;
    font-weight: 500;
    visibility: hidden;
}

.input-container #dropdown-input {
    height: 45px;
    background: white;
    border-radius: 50px;
    color: black;
    width: 80%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 12px;
    border: 1px solid black;
}

.input-container #dropdown-input.small {
    width: 300px;
}

.generator {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
}

.key-generator button {
    border-radius: 100px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400px;
    color: black;
    background: white;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    float: right;
    margin-top: 5px;
}

.close {
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400px;
    color: black;
    background: white;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    width: 100px;
}

.close:hover {
    background: black;
    color: white;
}

.key-generator button:hover {
    background: rgb(239, 80, 50);
    color: white;
}

.snippet-container {
    display: flex;
    align-items: center;
}

.snippet {
    background: whitesmoke;
    border: 1px solid lightgray;
    border-radius: 80px;
    padding: 2px;
}

.cost {
    position: relative;
    font-weight: 600;
}

.cost.seven::before {
    content: "7 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.thirty::before {
    content: "30 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.one::before {
    content: "7 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.one::before {
    content: "1 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.callthirty::before {
    content: "30 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}
.cost.callseven::before {
    content: "7 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.callone::before {
    content: "1 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.data-text {
    color: black;
    font-size: 14px;
}
.custom-modal .modal-box.trakcers .tracker-text p {
    color: black;
    font-size: 18px;
}

.modal-box.campaign button {
    margin-bottom: 8px;
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    color: #222222;
}

/* Dropdown */

.dropdown-list {
    display: inline-block;
    position: relative;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-input.small {
    width: 100%;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
}

.dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    margin: 0 auto;
}

.dd-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dd-button:hover {
    background-color: #eeeeee;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 600px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    height: 300px;
    margin-left: -300px;

    overflow: scroll;
}

.dd-input + .dd-menu {
    display: none;
}

.dd-input:checked + .dd-menu {
    display: block;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

button.get-data {
    height: 35px;
    border-radius: 0px;
    border-bottom-left-radius: 10px;
    position: absolute;
    top: 70px;
    right: 0;
}

.modal-button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
}

.modal-button-container .callrail-buttons {
    min-height: 180px;
    min-width: 130px;
    background: white;
    border: 1px solid #cecece;
    border-radius: 5px;
}

.modal-button-container .callrail-buttons:hover {
    border: 2px solid #ef5032;
}

.modal-button-container .button.is-loading {
    background: white;
    color: black;
    fill: black;
    width: 30px;
}

.callrail-buttons.first {
    margin-right: 2.5px;
}

.callrail-buttons.second {
    margin-left: 2.5px;
}

.send {
    position: relative;
    cursor: pointer;
    z-index: 999999999999999999999;
    background: black;
    color: white;
}

.update-text {
    height: 45px;
    display: flex;
    align-items: center;
}

.add-account {
    position: relative;
    padding: 8px;
    border-radius: 10px;
    z-index: 99999;
}

.trackers-container {
    width: 100% !important;
    z-index: 9999;
}

.tracker-container {
    width: 100%;
    background: #ef5032;
    color: white;
    text-align: center;
    min-height: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-top: 5px;
}

.tracking-info {
    width: 98%;
    display: flex;
    font-size: 23px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
}

.choose-campaign {
    position: relative;
    z-index: 9999;
    width: 250px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    text-align: center;
    border: none;
    background: whitesmoke;
}

.campaign-text {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.campaign-container {
    position: relative;
    z-index: 9999;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80%;
}

.dd-menu.campaign {
    position: static;
    z-index: 999999;
    width: 380px;
    height: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.dd-menu.campaign .modal-box__top {
    min-height: 45px;
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.full {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.full #loading {
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 5px solid #ef5032;
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #ef5032;
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.message {
    height: 200px;
    width: 95%;
    text-align: center;
}

.message.deleting {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9999999;
}

.tracker-text {
    font-size: 14px;
    width: 85%;
    text-align: center;
    margin: 0 auto;
}

.sure-buttons {
    margin: 0 auto;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.sure-buttons.paused {
    height: 100px;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.right-buttons button.delete {
    margin-bottom: 0;
}

.back-button {
    position: relative;
    z-index: 99999;
    cursor: pointer;
    fill: white;
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.pause {
    position: absolute;
    height: 25px;
    left: 10px;
    bottom: 10px;
    cursor: pointer;
    z-index: 999999;
}

.trash {
    position: absolute;
    height: 25px;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    z-index: 999999;
}

.tracker-trash {
    width: 20px;
    margin-bottom: 5px;
    cursor: pointer;
}

.pause-success {
    height: 100px;
}

.paused-text {
    text-align: center;
    margin-top: 8px;
    width: 70%;
}
.delete-notification {
    position: fixed;
    bottom: 0;
    right: 0;
    background: black;
    color: white;
    padding: 8px;
    z-index: 99999999;
    font-size: 20px;
    font-weight: bolder;
}

.campaign-row {
    position: relative;
}

.campaign-name {
    text-align: right;
}

.has-callrail {
    z-index: 99999;
    text-align: center;
    position: absolute;
    top: 70px;
    left: 0;
}

.has-callrail p {
    font-size: 12px;
}

.hasTracker {
    margin-right: 5px;
    height: 100%;
}

.hasTracker img {
    width: 15px;
    height: 15px;
}

.switch {
    z-index: 9999;
    cursor: pointer;
}

.radio_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #cecece;
    width: 180px;
    height: 30px;
    box-shadow: inset 0.5px 0.5px 2px 0 rgba(0, 0, 0, 0.15);
    border-bottom-right-radius: 10px;
}

.radio_container [type="radio"] {
    appearance: none;
    display: none;
    cursor: pointer;
}

.radio_container label {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    width: 80px;
    height: 30px;
    text-align: center;
    overflow: hidden;
    transition: linear 0.3s;
    color: #6e6e6edd;
    cursor: pointer;
    border-bottom-right-radius: 10px;
}

.radio_container input[type="radio"]:checked + label {
    background-color: #000;
    color: #f1f3f5;
    font-weight: 900;
    transition: 0.3s;
}

.radio_container {
    z-index: 9999;
}

.tracker-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.tracker-icons img.tracker-icon {
    width: 20px;
}
</style>

