




































































































import { Component, Vue } from "vue-property-decorator";

// import API
import { CONSTANTS } from "@/apiClient/constants";
import { negativeActionCreate, negativeActionReadAll } from "@/apiClient/negativeActionEndpoints";

@Component
export default class NegativeActionCreate extends Vue {

    // LOADING
    private CONSTANTS = CONSTANTS;
    private error: any = null;

    private adGroupTypeSelected: number = 0;
    private campaignTypeSelected: number = 0;
    private categorySelected: number = 0;

    private matchType: number = 0;
    private actionType: number = 0;
    private actionDestination: string = "";
    private negativeText: string = "";

    // MOUNTED
    public mounted() {
        console.log("mounted()");
    }

    // API 
    public async createNegativeAction() {
        switch (this.actionType) {
            case 20:
                this.actionDestination = this.categorySelected.toString();
                break;
            case 40:
                this.actionDestination = this.campaignTypeSelected.toString();
                break;
            case 60:
                this.actionDestination = this.adGroupTypeSelected.toString();
                break;
            case 30: 
            case 50:
            case 70: 
                break;
        }
        let result = await negativeActionCreate({
            text: this.negativeText,
            matchType: this.matchType,
            actionType: this.actionType,
            actionDestination: this.actionDestination
        });
        if (result.errors) {
            this.error = result.errors[0];
        }
        else {
            await negativeActionReadAll();
        }
        this.reset();
    };

    public setActionType(actionType: number) {
        if (this.actionType === actionType) {
            return;
        }
        this.actionDestination = "";
        this.actionType = actionType;
    };

    public reset() {
        this.error = null;

        this.adGroupTypeSelected = 0;
        this.campaignTypeSelected = 0;
        this.categorySelected = 0;

        this.matchType = 0;
        this.actionType = 0;
        this.actionDestination = "";
        this.negativeText = "";
    }
    
}
