// import store
import store from "../store/index";

// PATHC api/v1/category/update
const categoryUpdate = async (categoryId: number, name: string, description: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/category/update/${categoryId}`, {
            method: "PATCH",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                name: name,
                description: description
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

const highestAutoRepair = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/nightwatch/getHighestAutoRepair`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            console.log(jsonResponse)
            store.state.highestAutoRepair = jsonResponse.data
            
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return 
        return false;
    }


}





// export
export {
    categoryUpdate,
    highestAutoRepair
};