var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container"},[_c('button',{staticClass:"button is-light is-fullwidth my-2",on:{"click":function($event){return _vm.navigateBack()}}},[_vm._v(" BACK ")]),_c('div',{staticClass:"box"},[_c('p',{staticClass:"title is-3"},[_vm._v(" "+_vm._s(_vm.$store.state.customerCache.customer.customerName)+" "),(
                        _vm.canChangeCategory() &&
                        _vm.$store.state.customerCache.customer.categoryId
                    )?_c('span',{staticClass:"tag is-large is-link is-pulled-right",on:{"click":function($event){_vm.overrideSetCategory = true}}},[_vm._v("change")]):_vm._e(),(_vm.$store.state.customerCache.customer.category)?_c('span',{staticClass:"\n                        tag\n                        mr-1\n                        is-large is-link is-light is-pulled-right\n                    "},[_vm._v(_vm._s(_vm.$store.state.customerCache.customer.category.name))]):_vm._e()]),_c('p',{staticClass:"subtitle is-5 mb-1"},[_vm._v(" customer id: "),_c('strong',[_vm._v(_vm._s(_vm.$store.state.customerCache.customer.customerId))])]),(_vm.$store.state.customerCache.customer.mainWebsite)?_c('div',[(!_vm.$store.state.newWebsite.isEdit)?_c('p',{staticClass:"subtitle is-5 mb-1"},[_vm._v(" main website: "),_c('a',{on:{"click":function($event){_vm.visitWebsite(_vm.formatWebsite())}}},[_vm._v(_vm._s(_vm.$store.state.customerCache.customer.mainWebsite))]),_c('img',{staticClass:"edit",attrs:{"src":"/img/pencil.svg","alt":"pencil"},on:{"click":function($event){_vm.$store.state.newWebsite.isEdit = true}}})]):_c('div',{staticClass:"website-input__container"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.$store.state.newWebsite.site),expression:"$store.state.newWebsite.site"}],staticClass:"website-input",attrs:{"placeholder":_vm.$store.state.customerCache.customer.mainWebsite},domProps:{"value":(_vm.$store.state.newWebsite.site)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$store.state.newWebsite, "site", $event.target.value)}}}),_c('img',{staticClass:"edit",attrs:{"src":"/img/backTwo.svg","alt":"back"},on:{"click":function($event){_vm.$store.state.newWebsite.isEdit = false}}}),_c('button',{on:{"click":function($event){return _vm.setCustomerNewWebsite(
                                _vm.$store.state.customerCache.customer
                                    .customerId
                            )}}},[_vm._v(" New site ")])]),_c('br'),_c('button',{staticClass:"button is-link",class:{ 'is-loading': _vm.isLoadingProcessNekeyto },on:{"click":function($event){return _vm.processInNekeyto()}}},[_vm._v(" Process in Nekeyto ")])]):_c('div',[_vm._m(0),_c('div',{staticClass:"field has-addons"},[_c('div',{staticClass:"control is-expanded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mainWebsiteInput),expression:"mainWebsiteInput"}],staticClass:"input is-small is-fullwidth",attrs:{"type":"text","placeholder":"www.leadsnearme.com"},domProps:{"value":(_vm.mainWebsiteInput)},on:{"input":function($event){if($event.target.composing){ return; }_vm.mainWebsiteInput=$event.target.value}}})]),_c('div',{staticClass:"control"},[_c('a',{staticClass:"button is-link is-small",on:{"click":function($event){return _vm.setCustomerMainWebsite(
                                    _vm.$store.state.customerCache.customer
                                        .customerId
                                )}}},[_vm._v(" Set Main Website ")])])])]),(
                    !_vm.$store.state.customerCache.customer.category ||
                    _vm.overrideSetCategory
                )?_c('div',[_c('hr'),_vm._m(1),_c('div',{staticClass:"buttons mt-2"},_vm._l((_vm.$store.state.categoryCache
                            .categoryList),function(category){return _c('button',{key:category.id,staticClass:"button is-link is-small",on:{"click":function($event){return _vm.setCustomerCategory(
                                _vm.$store.state.customerCache.customer
                                    .customerId,
                                category.id
                            )}}},[_vm._v(" "+_vm._s(category.name)+" ")])}),0)]):_vm._e()]),(_vm.$store.state.customerCache.customer.category)?_c('div',{staticClass:"box mt-2"},[_c('p',{staticClass:"title is-4"},[_vm._v("Campaigns")]),_c('p',{staticClass:"subtitle is-6"},[_vm._v(" A list of all the Customer's Campaigns ")]),_vm._l((_vm.$store.state.customerCache.customer
                    .campaigns),function(item){return _c('div',{key:item.campaignId},[_c('CampaignView',{attrs:{"campaignId":item.campaignId}})],1)})],2):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"subtitle is-5 mb-1"},[_vm._v(" main website: "),_c('span',{staticClass:"tag is-danger"},[_vm._v("Incomplete")]),_vm._v(" no website is set yet ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"tag is-danger"},[_vm._v("Incomplete")]),_vm._v(" no category is set yet ")])}]

export { render, staticRenderFns }