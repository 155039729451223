// import store
import store from "../store/index";

// GET api/v1/customer/read/:customerId
const customerRead = async (customerId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${ store.state.serverAddress}api/v1/customer/read/${customerId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCustomerCache", {
            customerList: []
        });
    }
};
// GET api/v1/customer/readall
const customerReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${ store.state.serverAddress}api/v1/customer/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCustomerCache", {
            customerList: []
        });
    }
};
// GET api/v1/customer/readpretty/:customerId
const customerReadPretty = async (customerId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/customer/readpretty/${customerId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerId: jsonResponse.data.customerId,
                customer: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customer: null
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCustomerCache", {
            customer: null
        });
    }
};
// GET api/v1/customer/readallpretty/:customerId
const customerReadAllPretty = async () => {
    try {
        // MAKE REQUEST
        // const response = await fetch(`${ store.state.serverAddress}api/v1/customer/readallpretty`, {
        const response = await fetch(`${ store.state.serverAddress}api/v1/customer/readallprettybycategory`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                categoryid: `${store.state.activeCategoryId}`
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: jsonResponse.data
            });
            return true;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: []
            });
            return false;
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCustomerCache", {
            customerList: []
        });
        return false;
    }
};
const customerReadAllPrettyByCategory = async (categoryId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${ store.state.serverAddress}api/v1/customer/readallprettybycategory`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                categoryid: `${store.state.auth.categoryId}`
            },
            body: JSON.stringify({
                categoryId: categoryId
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: jsonResponse.data
            });
            return true;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: []
            });
            return false;
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCustomerCache", {
            customerList: []
        });
        return false;
    }
};
// GET api/v1/customer/readdownwards/:customerId
const customerReadDownwards = async (custmerId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/customer/readdownwards`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCustomerCache", {
                customerList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCustomerCache", {
            customerList: []
        });
    }
};
// POST api/v1/customer/setcategory
const customerSetCategory = async (customerId: any, categoryId: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/customer/setcategory`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                customerId: customerId,
                categoryId: categoryId
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST api/v1/customer/setmainwebsite
const customerSetMainWebsite = async (customerId: any, websiteUrl: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/customer/setmainwebsite`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
            body: JSON.stringify({
                customerId: customerId,
                websiteUrl: websiteUrl
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            return true;
        }
        else {
            console.log("errorinside");
            return false;
        }
    }
    catch (error) {
        return false;
    }
};
// POST api/v1/customer/magicupdate
const customerMagicUpdate = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/customer/magicupdate`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            return true;
        }
        else {
            return false;
        }
    }
    catch (error) {
        return false;
    }
};

const setCustomerToTrash = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/customer/setCustomerToTrash`, {
            method: "PUT",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-type": "application/json"
            },
            body: JSON.stringify({
                id: `${store.state.customerInfo.id}`
            })
        })
        // PARSE RESPONSE BODY
        .then(res => {
           store.state.customerInfo.deleting = true

        })
        // HANDLE RESULT
        // if (jsonResponse.result == "success") {
        //     // MANIPULATE STORE
        //     store.commit("updateCustomerCache", {
        //         customerList: jsonResponse.data
        //     });
        //     return true;
        // }
        // else {
        //     // MANIPULATE STORE
        //     store.commit("updateCustomerCache", {
        //         customerList: []
        //     });
        //     return false;
        // }
    }
    catch (error) {
        // MANIPULATE STORE
     
        return false;
    }

};


// export
export {
    customerRead,
    customerReadAll,
    customerReadPretty,
    customerReadAllPretty,
    customerReadDownwards,
    customerSetCategory,
    customerSetMainWebsite,
    customerMagicUpdate,
    setCustomerToTrash

};