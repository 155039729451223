










import { Component, Vue } from 'vue-property-decorator';

// import components
import GoodList from '../components/goodlist/GoodList.vue';

@Component({
    components: {
        GoodList,
    },
})
export default class GoodListPage extends Vue {};
