<template>
    <!-- THIS DIV IS SACRED -->

    <div class="table-container">
        <table
            v-if="clients && desktop === true"
            class="table has-sticky-header is-striped"
        >
            <thead>
                <tr>
                    <th @click="sortByName(clients, 'clientName')">Client</th>

                    <th @click="sortByName(clients, 'url')">Url</th>

                    <th @click="sortByName(clients, 'city')">City</th>
                    <th @click="filterByCount('Desktopautorepair')">
                        Auto Repair
                    </th>
                    <th @click="filterByCount('Desktopoilchange')">
                        Oil Change
                    </th>

                    <th @click="filterByCount('Desktopoilchangenearme')">
                        Oil change near me
                    </th>
                    <th @click="filterByCount('Desktopautorepairnearme')">
                        Auto repair near me
                    </th>

                    <th @click="filterByCount('Desktopmechanicnearme')">
                        Mechanic near me
                    </th>
                    <th @click="filterByCount('Desktoptires')">Tires</th>
                    <th @click="filterByCount('Desktoptransmissionrepair')">
                        Transmission repair
                    </th>
                    <th @click="filterByCount('Desktopautoshopnearme')">
                        Auto shop near me
                    </th>

                    <th @click="filterByCount('Desktopbrakeservice')">
                        Brake service
                    </th>

                    <th @click="filterByCount('Desktopcarrepair')">
                        Car Repair
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="list of clients.filter((row) => {
                        return (
                            row.Desktopautorepair > 0 ||
                            row.Desktopoilchange > 0 ||
                            row.Desktopoilchangenearme > 0 ||
                            row.Desktopautorepairnearme > 0 ||
                            row.Desktopmechanicnearme > 0 ||
                            row.Desktoptires > 0 ||
                            row.Desktoptransmissionrepair > 0 ||
                            row.Desktopautoshopnearme > 0 ||
                            row.Desktopbrakeservice > 0 ||
                            row.Desktopcarrepair > 0
                        );
                    })"
                    v-bind:key="list.id"
                    @click="openClientSingleModal(list)"
                >
                    <th>{{ list.clientName }}</th>
                    <th class="client-url">
                        <a :target="list.url" :href="list.url">{{ list.url }}</a>
                    </th>
                    <th class="client-city">{{ list.city }}</th>
                    <th>{{ list.Desktopautorepair }}</th>
                    <th>{{ list.Desktopoilchange }}</th>
                    <th>{{ list.Desktopoilchangenearme }}</th>
                    <th>{{ list.Desktopautorepairnearme }}</th>
                    <th>{{ list.Desktopmechanicnearme }}</th>
                    <th>{{ list.Desktoptires }}</th>
                    <th>{{ list.Desktoptransmissionrepair }}</th>
                    <th>{{ list.Desktopautoshopnearme }}</th>
                    <th>{{ list.Desktopbrakeservice }}</th>
                    <th>{{ list.Desktopcarrepair }}</th>
                </tr>
            </tbody>
        </table>
        <table
            v-if="clients && mobile === true"
            class="table has-sticky-header is-striped"
        >
            <thead>
                <tr>
                    <th @click="sortByName(clients, 'clientName')">Client</th>

                    <th @click="sortByName(clients, 'url')">Url</th>

                    <th @click="sortByName(clients, 'city')">City</th>
                    <th @click="filterByCount('Mobilesautorepair')">
                        Auto Repair
                    </th>
                    <th @click="filterByCount('Mobilesoilchange')">
                        Oil Change
                    </th>

                    <th @click="filterByCount('Mobilesoilchangenearme')">
                        Oil change near me
                    </th>
                    <th @click="filterByCount('Mobilesautorepairnearme')">
                        Auto repair near me
                    </th>

                    <th @click="filterByCount('Mobilesmechanicnearme')">
                        Mechanic near me
                    </th>
                    <th @click="filterByCount('Mobilestires')">Tires</th>
                    <th @click="filterByCount('Mobilestransmissionrepair')">
                        Transmission repair
                    </th>
                    <th @click="filterByCount('Mobilesautoshopnearme')">
                        Auto shop near me
                    </th>

                    <th @click="filterByCount('Mobilesbrakeservice')">
                        Brake service
                    </th>

                    <th @click="filterByCount('Mobilescarrepair')">
                        Car Repair
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr
                    @click="openClientSingleModal(listlist)"
                    v-for="list of clients.filter((row) => {
                        return (
                            row.Mobilesautorepair > 0 ||
                            row.Mobilesoilchange > 0 ||
                            row.Mobilesoilchangenearme > 0 ||
                            row.Mobilesautorepairnearme > 0 ||
                            row.Mobilesmechanicnearme > 0 ||
                            row.Mobilestires > 0 ||
                            row.Mobilestransmissionrepair > 0 ||
                            row.Mobilesautoshopnearme > 0 ||
                            row.Mobilesbrakeservice > 0 ||
                            row.Mobilescarrepair > 0
                        );
                    })"
                    v-bind:key="list.id"
                >
                    <th>{{ list.clientName }}</th>
                    <th class="client-url">
                        <a :href="list.url">{{ list.url }}</a>
                    </th>
                    <th>{{ list.city }}</th>
                    <th>{{ list.Mobilesautorepair }}</th>
                    <th>{{ list.Mobilesoilchange }}</th>
                    <th>{{ list.Mobilesoilchangenearme }}</th>
                    <th>{{ list.Mobilesautorepairnearme }}</th>
                    <th>{{ list.Mobilesmechanicnearme }}</th>
                    <th>{{ list.Mobilestires }}</th>
                    <th>{{ list.Mobilestransmissionrepair }}</th>
                    <th>{{ list.Mobilesautoshopnearme }}</th>
                    <th>{{ list.Mobilesbrakeservice }}</th>
                    <th>{{ list.Mobilescarrepair }}</th>
                </tr>
            </tbody>
        </table>
        <table
            v-if="clients && mobileEuro === true"
            class="table has-sticky-header is-striped"
        >
            <thead>
                <tr>
                    <th @click="sortByName(clients, 'clientName')">Client</th>

                    <th @click="sortByName(clients, 'url')">Url</th>

                    <th @click="sortByName(clients, 'city')">City</th>
                    <th @click="filterByCount('Mobilesbmwrepair')">
                        BMW Repair
                    </th>
                    <th @click="filterByCount('Mobilesmercedesrepair')">
                        Mercedes Repair
                    </th>

                    <th @click="filterByCount('Mobileseuropeanautorepair')">
                        European Auto Repair
                    </th>
                    <th @click="filterByCount('Mobilesaudirepair')">
                        Audi Repair
                    </th>

                    <th @click="filterByCount('Mobilesvwrepair')">VW Repair</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="list of clients.filter((row) => {
                        return (
                            row.Mobilesbmwrepair > 0 ||
                            row.Mobilesmercedesrepair > 0 ||
                            row.Mobileseuropeanautorepair > 0 ||
                            row.Mobilesaudirepair > 0 ||
                            row.Mobilesvwrepair > 0
                        );
                    })"
                    v-bind:key="list.id"
                    @click="openClientSingleModal(list)"
                >
                    <th>{{ list.clientName }}</th>
                    <th class="client-url">
                        <a :href="list.url">{{ list.url }}</a>
                    </th>
                    <th>{{ list.city }}</th>
                    <th>{{ list.Mobilesbmwrepair }}</th>
                    <th>{{ list.Mobilesmercedesrepair }}</th>
                    <th>{{ list.Mobileseuropeanautorepair }}</th>
                    <th>{{ list.Mobilesaudirepair }}</th>
                    <th>{{ list.Mobilesvwrepair }}</th>
                </tr>
            </tbody>
        </table>
        <table
            v-if="clients && desktopEuro === true"
            class="table has-sticky-header is-striped"
        >
            <thead>
                <tr>
                    <th @click="sortByName(clients, 'clientName')">Client</th>

                    <th @click="sortByName(clients, 'url')">Url</th>

                    <th @click="sortByName(clients, 'city')">City</th>
                    <th @click="filterByCount('Desktopbmwrepair')">
                        BMW Repair
                    </th>
                    <th @click="filterByCount('Desktopmercedesrepair')">
                        Mercedes Repair
                    </th>

                    <th @click="filterByCount('Desktopeuropeanrepair')">
                        European Auto Repair
                    </th>
                    <th @click="filterByCount('Desktopaudirepair')">
                        Audi Repair
                    </th>

                    <th @click="filterByCount('Desktopvwrepair')">VW Repair</th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="list of clients.filter((item) => {
                        return (
                            item.Desktopbmwrepair > 0 ||
                            item.Desktopmercedesrepair > 0 ||
                            item.Desktopeuropeanautorepair > 0 ||
                            item.Desktopaudirepair > 0 ||
                            item.Desktopvwrepair > 0
                        );
                    })"
                    v-bind:key="list.id"
                    @click="openClientSingleModal(list)"
                >
                    <th>{{ list.clientName }}</th>
                    <th class="client-url">
                        <a :href="list.url">{{ list.url }}</a>
                    </th>
                    <th>{{ list.city }}</th>
                    <th>{{ list.Desktopbmwrepair }}</th>
                    <th>{{ list.Desktopmercedesrepair }}</th>
                    <th>{{ list.Desktopeuropeanautorepair }}</th>
                    <th>{{ list.Desktopaudirepair }}</th>
                    <th>{{ list.Desktopvwrepair }}</th>
                </tr>
            </tbody>
        </table>
        <table
            v-if="clients && desktopDiesel === true"
            class="table has-sticky-header is-striped"
        >
            <thead>
                <tr>
                    <th @click="sortByName(clients, 'clientName')">Client</th>

                    <th @click="sortByName(clients, 'url')">Url</th>

                    <th @click="filterByCount('')">City</th>
                    <th @click="filterByCount('Desktopdieselrepair')">
                        Diesel Repair
                    </th>
                    <th @click="filterByCount('Desktoptruckrepair')">
                        Truck Repair
                    </th>

                    <th @click="filterByCount('Desktoppowerstrokerepair')">
                        Powerstroke Repair
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="list of clients.filter((item) => {
                        return (
                            item.Desktopdieselrepair > 0 ||
                            item.Desktoptruckrepair > 0 ||
                            item.Desktoppowerstrokerepair > 0
                        );
                    })"
                    v-bind:key="list.id"
                    @click="openClientSingleModal(list)"
                >
                    <th>{{ list.clientName }}</th>
                    <th class="client-url">
                        <a :href="list.url">{{ list.url }}</a>
                    </th>
                    <th>{{ list.city }}</th>
                    <th>{{ list.Desktopdieselrepair }}</th>
                    <th>{{ list.Desktoptruckrepair }}</th>
                    <th>{{ list.Desktoppowerstrokerepair }}</th>
                </tr>
            </tbody>
        </table>
        <table
            v-if="clients && mobileDiesel === true"
            class="table has-sticky-header is-striped"
        >
            <thead>
                <tr>
                    <th @click="sortByName(clients, 'clientName')">Client</th>

                    <th @click="sortByName(clients, 'url')">Url</th>

                    <th @click="filterByCount('')">City</th>
                    <th @click="filterByCount('Mobilesdieselrepair')">
                        Diesel Repair
                    </th>
                    <th @click="filterByCount('Mobilestruckrepair')">
                        Truck Repair
                    </th>

                    <th @click="filterByCount('Mobilespowerstrokerepair')">
                        Powerstroke Repair
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr
                    v-for="list of clients.filter((item) => {
                        return (
                            item.Mobilesdieselrepair > 0 ||
                            item.Mobilestruckrepair > 0 ||
                            item.Mobilespowerstrokerepair > 0
                        );
                    })"
                    v-bind:key="list.id"
                    @click="openClientSingleModal(list)"
                >
                    <th>{{ list.clientName }}</th>
                    <th class="client-url">
                        <a :href="list.url">{{ list.url }}</a>
                    </th>
                    <th>{{ list.city }}</th>
                    <th>{{ list.Mobilesdieselrepair }}</th>
                    <th>{{ list.Mobilestruckrepair }}</th>
                    <th>{{ list.Mobilespowerstrokerepair }}</th>
                </tr>
            </tbody>
        </table>
        <article
            v-if="clientAddedSuccessMessage === true"
            class="message is-success"
        >
            <div class="message-header">
                <p>Success</p>
                <button
                    class="delete"
                    @click="clientAddedSuccessMessage = false"
                    aria-label="delete"
                ></button>
            </div>
            <div class="message-body">Client has been added to the table.</div>
        </article>
        <article
            v-if="clientAddedFailedMessage === true"
            class="message is-warning"
        >
            <div class="message-header">
                <p>Warning</p>
                <button class="delete" aria-label="delete"></button>
            </div>
            <div class="message-body">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <strong>Pellentesque risus mi</strong>, tempus quis placerat ut,
                porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla.
                Nullam gravida purus diam, et dictum
                <a>felis venenatis</a> efficitur. Aenean ac
                <em>eleifend lacus</em>, in mollis lectus. Donec sodales, arcu
                et sollicitudin porttitor, tortor urna tempor ligula, id
                porttitor mi magna a neque. Donec dui urna, vehicula et sem
                eget, facilisis sodales sem.
            </div>
        </article>

        <div v-if="!clients" class="loading-container">
            <div class="centering">
                <div class="loaderContainer">
                    <div class="loaderBG">
                        <span class="loaderCircle"></span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isAddClientModal" class="client-modal">
            <div class="client-modal__header">
                <h3>Add Client</h3>
                <button
                    @click="closeAddClientModal()"
                    class="delete is-large"
                ></button>
            </div>

            <div class="client-modal__container">
                <div v-if="newClients.length > 0" class="add-box">
                    <p
                        class="add-box__client-names"
                        v-for="client of newClients"
                        v-bind:key="client.id"
                        @click="addClientToNightwatch(client.id, client.name)"
                    >
                        {{ client.name }}
                    </p>
                </div>
                <div v-else class="add-box manual">

                    <p>Didn't find any clients but you can enter in new client manually</p>
                    <input v-model="newClient.name" placeholder="Enter client's name" />
                    <input v-model="newClient.id" placeholder="Enter client's id" />
                    <input v-model="newClient.url" placeholder="Enter client's url" />
                    <input v-model="newClient.place" placeholder="Enter client's location" />

                    <p class="alert">Can be found in the url on client's page in nightwatch https://app.nightwatch.io/urls/!!! ID IS HERE !!! /keywords</p>
                    <button @click="addClientToNightwatchManually(newClient.id, newClient.name, newClient.url, newClient.place)">Create</button>
                </div>
            </div>
        </div>
        <div v-if="isAddClientSingleModal" class="client-modal-single">
            <div class="client-modal__header">
                <h3>{{clientSingleModalInfo.name}}</h3>
                <button
                    @click="closeAddClientModal()"
                    class="delete is-large"
                ></button>
            </div>
            <div class="info-container" v-for="item of clientSingleModalInfo.info" v-bind:key="item.id">
               
            <p>Keyword: {{item.query}}</p>
             <p>Position: {{item.position}}</p>
            <p>Last week change: {{item.last_week_change}}</p>

              <p class="info-container__city">{{item.local_search}}</p>
                           <p class="info-container__device" v-if="item.mobile">Mobile</p>
             <p class="info-container__device" v-else>Desktop</p>

            </div>
        </div>

        <div
            v-if="isAddClientModal || isAddClientSingleModal"
            @click="closeAddClientModal()"
            class="client-modal__overlay"
        ></div>

        <div class="footer__container">
            <p
                v-bind:class="{ active: desktop }"
                @click="openDesktop()"
                class="footer__item desktop"
            >
                Desktop
            </p>
            <p
                v-bind:class="{ active: mobile }"
                @click="openMobile()"
                class="footer__item mobile"
            >
                Mobile
            </p>
            <p
                v-bind:class="{ active: desktopEuro }"
                @click="openDesktopEuro()"
                class="footer__item desktop"
            >
                Desktop Euro
            </p>
            <p
                v-bind:class="{ active: mobileEuro }"
                @click="openMobileEuro()"
                class="footer__item mobile"
            >
                Mobile Euro
            </p>
            <p
                v-bind:class="{ active: desktopDiesel }"
                @click="openDesktopDiesel()"
                class="footer__item desktop"
            >
                Desktop Diesel
            </p>
            <p
                v-bind:class="{ active: mobileDiesel }"
                @click="openMobileDiesel()"
                class="footer__item mobile"
            >
                Mobile Diesel
            </p>
            <img
                @click="openAddClientModal()"
                class="footer__plus"
                src="/img/plus.svg"
                alt="plus"
            />
        </div>
    </div>
</template>

<script>
import { searchTermYesterday } from "../../apiClient/searchTermEndpoints";

import store from "../../store/index";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

// import API
var moment = require("moment");

export default {
    components: { DatePicker },

    computed: {
        sortedArray: function (costType) {
            function compare(a, b) {
                if (a[costType] < b[costType]) return -1;
                if (a[costType] > b[costType]) return 1;
                return 0;
            }

            return this.arrays.sort(compare);
        },
    },

    methods: {

        async addClientToNightwatchManually(urlId, name, url, place) {
            console.log(urlId, name, url, place)
            await fetch(
                `${store.state.serverAddress}api/v1/nightwatch/addNewClientManually`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        urlId: urlId,
                        name: name,
                        url: url,
                        place: place

                    }),
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log(res.status);
                    console.log("yep", res);
                    if (res.status === 201) {
                        console.log(res.data.status);
                        this.getNewData();
                        this.getNightwatchNotYetClients();
                        this.clientAddedSuccessMessage = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },


        async addClientManually(id, name) {
           await this.addClientToNightwatch(id, name)

        },


        async getDataForOne(id) {
            await fetch(
                `${store.state.serverAddress}api/v1/nightwatch/getInfo/${id}`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("OH YEA", res.data);
                   this.clientSingleModalInfo.info = res.data
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async openClientSingleModal(client) {
            this.isAddClientSingleModal = true;
            console.log("client", client);
            this.clientSingleModalInfo.name = client.clientName;
            let clientUrlId = await client.url_id
            this.getDataForOne(clientUrlId)
        },

        async filterByCount(costType) {
            if (!this.sortedByCost1 && costType) {
                this.sortedByCost1 = true;
                console.log("costtype", costType);
                return this.clients.sort((a, b) => a[costType] - b[costType]);
            } else {
                this.sortedByCost1 = false;

                return this.clients.sort((a, b) => b[costType] - a[costType]);
            }
        },

        async getNewData() {
            await fetch(
                `${store.state.serverAddress}api/v1/nightwatch/readAll`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("yep", res.data);
                    this.clients = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async getAllClients() {
            await fetch(
                `${store.state.serverAddress}api/v1/nightwatch/onlyDesktop`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("yep", res.data.length);
                    this.clients = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
            console.log("yay", this.clients);
        },

        async addAllClients() {
            await fetch(
                `${store.state.serverAddress}api/v1/nightwatch/addAllClients`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log("yep", data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async openDesktop() {
            this.mobile = false;
            this.desktop = true;
            this.mobileEuro = false;
            this.desktopEuro = false;
            this.mobileDiesel = false;
            this.desktopDiesel = false;
        },

        async openMobile() {
            this.mobile = true;
            this.desktop = false;
            this.mobileEuro = false;
            this.desktopEuro = false;
            this.mobileDiesel = false;
            this.desktopDiesel = false;
        },

        async openDesktopEuro() {
            this.desktop = false;
            this.mobileEuro = false;
            this.desktopEuro = true;
            this.mobileDiesel = false;
            this.desktopDiesel = false;
            this.mobile = false;
            console.log("mobile boolean", this.mobile);
        },

        async openMobileEuro() {
            this.mobile = false;
            this.desktop = false;
            this.mobileEuro = true;
            this.desktopEuro = false;
            this.mobileDiesel = false;
            this.desktopDiesel = false;
        },

        async openDesktopDiesel() {
            this.mobile = false;
            this.desktop = false;
            this.mobileEuro = false;
            this.desktopEuro = false;
            this.mobileDiesel = false;
            this.desktopDiesel = true;
        },

        async openMobileDiesel() {
            this.mobile = false;
            this.desktop = false;
            this.mobileEuro = false;
            this.desktopEuro = false;
            this.mobileDiesel = true;
            this.desktopDiesel = false;
        },

        async openAddClientModal() {
            this.isAddClientModal = true;
            this.getNightwatchNotYetClients();
        },

        addInitialData1stStep() {
            fetch(
                `${store.state.serverAddress}api/v1/nightwatch/addDataToAllClientsFirstStep`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("yep", res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        addInitialData2ndStep() {
            fetch(
                `${store.state.serverAddress}api/v1/nightwatch/addDataToAllClientsSecondStep`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("yep", res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        addInitialData3rdStep() {
            fetch(
                `${store.state.serverAddress}api/v1/nightwatch/addDataToAllClientsThirdStep`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("yep", res.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        getNightwatchNotYetClients() {
            fetch(
                `${store.state.serverAddress}api/v1/nightwatch/nightwatchClients`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log("yep", res.data);
                    if (res.data === false) {
                        null;
                    }
                    this.newClients = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async addClientToNightwatch(groupId, name) {
            await fetch(
                `${store.state.serverAddress}api/v1/nightwatch/addNewClient`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        groupId: groupId,
                        name: name,

                    }),
                }
            )
                .then((res) => res.json())
                .then((res) => {
                    console.log(res.status);
                    console.log("yep", res);
                    if (res.status === 201) {
                        console.log(res.data.status);
                        this.getNewData();
                        this.getNightwatchNotYetClients();
                        this.clientAddedSuccessMessage = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async closeAddClientModal() {
            this.isAddClientModal = false;
            this.isAddClientSingleModal = false;
            this.clientSingleModalInfo.info = ''
        },
    },

    data() {
        return {
            clients: null,
            desktop: true,
            mobile: false,
            mobileEuro: false,
            desktopEuro: false,
            mobileDiesel: false,
            desktopDiesel: false,
            isAddClientModal: false,
            isAddClientSingleModal: false,
            newClients: [],
            clientAddedSuccessMessage: false,
            clientAddedFailedMessage: false,
            sortedByCost1: false,
            clientSingleModalInfo: {
                name: "",
                city: "",
                info: []
            },
            newClient: {
                name: null,
                id: null,
                url: null,
                place: null
            }
        };
    },

    mounted() {
        fetch(`${store.state.serverAddress}api/v1/nightwatch/readAll`, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        })
            .then((res) => res.json())
            .then((res) => {
                console.log("yep", res.data);
                this.clients = res.data;
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
</script>

<style scoped>
body {
    margin: 0 auto;
}

table {
    margin-top: 30px;
    width: 100vw;
}

article {
    position: fixed;
    max-width: 400px;
    height: 350px;
    top: 50%;
    margin-top: -175px;
    left: 50%;
    margin-left: -200px;
    z-index: 999999999999;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.client__url {
    max-width: 140px;
    overflow-x: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
table > tbody > tr {
    height: 70px !important;
}

th {
    overflow: hidden;
    height: 70px;
}

.navBarStack {
    display: none;
}

button {
    position: relative;
    z-index: 99999;
}

.loading-container button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    outline: unset;
}

.button {
    outline: none;
    border: none;
    background: black;
    cursor: pointer;

    padding: 5px;
    border-radius: 50px;

    color: #fff;
    font-size: 13px;
    /* bottom: 0;
 left: 50%; */
    width: 70px;
    height: 22px;
    margin-right: 5px;
    margin-top: 5px;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: none;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.custom-modal::before {
    background: black;
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    content: "";
    height: 100%;
    width: 100%;
}

.modal-box {
    background-color: white;
    width: 40vw;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    border-radius: 10px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .modal-box {
        margin-top: 150px;
    }
}

.modal-box select {
    z-index: 999999999;
}

.modal-box.campaign {
    overflow: hidden;
    max-height: 40vw;
    min-height: 400px;
}

@media screen and (max-width: 600px) {
    .modal-box {
        width: 95vw;
    }

    .modal-box.campaign {
        width: 95vw;
    }

    .modal-box.campaign li {
        font-size: 13px;
    }
}

.addCampaign-button {
    height: 40px;
    padding: 8px;
}

.modal-box__top {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 70px;
    background: #6f7074;
}

.modal-box__top h3 {
    color: white;
    margin-left: 15px;
}

.modal-box__top button {
    margin-right: 8px;
}

.modal-box.small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.modal-box .datepicker {
    z-index: 9999999;
    border-radius: 50px;
}

.search {
    background: #6f7074;
    color: white;
}

.modal-box::after {
    content: "";
    position: absolute;
    opacity: 0.3;
    /* background: url('/img/google-ads-01.png'), white; */
    filter: opacity(0.5);
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 10px;
}

.modal-box.small::after {
    content: "";
    position: absolute;
    opacity: 0.3;
    background: white;
}

.modal-box button {
    height: 35px;
    width: 180px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    /* z-index: 9999999; */
    margin-bottom: 8px;
}

.custom-modal h1 {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 15px;
    color: black;
    z-index: 9999999;
    text-align: center;
    width: 90%;
}

thead {
    position: sticky;
    top: 0;
    background: white;
}

th {
    text-align: center;
}

.kpi {
    /* background: white; */
    /* color: #ef5032; */
}

.thirtyRed {
    color: red;
    font-weight: bolder;
}

.thirtyYellow {
    color: orange;
}

.thirtyGreen {
    color: green;
    font-weight: bolder;
}

.table-container {
    width: 100vw;
    overflow: unset;
    padding: 20px;
    display: flex;
    justify-content: center;
    z-index: 0000;
}

@media screen and (max-width: 1000px) {
    .table-container {
        overflow: scroll;
    }
}

table {
    background-color: white;
    width: 98vw;
    
}

.table {
    position: relative;
    margin-bottom: 78px;
}

.table::before {
    content: "";
    width: 100%;
    height: 15px;
    position: absolute;
    background: black;
    top: -8px;
    border-radius: 100px;
}

thead {
    background: black;
    border-radius: 100px;
}

#app > div:nth-child(2) > div > div > div.box {
    padding: 0;
}

thead > tr > th {
    padding-top: 28px;
}

thead th {
    color: white;
}

tbody tr {
    border-right: 0.2px solid gray;
    /* position: relative; */
}

tbody th {
    position: relative;
}

tbody tr:hover {
    border-left: 4px solid #ef5032;
    position: relative;
    color: black;
}

tbody tr.small:hover {
    position: unset;
    color: unset;
}

th {
    text-align: center !important;
}

tbody tr::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    left: -2px;
    height: 1px;
    background: rgba(136, 108, 108, 0.05);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

tbody tr.small::after {
    content: "";
    position: unset;
    display: unset;
    width: unset;
    bottom: unset;
    left: unset;
    height: unset;
    display: unset;
    justify-content: unset;
    align-items: unset;
}

/* tbody tr::after {
  content: '';
  position: absolute;

  width: 0;
  top: 0;
  left: -2px;
  opacity: .5;
  height: 0px;
  background: black;
  

  
} */

tbody tr:hover::before {
    height: 100%;
}

tbody tr:hover::after {
    content: url("/img/info.svg");
    height: 100%;
    color: black;
    font-style: italic;
    text-decoration: underline;
}

tbody tr.small:hover::after {
    content: unset;
    color: unset;
}

table.small {
    position: relative;
    z-index: 99999999999999;
    overflow: hidden;
    height: 100%;
    margin-top: 8px;
}

table.small tr {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

button.small {
    margin-bottom: 8px;
}

th {
    font-size: 13px;
    max-width: 208px;
    font-weight: 600;
}

tr {
    height: 70px !important;
    cursor: pointer;
}

.input-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    background: whitesmoke;
    border-radius: 20px;
    padding: 15px;
    position: relative;
}

.addCampaignButton-container {
    width: 30%;
    display: flex;
    justify-content: flex-start;
}

.addCampaignButton {
    border-radius: 100px;
    padding: 15px;
    font-size: 12px;
    font-weight: 400px;
    color: white;
    background: #000;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    float: right;
    margin-top: 5px;
}

.addCampaignButton:hover {
    background: rgb(239, 80, 50);
    color: white;
}

@media screen and (max-width: 1000px) {
    .input-container {
        flex-direction: column-reverse;
    }

    .input-container input#dropdown-input {
        width: 100%;
    }

    .addCampaignButton-container {
        width: 70%;
        margin-top: 20px;
    }

    .addCampaignButton {
        width: 100%;
    }
}

.kpi-header {
    width: 30%;
    font-size: 30px;
    padding-left: 5px;
    font-weight: 500;
    visibility: hidden;
}

.input-container #dropdown-input {
    height: 45px;
    background: white;
    border-radius: 50px;
    color: black;
    width: 80%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 12px;
    border: 1px solid black;
}

.input-container #dropdown-input.small {
    width: 300px;
}

.generator {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
}

.key-generator button {
    border-radius: 100px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400px;
    color: black;
    background: white;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    float: right;
    margin-top: 5px;
}

.close {
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400px;
    color: black;
    background: white;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    width: 100px;
}

.close:hover {
    background: black;
    color: white;
}

.key-generator button:hover {
    background: rgb(239, 80, 50);
    color: white;
}

.snippet-container {
    display: flex;
    align-items: center;
}

.snippet {
    background: whitesmoke;
    border: 1px solid lightgray;
    border-radius: 80px;
    padding: 2px;
}

.cost {
    position: relative;
    font-weight: 600;
}

.cost.seven::before {
    content: "7 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.thirty::before {
    content: "30 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.one::before {
    content: "7 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.one::before {
    content: "1 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.callthirty::before {
    content: "30 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}
.cost.callseven::before {
    content: "7 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.callone::before {
    content: "1 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.data-text {
    color: black;
    font-size: 14px;
}
.custom-modal .modal-box.trakcers .tracker-text p {
    color: black;
    font-size: 18px;
}

.modal-box.campaign button {
    margin-bottom: 8px;
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    color: #222222;
}

/* Dropdown */

.dropdown-list {
    display: inline-block;
    position: relative;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-input.small {
    width: 100%;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
}

.dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    margin: 0 auto;
}

.dd-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dd-button:hover {
    background-color: #eeeeee;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    height: 300px;

    overflow: scroll;
}

.dd-input + .dd-menu {
    display: none;
}

.dd-input:checked + .dd-menu {
    display: block;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

button.get-data {
    height: 45px;
    border-radius: 10px;
}

.modal-button-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.modal-button-container button {
    background: #ef5032;
    color: white;
    height: 45px;
    border-radius: 50px;
    width: 350px;
}

.send {
    position: relative;
    cursor: pointer;
    z-index: 999999999999999999999;
    background: black;
    color: white;
}

.update-text {
    height: 45px;
    display: flex;
    align-items: center;
}

.add-account {
    position: relative;
    padding: 8px;
    border-radius: 10px;
    z-index: 99999;
}

.tracker-container {
    width: 100%;
    background: #ef5032;
    color: white;
    text-align: center;
}

.tracking-info {
    width: 98%;
    display: flex;
    font-size: 13px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
}

.choose-campaign {
    position: relative;
    z-index: 9999;
    width: 250px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    text-align: center;
    border: none;
    background: whitesmoke;
}

.campaign-text {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.campaign-container {
    position: relative;
    z-index: 9999;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80%;
}

.dd-menu.campaign {
    position: static;
    z-index: 999999;
    width: 380px;
    height: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.dd-menu.campaign .modal-box__top {
    min-height: 45px;
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.full {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.full #loading {
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 5px solid #ef5032;
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #ef5032;
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.message {
    height: 200px;
    width: 95%;
    text-align: center;
}

.tracker-text {
    font-size: 14px;
    width: 85%;
    text-align: center;
    margin: 0 auto;
}

.sure-buttons {
    margin: 0 auto;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.sure-buttons.paused {
    height: 100px;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.right-buttons button.delete {
    margin-bottom: 0;
}

.back-button {
    position: relative;
    z-index: 99999;
    cursor: pointer;
    fill: white;
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.pause {
    position: absolute;
    height: 25px;
    left: 10px;
    bottom: 10px;
    cursor: pointer;
    z-index: 999999;
}

.pause-success {
    height: 100px;
}

.paused-text {
    text-align: center;
    margin-top: 8px;
    width: 70%;
}

.client-url {
    max-width: 80px;
    overflow-x: scroll;

    z-index: 9999999;
}

.client-city {
    font-size: 12px;
    font-weight: bold;
}

.loading-container {
    width: 100vw;
    height: 95vh;
    background: #ef5032;
}

.centering {
    width: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@-webkit-keyframes loader {
    0% {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(120px);
        -moz-transform: translateX(120px);
        -ms-transform: translateX(120px);
        -o-transform: translateX(120px);
        transform: translateX(120px);
    }
}

@keyframes loader {
    0% {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        -ms-transform: translateX(0px);
        -o-transform: translateX(0px);
        transform: translateX(0px);
    }

    50% {
        -webkit-transform: translateX(120px);
        -moz-transform: translateX(120px);
        -ms-transform: translateX(120px);
        -o-transform: translateX(120px);
        transform: translateX(120px);
    }
}

.loaderContainer {
    width: 160px;
    margin: 0 auto;
    position: relative;
}

.loaderContainer .loaderBG {
    background: black;
    width: 160px;
    height: 35px;
    border-radius: 2px;
    float: left;
}

.loaderContainer .loaderCircle {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    float: left;
    position: absolute;
    top: -3px;
    /* calling our animation keyframe */
    -webkit-animation: loader 2300ms infinite ease-in-out;
    -moz-animation: loader 2300ms infinite ease-in-out;
    -ms-animation: loader 2300ms infinite ease-in-out;
    -o-animation: loader 2300ms infinite ease-in-out;
    animation: loader 2300ms infinite ease-in-out;
}

.footer__container {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100vw;
    height: 80px;
    background: whitesmoke;
    display: flex;
    align-items: center;
    z-index: 9999999999;
}

.footer__item {
    cursor: pointer;
    position: relative;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    z-index: 9999999999;
    color: rgb(111, 112, 116);
    padding: 14px;
    border-right: 1px solid lightgray;
}

.footer__item:hover:not(.active) {
    background: white;
    color: black;
    transition-duration: 0.5s;
}

.footer__plus {
    width: 40px;
    margin-left: 20px;
    cursor: pointer;
}

.active {
    color: white;
    background: rgb(111, 112, 116);
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.9);
    transition-duration: 0.5s;
}

svg.active {
    fill: white;
}

.desktop::before {
    position: absolute;
    content: "";
    background-image: url("/img/desktop.svg");
    background-size: contain;
    width: 18px;
    height: 15px;
    right: 0px;
    top: 10px;
    background-repeat: no-repeat;
}

.mobile::before {
    position: absolute;
    content: "";
    background-image: url("/img/phone.svg");
    background-size: contain;
    width: 18px;
    height: 13px;
    right: 0px;
    top: 10px;
    background-repeat: no-repeat;
}

.client-modal {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 0;
    background: white;
    z-index: 99999999999;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    animation: slideLeft 0.5s forwards;
    -webkit-animation: slideLeft 0.5s forwards;
}



@-webkit-keyframes slideLeft {
    100% {
        width: 39.8vw;
    }
}

@keyframes slideLeft {
    100% {
        width: 39.8vw;
    }
}

.client-modal-single {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 0;
    background: black;
    color: white;
    z-index: 99999999999;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    animation: slideRight 0.5s forwards;
    -webkit-animation: slideRight 0.5s forwards;
    
}

@-webkit-keyframes slideRight {
    100% {
        width: 19.8vw;
    }
}

@keyframes slideRight {
    100% {
        width: 19.8vw;
    }
}

.client-modal__header {
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    width: 100%;
    background: rgb(111, 112, 116);
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: rgb(111, 112, 116);
    position: relative;
    z-index: -5;
    font-size: 14px;
    animation: word 2s forwards;
    -webkit-animation: word 2s forwards;
}



@-webkit-keyframes word {
    100% {
        color: white;
    }
}

@keyframes word {
    100% {
        color: white;
    }
}




.client-modal__header .delete {
    border-radius: 0;
    height: 60px;
    width: 60px;
    max-width: unset;
    max-height: unset;
}

.client-modal__header h3 {
    padding: 12px;
}

.client-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: black;
    z-index: 9999;
    opacity: 0.5;
}

.client-modal__container {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-box {
    width: 100%;
    height: 700px;
    border: 1px solid lightgray;
    border-radius: 3px;
    overflow-x: scroll;
}

.add-box.manual {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 200px;
}

.add-box.manual input {
    margin-top: 14px;
    height: 40px;
    border: none;
    border-bottom: 1px solid black;
    outline: none;
}

.add-box.manual p {
    margin-top: 8px;
        animation: para 2s forwards;
    -webkit-animation: para 2s forwards;
    color: white;
}

@-webkit-keyframes para {
    100% {
        color: black;
    }
}

@keyframes para {
    100% {
        color: black;
    }
}


.add-box.manual button {
    width:250px;
    margin: 0 auto;
    margin-top: 15px;
    padding: 8px;
    background: white;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: .5s;
     animation: button 4s forwards;
    -webkit-animation: button 4s forwards;

}

@-webkit-keyframes button {
    100% {
    background: #EE5023;
    color: white;
    }
}

@keyframes button {
    100% {
    background: #EE5023;
    color: white;
    }
}


.add-box.manual button:hover {
    background: white;
    color: #EE5023;
    border: 1px solid #EE5023;

}

.add-box.manual .alert {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
}

.add-box__client-names {
    height: 120px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.add-box__client-names:hover {
    background: whitesmoke;
}

.info-container {
    border-bottom: 1px solid white;
    margin-bottom: 15px;
    padding: 15px;

}

.info-container__device {
    text-align: right;
    margin-bottom: 15px;
}

.info-container__city {
    font-size: 10px;
    font-weight: bolder;
    margin: 8px 0 8px 0;

}
</style>