






































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

// import API
import {
    customerReadPretty,
    customerSetCategory,
    customerSetMainWebsite,
} from "../../apiClient/customerEndpoints";

// import components
import CampaignView from "@/components/campaign/CampaignView.vue";
import { statusListForCustomer } from "@/apiClient/statusEndpoints";
import { CONSTANTS } from "@/apiClient/constants";

@Component({
    components: { CampaignView },
})
export default class CustomerView extends Vue {
    private CONSTANTS = CONSTANTS;

    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;
    private isLoadingProcessNekeyto: boolean = false;

    private overrideSetCategory: boolean = false;

    private mainWebsiteInput: string = "";

    // BUTTON
    private btnLoad?: HTMLButtonElement;

    // MOUNTED
    public mounted() {
        console.log("mounted()");

        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement>document.getElementById("btnLoad");

        this.readCustomer();
    }

    // BUTTON BINDS
    public async readCustomer() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            await customerReadPretty(
                this.$store.state.customerCache.customerId
            );
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    }
    public async setCustomerCategory(customerId: string, categoryId: number) {
        await customerSetCategory(customerId, categoryId);
        await this.readCustomer();
        this.overrideSetCategory = false;
        await this.$forceUpdate();
    }
    public async setCustomerMainWebsite(customerId: string) {
        await customerSetMainWebsite(customerId, this.mainWebsiteInput);
        await this.readCustomer();
        await this.$forceUpdate();
    }

        public async setCustomerNewWebsite(customerId: string) {

        console.log(this.$store.state.newWebsite.site);
        await customerSetMainWebsite(customerId, this.$store.state.newWebsite.site);
        await this.readCustomer();
        await this.$forceUpdate();
    }

    public async navigateBack() {
        this.$store.commit("removeComponent", {
            page: "customer",
            name: "CustomerView",
        });
        this.$store.commit("addComponent", {
            page: "customer",
            name: "CustomerList",
        });
    }
    public async processInNekeyto() {
        this.isLoadingProcessNekeyto = true;
        let result = await statusListForCustomer(
            this.$store.state.customerCache.customer.customerId
        );
        if (result.length > 0) {
            this.$router.push("/nekeyto");
        }
        this.isLoadingProcessNekeyto = false;
    }

    public canChangeCategory() {
        for (let campaign of this.$store.state.customerCache.customer
            .campaigns) {
            if (campaign.campaignTypeId) {
                return false;
            }
        }
        return true;
    }
    public formatWebsite() {
        return `${this.$store.state.customerCache.customer.mainWebsite}`;
    }
    public visitWebsite(url: string) {
        // @ts-ignore
        window.open(url, "_blank").focus();
    }

    public isDateBad(date: string) {
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (diffDays > 14) {
            return true;
        }
        return false;
    }
    public isDateMedium(date: string) {
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (diffDays <= 14 && diffDays > 7) {
            return true;
        }
        return false;
    }
    public isDateGood(date: string) {
        let todayDate: any = new Date();
        let thenDate: any = new Date(date);
        const diff: any = Math.abs(todayDate - thenDate);
        const diffDays: any = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (diffDays <= 7) {
            return true;
        }
        return false;
    }

    // public
}
