<template>
    <!-- THIS DIV IS SACRED -->

    <div>
        <!-- CONTAINER -->
        <div class="container mb-2">
            <div v-if="filteredGoogleAdsData.length > 0">
                <div
                    v-for="ads in filteredGoogleAdsData"
                    v-bind:key="ads[0].cost_micros"
                >
                    <p>
                        {{
                            (ads[0].metrics.cost_micros / 1000000)
                                .toFixed(2)
                                .toString()
                                .split(",")
                                .reduce((total, num) => {
                                    return total + Math.round(num);
                                })
                        }}
                    </p>
                </div>
            </div>

            <div class="input-container" id="input-container">
                <div v-if="addCampaign.modal === true" class="custom-modal">
                    <div class="modal-box campaign">
                        <div class="modal-box__top">
                            <h3>Add new campaign</h3>

                            <button
                                @click="closeCampaignModal()"
                                class="delete is-large"
                            ></button>
                        </div>

                        <div
                            class="campaign-container"
                            v-if="addCampaign.list.length > 0"
                        >
                            <input
                                v-if="addCampaign.search === true"
                                class="choose-campaign"
                                v-model="addCampaign.chooseCampaign"
                                placeholder="choose campaign"
                            />

                            <ul
                                v-if="
                                    addCampaign.list.length > 0 &&
                                    addCampaign.campaignName.length <= 0 &&
                                    campaignSuccess === false &&
                                    campaignWarning === false
                                "
                                class="dd-menu campaign"
                            >
                                <li
                                    v-for="campaign in filteredNewCamps(
                                        addCampaign.list
                                    )"
                                    :key="campaign.id"
                                    @click="
                                        setNewCampaign(
                                            campaign.id,
                                            campaign.descriptive_name,
                                            campaign.resource_name
                                        )
                                    "
                                >
                                    {{ campaign.descriptive_name }}
                                </li>

                                <li class="divider"></li>
                            </ul>

                            <div
                                class="campaign-text"
                                v-else-if="
                                    addCampaign.campaignName.length > 0 &&
                                    campaignSuccess === false &&
                                    campaignWarning === false
                                "
                            >
                                <p>
                                    Click button to add campaign:
                                    {{ addCampaign.campaignName }}
                                </p>
                            </div>

                            <article
                                v-else-if="campaignSuccess === true"
                                class="message is-success"
                            >
                                <div class="message-header">
                                    <p>Success</p>
                                </div>
                                <div class="message-body">
                                    You did it! Refresh the screen and you'll
                                    see the new campaign is set in Nekeyto.
                                </div>
                            </article>

                            <article
                                v-else-if="campaignWarning === true"
                                class="message is-warning"
                            >
                                <div class="message-header">
                                    <p>Warning</p>
                                </div>
                                <div class="message-body">
                                    Whoops. Looks like this account does not
                                    have any campaigns set up in Google Ads yet.
                                </div>
                            </article>

                            <button
                                v-if="
                                    campaignSuccess === false &&
                                    campaignWarning === false
                                "
                                v-bind:class="{
                                    'is-loading': addCampaign.loading,
                                }"
                                class="button addCampaign-button"
                                @click="addNewCampaign()"
                            >
                                Add Campaign
                            </button>
                        </div>
                        <div class="loading-container" v-else>
                            <div id="loading"></div>
                        </div>
                    </div>
                </div>

                <!-- <p class="kpi-header">LNM KPI</p> -->

                <input
                    v-model="$store.state.searchHolder"
                    id="dropdown-input"
                    class="dropdown-input"
                    placeholder="Enter client name"
                />
                <!-- <div class="generator">
                    <div class="key-generator" v-if="isValidated === false">
                        <div v-if="snippet.length === 0">
                            <button @click="generateSnippet()">
                                Generate Key
                            </button>
                        </div>
                        <div class="snippet-container" v-else>
                            <p class="snippet">{{ snippet }}</p>
                            <button v-on:click="validateSnippet()">
                                Validate
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        <p class="snippet-text">
                            <span class="snippet-text__span"
                                >Add
                                <span class="snippet">{{ snippet }}</span> to
                                <strong>CallRail</strong> and
                                <strong>GoogleAds</strong> campaign</span
                            >
                            <button class="close" @click="reset()">
                                Close
                            </button>
                        </p>
                    </div>
                </div> -->
            </div>

            <div class="box">
                <div v-if="customers.length > 0">
                    <!-- TABLE -->
                    <div class="table-container">
                        <table class="table has-sticky-header is-striped">
                            <thead>
                                <tr>
                                    <th @click="filteredByName()">Account</th>

                                    <th @click="check()">Campaign Name</th>

                                    <th @click="filteredByCost('kpiOne')">
                                        CPL 1
                                    </th>
                                    <th @click="filteredByCost('kpiTwo')">
                                        CPL 7
                                    </th>
                                    <th @click="filteredByCost('kpiThree')">
                                        CPL 30
                                    </th>

                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('lastOneDayCost')
                                        "
                                    >
                                        1 Day Cost
                                    </th>
                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('lastSevenDayCost')
                                        "
                                    >
                                        7 Day Cost
                                    </th>
                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('lastThirtyDayCost')
                                        "
                                    >
                                        30 Day Cost
                                    </th>

                                    <th
                                        class="cost"
                                        @click="filteredByCost('oneDayCalls')"
                                    >
                                        1 Day Calls
                                    </th>
                                    <th
                                        class="cost"
                                        @click="filteredByCost('sevenDayCalls')"
                                    >
                                        7 Day Calls
                                    </th>
                                    <th
                                        class="cost"
                                        @click="
                                            filteredByCost('thirtyDayCalls')
                                        "
                                    >
                                        30 Day Calls
                                    </th>
                                    <th @click="sortedThirty()">30/30</th>
                                    <th @click="filteredByCost('yearOverYear')">
                                        Year/Year
                                    </th>

                                    <th @click="filteredByCost('cpc')">CPC</th>
                                    <th @click="filteredByCost('impressions')">
                                        IMP
                                    </th>
                                    <th @click="filteredByCost('ctr')">CTR</th>
                                    <th @click="filteredByCost('realClicks')">
                                        CLICKS
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <div v-if="showModal === true">
                                    <div class="custom-modal">
                                        <div
                                            v-if="choice === true"
                                            class="modal-box"
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign: {{ currClient }}
                                                </h3>
                                                <button
                                                    @click="closeModal()"
                                                    class="delete is-large"
                                                ></button>
                                            </div>

                                            <button
                                                @click="getPastData()"
                                                class="get-data"
                                            >
                                                Get Data
                                            </button>
                                            <div class="status">
                                                <button
                                                    v-if="
                                                        removeFromAudit ===
                                                        false
                                                    "
                                                    @click="
                                                        removeFromAuditTable()
                                                    "
                                                >
                                                    Unpause
                                                </button>
                                                <p
                                                    v-if="
                                                        removeFromAudit === true
                                                    "
                                                >
                                                    Campaign is no longer paused
                                                    inside of Nekeyto
                                                </p>
                                            </div>

                                            <div class="modal-button-container">
                                                <!-- <button v-bind:class="{'is-loading': laodingSearchTermProcess}" class="button is-link is-fullwidth my-2" @click="searchTermProcess()"> -->

                                                <button
                                                    @click="getTracker()"
                                                    v-bind:class="{
                                                        'is-loading':
                                                            isTrackingLoading,
                                                    }"
                                                    class="
                                                        button
                                                        is-fullwidth
                                                        my-2
                                                    "
                                                >
                                                    Update/View Call Rail
                                                    Tracking Number (In Nekeyto)
                                                </button>
                                                <button
                                                    @click="addAccount()"
                                                    class="button"
                                                >
                                                    Update/View Call Rail
                                                    Account Number (In Nekeyto)
                                                </button>
                                            </div>
                                        </div>
                                        <div
                                            v-if="addTracker === true"
                                            class="modal-box trackers"
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign: {{ currClient }}
                                                </h3>
                                                <div class="right-buttons">
                                                    <img
                                                        src="/img/back.svg"
                                                        alt="back"
                                                        @click="
                                                            back(
                                                                currClient,
                                                                currNumber,
                                                                acctNum
                                                            )
                                                        "
                                                        class="back-button"
                                                    />

                                                    <button
                                                        @click="closeModal()"
                                                        class="delete is-large"
                                                    ></button>
                                                </div>
                                            </div>

                                            <label
                                                v-if="sure === false"
                                                class="dropdown-list"
                                            >
                                                <div
                                                    v-if="currAccountNum > 0"
                                                    @click="searchAccount = ''"
                                                    class="dd-button"
                                                >
                                                    Select new tracker
                                                </div>
                                                <article
                                                    v-else
                                                    class="message is-warning"
                                                >
                                                    <div class="message-header">
                                                        <p>Warning</p>
                                                    </div>
                                                    <div class="message-body">
                                                        Whoops. No account
                                                        number is hooked up to
                                                        this Google ads campaign
                                                        yet.
                                                    </div>
                                                </article>
                                                <!-- <button>Back</button> -->
                                                <div v-if="sure === false">
                                                    <input
                                                        type="checkbox"
                                                        class="dd-input"
                                                        id="test"
                                                    />

                                                    <ul
                                                        v-if="
                                                            trackers.length > 0
                                                        "
                                                        class="dd-menu"
                                                    >
                                                        <input
                                                            v-model="
                                                                searchAccount
                                                            "
                                                            id="dropdown-input"
                                                            class="
                                                                dropdown-input
                                                                small
                                                            "
                                                            placeholder="Search tracking numbers by number name"
                                                        />

                                                        <li
                                                            v-for="tracker in filteredCrAccounts(
                                                                trackers
                                                            )"
                                                            :key="tracker[1]"
                                                            @click="
                                                                setTrackerInfo(
                                                                    tracker[1],
                                                                    tracker[0]
                                                                )
                                                            "
                                                        >
                                                            <strong
                                                                >Company:</strong
                                                            >
                                                            {{ tracker[3] }} |
                                                            <strong
                                                                >Name:</strong
                                                            >
                                                            {{ tracker[0] }} |
                                                            <strong
                                                                >Track #</strong
                                                            >
                                                            {{ tracker[2] }}
                                                        </li>

                                                        <li
                                                            class="divider"
                                                        ></li>
                                                    </ul>
                                                    <div
                                                        class=""
                                                        v-if="
                                                            trackerId.length > 0
                                                        "
                                                    >
                                                        <p class="tracker-text">
                                                            You are connecting
                                                            tracking number
                                                            {{
                                                                trackerInfo.trackingNumber
                                                            }}
                                                            from ({{
                                                                callRailName
                                                            }}) campaign to this
                                                            Google Ads campaign
                                                            <br />
                                                            <strong>
                                                                Are you
                                                                sure?</strong
                                                            >
                                                        </p>
                                                        <div
                                                            class="sure-buttons"
                                                        >
                                                            <button
                                                                @click="
                                                                    sure = true
                                                                "
                                                            >
                                                                Yes
                                                            </button>
                                                            <button
                                                                @click="
                                                                    closeModal()
                                                                "
                                                            >
                                                                No
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>

                                            <div v-if="sure === true">
                                                <button
                                                    v-if="
                                                        trackerSuccess === false
                                                    "
                                                    class="send"
                                                    @click="addTrackerId()"
                                                >
                                                    Send Tracker
                                                </button>
                                                <article
                                                    v-if="
                                                        trackerSuccess === true
                                                    "
                                                    class="message is-success"
                                                >
                                                    <div class="message-header">
                                                        <p>Success</p>
                                                    </div>
                                                    <div class="message-body">
                                                        CallRail tracking number
                                                        has been updated
                                                    </div>
                                                </article>
                                            </div>
                                            <div
                                                class="tracker-container"
                                                v-if="
                                                    trackerInfo.companyName
                                                        .length > 0
                                                "
                                            >
                                                <p>Current Info</p>
                                                <div class="tracking-info">
                                                    <div>
                                                        <p>
                                                            Company Name:
                                                            {{
                                                                trackerInfo.companyName
                                                            }}
                                                        </p>
                                                        <p>
                                                            Number Name:
                                                            {{
                                                                trackerInfo.campaignName
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            Tracking Numbers:
                                                            {{
                                                                trackerInfo.trackingNumber
                                                            }}
                                                        </p>
                                                        <p>
                                                            Forwards To:
                                                            {{
                                                                trackerInfo.destinationNumber
                                                            }}
                                                        </p>
                                                        <p>
                                                            Status:
                                                            {{
                                                                trackerInfo.status
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="modal-box trackers"
                                            v-if="addCrAccount === true"
                                        >
                                            <div class="modal-box__top">
                                                <h3>
                                                    Campaign: {{ currClient }}
                                                </h3>
                                                <div class="right-buttons">
                                                    <img
                                                        src="/img/back.svg"
                                                        alt="back"
                                                        @click="
                                                            back(
                                                                currClient,
                                                                currNumber,
                                                                acctNum
                                                            )
                                                        "
                                                        class="back-button"
                                                    />

                                                    <button
                                                        @click="closeModal()"
                                                        class="delete is-large"
                                                    ></button>
                                                </div>
                                            </div>
                                            <p
                                                v-if="
                                                    currAccountNum > 0 &&
                                                    crAcctMessage.length <= 0
                                                "
                                            >
                                                Current account number:
                                                {{ currAccountNum }}
                                            </p>
                                            <button
                                                @click="
                                                    areYouSureDeleteCr = true
                                                "
                                                v-if="
                                                    areYouSureDeleteCr ===
                                                        false &&
                                                    !deleteSuccess &&
                                                    !deleteWarning &&
                                                    crAcctMessage.length <= 0
                                                "
                                            >
                                                Remove
                                            </button>
                                            <article
                                                v-else-if="
                                                    deleteSuccess === true
                                                "
                                                class="message is-success"
                                            >
                                                <div class="message-header">
                                                    <p>Success</p>
                                                </div>
                                                <div class="message-body">
                                                    CallRail Number info has
                                                    been deleted
                                                </div>
                                            </article>

                                            <article
                                                v-else-if="
                                                    deleteWarning === true
                                                "
                                                class="message is-warning"
                                            >
                                                <div class="message-header">
                                                    <p>Warning</p>
                                                </div>
                                                <div class="message-body">
                                                    Whoops. Couldn't delete
                                                    CallRail info.
                                                </div>
                                            </article>

                                            <div v-else>
                                                <button
                                                    v-if="
                                                        crAcctMessage.length <=
                                                        0
                                                    "
                                                    @click="deleteCrInfo()"
                                                >
                                                    Yes</button
                                                ><button
                                                    v-if="
                                                        crAcctMessage.length <=
                                                        0
                                                    "
                                                    @click="closeModal()"
                                                >
                                                    No
                                                </button>
                                            </div>
                                            <p
                                                v-if="
                                                    currAccountNum.length <= 0
                                                "
                                            >
                                                No account number on this
                                                campaign is set
                                            </p>
                                            <input
                                                v-if="crAcctMessage.length <= 0"
                                                v-model="crAcctNum"
                                                class="add-account"
                                                placeholder="Add Call Rail Account"
                                            />
                                            <div
                                                v-if="crAcctMessage.length > 0"
                                            >
                                                <article
                                                    class="message is-success"
                                                >
                                                    <div class="message-header">
                                                        <p>Success</p>
                                                    </div>
                                                    <div class="message-body">
                                                        CallRail account number
                                                        has been added
                                                    </div>
                                                </article>
                                            </div>
                                            <button
                                                v-if="
                                                    crAcctNum.length === 9 &&
                                                    crAcctMessage.length <= 0
                                                "
                                                class="send"
                                                @click="addAcctNum()"
                                            >
                                                Update
                                            </button>
                                            <p class="update-text" v-else>
                                                Located in "My Account"
                                            </p>
                                        </div>
                                        <div v-if="getData === true">
                                            <div
                                                v-if="
                                                    imp.length === 0 &&
                                                    cost.length === 0 &&
                                                    calls.length === 0
                                                "
                                                class="modal-box"
                                            >
                                                <div class="modal-box__top">
                                                    <h3>
                                                        Campaign:
                                                        {{ currClient }}
                                                    </h3>
                                                    <div class="right-buttons">
                                                        <img
                                                            src="/img/back.svg"
                                                            alt="back"
                                                            @click="
                                                                back(
                                                                    currClient,
                                                                    currNumber,
                                                                    acctNum
                                                                )
                                                            "
                                                            class="back-button"
                                                        />

                                                        <button
                                                            @click="
                                                                closeModal()
                                                            "
                                                            class="
                                                                delete
                                                                is-large
                                                            "
                                                        ></button>
                                                    </div>
                                                </div>
                                                <h3>Pick a date range</h3>
                                                <date-picker
                                                    class="datepicker"
                                                    v-model="time3"
                                                    confirm
                                                    range
                                                ></date-picker
                                                ><button
                                                    class="search"
                                                    @click="
                                                        getDaysBetweenDates()
                                                    "
                                                >
                                                    Search
                                                </button>
                                            </div>
                                            <div v-else class="modal-box small">
                                                <div class="modal-box__top">
                                                    <h3>
                                                        Campaign:
                                                        {{ currClient }}
                                                    </h3>
                                                    <div class="right-buttons">
                                                        <img
                                                            src="/img/back.svg"
                                                            alt="back"
                                                            @click="
                                                                back(
                                                                    currClient,
                                                                    currNumber,
                                                                    acctNum
                                                                )
                                                            "
                                                            class="back-button"
                                                        />

                                                        <button
                                                            @click="
                                                                closeModal()
                                                            "
                                                            class="
                                                                delete
                                                                is-large
                                                            "
                                                        ></button>
                                                    </div>
                                                </div>

                                                <table class="small">
                                                    <thead class="small">
                                                        <tr class="small">
                                                            <th>Cost</th>
                                                            <th>Calls</th>
                                                            <th>Cpc</th>
                                                            <th>Impr.</th>
                                                            <th>Ctr</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr class="small">
                                                            <th>
                                                                ${{
                                                                    this.cost.toFixed(
                                                                        2
                                                                    )
                                                                }}
                                                            </th>
                                                            <th>
                                                                {{ this.calls }}
                                                            </th>
                                                            <th v-if="cpc > 0">
                                                                ${{
                                                                    this.cpc.toFixed(
                                                                        2
                                                                    )
                                                                }}
                                                            </th>
                                                            <th v-else>0</th>
                                                            <th>
                                                                {{ this.imp }}
                                                            </th>
                                                            <th>
                                                                {{
                                                                    (
                                                                        this
                                                                            .ctr *
                                                                        100
                                                                    ).toFixed(
                                                                        2
                                                                    )
                                                                }}%
                                                            </th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <tr
                                    v-for="list of filteredClients(customers)"
                                    v-bind:key="list.id"
                                    @click="
                                        openModal(
                                            list.campaignName,
                                            list.campaignId,
                                            list.acctNum
                                        )
                                    "
                                >
                                    <!-- <div class="custom-modal-overlay" v-if="showModal === true" ></div> -->

                                    <th>{{ list.customer.customerName }}</th>
                                    <th>{{ list.campaignName }}</th>

                                    <th class="kpi">
                                        <div v-if="Number(list.kpiOne) > 0">
                                            ${{ list.kpiOne.toFixed(2) }}
                                        </div>
                                        <div v-else>
                                            <p>$0</p>
                                        </div>
                                    </th>
                                    <th class="kpi">
                                        <div v-if="Number(list.kpiTwo) > 0">
                                            ${{ list.kpiTwo.toFixed(2) }}
                                        </div>
                                        <div v-else>
                                            <p>$0</p>
                                        </div>
                                    </th>

                                    <th class="kpi">
                                        <div v-if="Number(list.kpiThree) > 0">
                                            ${{ list.kpiThree.toFixed(2) }}
                                        </div>
                                        <div v-else>
                                            <p>$0</p>
                                        </div>
                                    </th>

                                    <th class="google">
                                        ${{
                                            list.lastOneDayCost
                                                ? list.lastOneDayCost.toFixed(2)
                                                : null
                                        }}
                                    </th>
                                    <th class="google">
                                        ${{
                                            list.lastSevenDayCost
                                                ? list.lastSevenDayCost.toFixed(
                                                      2
                                                  )
                                                : null
                                        }}
                                    </th>
                                    <th class="google">
                                        ${{
                                            list.lastThirtyDayCost
                                                ? list.lastThirtyDayCost.toFixed(
                                                      2
                                                  )
                                                : null
                                        }}
                                    </th>
                                    <th class="call-rail">
                                        {{ list.oneDayCalls }}
                                    </th>
                                    <th class="call-rail">
                                        {{ list.sevenDayCalls }}
                                    </th>
                                    <th class="call-rail">
                                        {{ list.thirtyDayCalls }}
                                    </th>
                                    <th
                                        :class="
                                            list.thirtyOverThirty >= 0
                                                ? 'thirtyGreen'
                                                : list.thirtyOverThirty < 0 &&
                                                  list.thirtyOverThirty > -11
                                                ? 'thirtyYellow'
                                                : 'thirtyRed'
                                        "
                                        v-if="list.thirtyOverThirty < 3000"
                                    >
                                        {{ list.thirtyOverThirty }}%
                                    </th>
                                    <th v-else>n/a</th>
                                    <th
                                        v-if="
                                            list.yearOverYear < 3000 &&
                                            typeof list.yearOverYear ===
                                                'number'
                                        "
                                    >
                                        {{ list.yearOverYear }}%
                                    </th>
                                    <th v-else>n/a</th>

                                    <th class="google">
                                        ${{ Number(list.cpc).toFixed(2) }}
                                    </th>
                                    <th class="google">
                                        {{ list.impressions }}
                                    </th>
                                    <th class="google">
                                        {{ (list.ctr * 100).toFixed(2) }}%
                                    </th>
                                    <th class="google">
                                        {{ list.realClicks }}
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="loading-container full" v-else>
                    <h1 class="no-audit">No paused campaigns</h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { searchTermYesterday } from "../../apiClient/searchTermEndpoints";
import store from "../../store/index";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

// import API
var moment = require("moment");

export default {
    components: { DatePicker },

    computed: {
        sortedArray: function () {
            function compare(a, b) {
                if (
                    Number(a.lastOneDayCost) / Number(a.oneDayCalls) <
                    Number(b.lastOneDayCost) / Number(b.oneDayCalls)
                )
                    return -1;

                if (
                    Number(a.lastOneDayCost) / Number(a.oneDayCalls) >
                    Number(b.lastOneDayCost) / Number(b.oneDayCalls)
                )
                    return 1;
                return 0;
            }
            return this.customers.sort(compare);
        },
    },

    methods: {
        async sortedThirty() {
            this.customers = this.regSorterHolder;
            let negative = [];
            let positive = [];
            console.log(this.customers);
            for (let i = 0; i < this.customers.length; i++) {
                let currentNumber = this.customers[i].thirtyOverThirty;
                let currentCampaign = this.customers[i];
                if (currentNumber < 0) {
                    negative.push(currentCampaign);
                } else {
                    positive.push(currentCampaign);
                }
            }
            let negSort = null;
            let posSort = null;

            if (this.thirtyOverThirtySort === false) {
                //this is working at 1/2 - negative numbers first
                console.log("false");
                //this is not working ?????

                posSort = positive.sort((a, b) => {
                    return a.thirtyOverThirty - b.thirtyOverThirty;
                });
                negSort = negative.sort((a, b) => {
                    return a["thirtyOverThirty"] - b["thirtyOverThirty"];
                });

                this.customers = negSort.concat(posSort);
                this.thirtyOverThirtySort = true;
            } else {
                // this is working at 1/2 - positive numbers first
                console.log("true");
                //this is not working    ??????? positives
                posSort = positive.sort((a, b) => {
                    if (b.thirtyOverThirty > a.thirtyOverThirty) {
                        return -1;
                    } else {
                        return 1;
                    }
                    return 0;
                });
                //this is working
                negSort = negative.sort((a, b) => {
                    return b["thirtyOverThirty"] - a["thirtyOverThirty"];
                });

                console.log("positive", posSort);
                this.customers = await posSort.concat(negSort);
                this.thirtyOverThirtySort = false;
            }
        },

        async getNewData() {
            fetch(
                `${store.state.serverAddress}api/v1/campaign/readallForPaused`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((list) => {
                    let arr = [];
                    this.customers = list.data;
                    this.regSorterHolder = arr;
                })

                .catch((err) => {
                    console.log(err);
                });
        },

        async removeFromAuditTable() {
            const id = this.currNumber;

            fetch(
                `${store.state.serverAddress}api/v1/campaign/removeFromAuditTable/${id}`,
                {
                    method: "PUT",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 202) {
                        this.removeFromAudit = true;
                        this.getNewData();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async deleteCrInfo() {
            const id = this.currNumber;

            fetch(
                `${store.state.serverAddress}api/v1/campaign/deleteCr/${id}`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 202) {
                        this.deleteSuccess = true;
                    } else {
                        this.deleteWarning = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        async pauseCampaign(id) {
            fetch(
                `${store.state.serverAddress}api/v1/campaign/updateStatus/${id}`,
                {
                    method: "PUT",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        status: 2,
                    }),
                }
            );

            this.idStatus = "";
            this.status = "";
        },

        async openCampaignModal() {
            this.addCampaign.modal = true;
            this.addCampaign.search = true;
            this.getNewCampaigns();
            console.log(this.addCampaign);
        },

        async closeCampaignModal() {
            this.addCampaign.modal = false;
            this.addCampaign.campaignName = "";
            this.addCampaign.resourceName = "";
            this.addCampaign.id = "";
            this.campaignSuccess = false;
            this.campaignWarning = false;
        },

        async addNewCampaign() {
            this.addCampaign.loading = await true;
            const id = await this.addCampaign.id;
            await fetch(
                `${store.state.serverAddress}api/v1/campaign/addNewCampaignManually`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                    body: JSON.stringify({
                        id: id,
                    }),
                }
            )
                .then((res) => {
                    console.log(res);
                    if (res.status === 201) {
                        this.campaignSuccess = true;
                    } else {
                        this.campaignWarning = true;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            this.addCampaign.loading = false;
        },

        async getNewCampaigns() {
            fetch(
                `${store.state.serverAddress}api/v1/campaign/getNewAccounts`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((data) => {
                    this.addCampaign.list = data;
                    console.log("yep", this.addCampaign);
                });
        },

        async setNewCampaign(id, name, resourcName) {
            this.addCampaign.campaignName = await name;
            this.addCampaign.id = await id;
            this.addCampaign.resourceName = await resourcName;
            this.addCampaign.search = false;
            this.addCampaign.chooseCampaign = "";
            console.log(this.addCampaign);
        },

        async setTrackerInfo(tracking, name) {
            this.trackerId = await tracking;
            this.callRailName = await name;
            console.log(this.trackerId, this.currNumber);
        },

        async areYouSure() {
            this.sure = true;
            console.log(this.currNumber, this.trackerId);
        },

        getCampaignTracker(acctNum) {
            this.isTrackingLoading = true;
            fetch(
                `${store.state.serverAddress}api/v1/campaign/getTrackers/${acctNum}`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((list) => {
                    // this.trakers = list.trackers;
                    let allTracks = list.trackers;
                    let finarlArr = [];
                    for (let i = 0; i < allTracks.length; i++) {
                        let currObj = allTracks[i];
                        for (let key in currObj) {
                            let currPage = currObj[key];
                            for (let item in currPage) {
                                let currAccount = currPage[item];
                                finarlArr.push([
                                    currAccount.name,
                                    currAccount.id,
                                    currAccount.tracking_numbers,
                                    currAccount.company.name,
                                ]);
                            }
                        }
                    }
                    this.trackers = finarlArr;
                    this.isTrackingLoading = false;
                    this.addTracker = true;
                    this.choice = false;
                    console.log("this", this.trackers);
                })

                .catch((err) => {
                    console.log(err);
                });
        },

        getTracker() {
            this.choice = true;
            this.getData = false;
            this.addCrAccount = false;
            this.crAcctNum = "";
            this.getTrackerInfo();

            this.getCampaignTracker(this.acctNum);
        },

        addAccount() {
            this.choice = false;
            this.getData = false;
            this.addTracker = false;
            this.addCrAccount = true;
        },

        getPastData() {
            this.addTracker = false;
            this.choice = false;
            this.getData = true;
        },

        async back(client, number, acctNum) {
            this.closeModal();
            this.openModal(client, number, acctNum);
        },

        async openModal(client, number, acctNum) {
            this.showModal = true;
            console.log("open", this.showModal);
            this.currClient = client;
            this.currNumber = number;
            this.acctNum = acctNum;
            this.getAcctNum();
            console.log("client name:");

            var inputContainer = document.getElementById("input-container");
            inputContainer.style.zIndex = "1";

            var navbarContainer = document.getElementById("navbar");

            navbarContainer.style.zIndex = "1";

            var campaignButton = document.getElementById("addCampaignButton");

            navbarContainer.style.zIndex = "1";
            campaignButton.style.zIndex = "1";
        },

        async closeModal() {
            var navbarContainer = document.getElementById("navbar");

            navbarContainer.style.zIndex = "30";
            var navbarContainer = document.getElementById("navbar");

            navbarContainer.style.zIndex = "5";

            this.showModal = false;
            this.choice = true;
            this.addTracker = false;
            this.getData = false;
            this.acctNum = null;
            this.campaignId = null;
            this.sure = false;
            this.callRailName = "";
            this.addCrAccount = false;
            this.crAcctNum = "";
            this.crAcctMessage = "";
            this.currAccountNum = "";
            (this.currTracker = ""), (this.cost = "");
            this.cpc = "";
            this.ctr = "";
            this.imp = "";
            this.calls = "";
            this.areYouSureDeleteCr = false;
            this.deleteSuccess = false;
            this.deleteWarning = false;
            this.trackerId = "";
            for (let key in this.trackerInfo) {
                this.trackerInfo[key] = "";
            }
            this.trackerSuccess = false;
            this.removeFromAudit = false;
        },

        async getDaysBetweenDates() {
            let startDate = moment(this.time3[0].toISOString().substring(0, 10))
                .clone()
                .format("YYYY-MM-DD");
            let endDate = moment(this.time3[1].toISOString().substring(0, 10))
                .clone()
                .format("YYYY-MM-DD");
            // var now = startDate.clone(),
            // dates = [];

            // while (now.isSameOrBefore(endDate)) {
            //     dates.push(now.format("YYYY-MM-DD"));
            //     now.add(1, "days");
            // }
            // console.log("days", dates);
            // let number = await this.currNumber;
            console.log("YOYO", startDate, endDate);
            this.getCamaignByDate(startDate, endDate);
        },

        check() {
            console.log("ca", this.time3);
        },

        customFormatter(date) {
            console.log("date", date);
            return moment(date).format("MMMM Do YYYY, h:mm:ss a");
        },
        reset() {
            this.isValidated = false;
            this.snippet = "";
        },
        resetData() {
            this.calls = "";
            this.imp = "";
            this.ctr = "";
            this.cost = "";
        },

        generateSnippet() {
            fetch(`${store.state.serverAddress}api/v1/keycodes/create`, {
                method: "GET",
                mode: "cors",
                headers: {
                    Authorization: `Bearer ${store.state.auth.key}`,
                },
            })
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((key) => {
                    console.log("key", key.data);
                    this.snippet = `<${key.data}>`;
                })

                .catch((err) => {
                    console.log(err);
                });
        },

        async validateSnippet() {
            try {
                fetch(`${store.state.serverAddress}api/v1/keycodes/create`, {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                    body: JSON.stringify({
                        data: this.snippet,
                    }),
                })
                    .then((res) => {
                        console.log(res);
                        if (res.status === 201) {
                            this.isValidated = true;
                        } else {
                            this.warning = true;
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } catch (err) {
                console.log(err);
            }
        },

        async addTrackerId() {
            console.log("HELLO");
            console.log("working", this.currNumber, this.trackerId);
            fetch(`${store.state.serverAddress}api/v1/campaign/addTracker`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.key}`,
                },
                body: JSON.stringify({
                    tracker: await this.trackerId,
                    campaignId: await this.currNumber,
                }),
            })
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((data) => {
                    //
                    this.trackerSuccess = true;
                    console.log("data", data);

                    // this.filteredGoogleAdsData = data.data
                })

                .catch((err) => {
                    console.log(err);
                });
        },

        async getTrackerInfo() {
            let id = this.currNumber;
            fetch(
                `${store.state.serverAddress}api/v1/campaign/getTrackerInfo/${id}`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log("data", data);

                    this.trackerInfo.campaignName = data.name;
                    this.trackerInfo.campaingCreationDate = data.created_at;
                    this.trackerInfo.destinationNumber =
                        data.destination_number;
                    this.trackerInfo.trackingNumber = data.tracking_numbers;
                    this.trackerInfo.status = data.status;
                    this.trackerInfo.companyName = data.company.name;
                })
                .catch((err) => {
                    console.log("error getting tracking info", err);
                });
        },

        async addAcctNum() {
            console.log("working", this.crAcctNum);
            fetch(`${store.state.serverAddress}api/v1/campaign/addAcctNum`, {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${store.state.auth.key}`,
                },
                body: JSON.stringify({
                    acctNum: await this.crAcctNum,
                    campaignId: await this.currNumber,
                }),
            })
                // PARSE RESPONSE BODY
                .then((res) => {
                    console.log(res);
                    this.crAcctMessage = res.statusText;
                })

                .catch((err) => {
                    console.log(
                        "failed to update account number on campaign",
                        err
                    );
                });
        },

        async getAcctNum() {
            console.log("working", this.crAcctNum);
            let id = this.currNumber;
            fetch(
                `${store.state.serverAddress}api/v1/campaign/getAcctNum/${id}`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((data) => {
                    //
                    console.log("data", data);
                    this.currAccountNum = data.acctNum;
                    this.currTracker = data.tracker;
                })

                .catch((err) => {
                    console.log(
                        "failed to update account number on campaign",
                        err
                    );
                });
        },

        getLastThirtdays(campaignId, startDate, endDate) {
            fetch(
                `${store.state.serverAddress}api/v1/campaign/getAllDataByDate`,
                {
                    method: "GET",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((data) => {
                    //
                    console.log("data", data);

                    // this.filteredGoogleAdsData = data.data
                })

                .catch((err) => {
                    console.log(err);
                });
        },

        filteredClients(customers) {
            const search = store.state.searchHolder.toLowerCase().trim();

            if (!search) return customers;

            return customers.filter(
                (c) =>
                    c.campaignName
                        .toLowerCase()
                        .replace(/- /g, "")
                        .trim()
                        .indexOf(search) > -1 ||
                    c.customer.customerName
                        .toLowerCase()
                        .replace(/- /g, "")
                        .trim()
                        .indexOf(search) > -1
            );
        },

        filteredCrAccounts(account) {
            const search = this.searchAccount.toLowerCase().trim();

            if (!search) return account;

            return account.filter(
                (c) => c[0].toLowerCase().indexOf(search) > -1
            );
        },

        filteredNewCamps(camps) {
            const search = this.addCampaign.chooseCampaign.toLowerCase().trim();

            if (!search) return camps;

            return camps.filter(
                (c) => c.descriptive_name.toLowerCase().indexOf(search) > -1
            );
        },
        filteredByName() {
            if (!this.sortedAlph) {
                this.sortedAlph = true;
                return this.customers.sort((a, b) => {
                    if (a.customer.customerName < b.customer.customerName)
                        return -1;
                    if (a.customer.customerName > b.customer.customerName)
                        return 1;
                    return 0;
                });
            } else {
                this.sortedAlph = false;
                return this.customers.sort((a, b) => {
                    if (a.customer.customerName > b.customer.customerName)
                        return -1;
                    if (a.customer.customerName < b.customer.customerName)
                        return 1;
                    return 0;
                });
            }
        },

        filterDataByValues(type, limit) {
            console.log("hi", limit, type);
            return (this.customers = this.customers.filter((item) => {
                if (
                    typeof item[type] === "number" &&
                    Number(item[type]) > limit &&
                    item[type] !== null
                ) {
                    console.log(item[type]);
                    return item;
                }
            }));
        },

        async filteredByKpi1() {
            if (!this.sortedByKpi1) {
                this.sortedByKpi1 = true;

                return this.customers.sort((a, b) => {
                    //    return Number(a.lastOneDayCost) / Number(a.oneDayCalls) - Number(b.lastOneDayCost) / Number(b.oneDayCalls)

                    return Number(b[costType]) - Number(a[costType]);
                });
            } else {
                this.sortedByKpi1 = false;

                return this.customers.sort((a, b) => {
                    return Number(a[costType]) - Number(b[costType]);
                });
            }
        },
        async filteredByCost(costType) {
            this.customers = [...this.regSorterHolder];
            if (costType === "thirtyOverThirty") {
                if (!this.sortedByCost1 && costType) {
                    this.sortedByCost1 = true;
                    console.log("costtype", costType);

                    function sortNumber(a, b) {
                        return a[costType] - b[costType];
                    }
                    this.customers.sort(sortNumber);
                } else {
                    this.sortedByCost1 = false;

                    function sortNumber(a, b) {
                        return b[costType] - a[costType];
                    }
                    this.customers.sort(sortNumber);
                }
            } else {
                if (!this.sortedByCost1 && costType) {
                    this.sortedByCost1 = true;
                    console.log("costtype", costType);
                    return this.customers.sort((a, b) => {
                        if (a[costType] < b[costType]) return -1;

                        if (a[costType] > b[costType]) return 1;
                        return 0;
                    });
                } else {
                    this.sortedByCost1 = false;

                    return this.customers.sort((a, b) => {
                        if (a[costType] > b[costType]) return -1;
                        if (a[costType] < b[costType]) return 1;
                        return 0;
                    });
                }
            }
        },

        async filteredByKpi() {
            if (!this.kpi1) {
                this.kpi1 = true;
                return this.customers.sort((a, b) => {
                    return (
                        Number(a["lastOneDayCost"]) / Number(a["oneDayCalls"]) -
                        Number(b["lastOneDayCost"]) / Number(b["oneDayCalls"])
                    );
                    //     return -1;

                    // if ((Number(a['lastOneDayCost']) / Number(a['oneDayCalls'])) > (Number(b['lastOneDayCost']) / Number(b['oneDayCalls'])) )
                    //     return 1;
                    // return 0
                });
            } else {
                this.kpi1 = false;
                return this.customers.sort((a, b) => {
                    return (
                        Number(b["lastOneDayCost"]) / Number(b["oneDayCalls"]) -
                        Number(a["lastOneDayCost"]) / Number(a["oneDayCalls"])
                    );
                    //     return -1;
                    // if ((Number(a['lastOneDayCost']) / Number(a['oneDayCalls'])) < (Number(b['lastOneDayCost']) / Number(b['oneDayCalls'])) )
                    //     return 1;
                    // return 0
                });
            }
        },

        async getCamaignByDate(startDate, endDate) {
            console.log("hi", this.currNumber);
            fetch(
                `${store.state.serverAddress}api/v1/campaign/readbydateNew/${this.currNumber}/${startDate}/${endDate}`,
                {
                    method: "GET",
                    endpoint: "api/v1/campaign/readbydateNew",
                    mode: "cors",
                    headers: {
                        Authorization: `Bearer ${store.state.auth.key}`,
                    },
                }
            )
                // PARSE RESPONSE BODY
                .then((res) => res.json())
                .then((data) => {
                    //
                    console.log("data", data);

                    console.log("all data", data);
                    this.imp = data.imp;
                    this.cost = data.cost;
                    this.calls = data.calls;
                    this.cpc = data.cpc;
                    this.ctr = data.ctr;
                    // this.filteredGoogleAdsData = data.data
                })

                .catch((err) => {
                    console.log(err);
                });
        },
    },

    data() {
        return {
            goodList: [],
            filteredGoogleAdsData: [],
            campaign: [],
            customers: [],
            setClientName: "",
            sortedAlph: false,
            sortedByDate: false,
            sortedByKpi1: false,
            searchHolder: "",
            amount: "",
            sortedByCost1: false,
            kpi1: false,
            snippet: "",
            isValidated: false,
            warning: false,
            time1: null,
            time2: null,
            time3: null,
            campaignId: "",
            dataDates: "",
            showModal: false,
            currClient: "",
            currNumber: "",
            cost: "",
            ctr: "",
            imp: "",
            calls: "",
            choice: true,
            addTracker: false,
            getData: false,
            trackers: null,
            searchAccount: "",
            trackerId: "",
            sure: false,
            callRailName: "",
            crAcctNum: "",
            addCrAccount: false,
            crAcctMessage: "",
            currAccountNum: "",
            isTrackingLoading: false,
            currTracker: "",
            trackerInfo: {
                companyName: "",
                campaingCreationDate: "",
                campaignName: "",
                destinationNumber: "",
                trackingNumber: "",
                status: "",
            },
            addCampaign: {
                modal: false,
                campaignName: "",
                resourceName: "",
                id: "",
                list: [],
                chooseCampaign: "",
                search: false,
                loading: false,
            },
            campaignSuccess: false,
            campaignWarning: false,
            deleteSuccess: false,
            deleteWarning: false,
            areYouSureDeleteCr: false,
            trackerSuccess: false,
            thirtyOverThirtySort: false,
            regSorterHolder: [],
            removeFromAudit: false,
        };
    },

    mounted() {
        console.log("filtered", this.filteredGoogleAdsData);

        this.removeFromAudit = false;

        fetch(`${store.state.serverAddress}api/v1/campaign/readallForPaused`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        })
            // PARSE RESPONSE BODY
            .then((res) => res.json())
            .then((list) => {
                console.log("list", list.data);
                let arr = [];
                this.customers = list.data;
                this.regSorterHolder = arr;
            })

            .catch((err) => {
                console.log(err);
            });
    },
};

function isHovered() {}
</script>

<style scoped>
.navBarStack {
    display: none;
}

button {
    position: relative;
    z-index: 99999;
}

.loading-container {
}

.loading-container button {
    width: 100%;
    height: 40px;
    border-radius: 10px;
    outline: unset;
}

.button {
    outline: none;
    border: none;
    background: black;
    cursor: pointer;

    padding: 5px;
    border-radius: 50px;

    color: #fff;
    font-size: 13px;
    /* bottom: 0;
 left: 50%; */
    width: 70px;
    height: 22px;
    margin-right: 5px;
    margin-top: 5px;
}

.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    background: none;
    z-index: 9;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.custom-modal::before {
    background: black;
    opacity: 0.7;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    content: "";
    height: 100%;
    width: 100%;
}

.modal-box {
    background-color: white;
    width: 40vw;
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
    border-radius: 10px;
    position: relative;
}

@media screen and (max-width: 1000px) {
    .modal-box {
        margin-top: 150px;
    }
}

.modal-box select {
    z-index: 999999999;
}

.modal-box.campaign {
    overflow: hidden;
    max-height: 40vw;
    min-height: 400px;
}

@media screen and (max-width: 600px) {
    .modal-box {
        width: 95vw;
    }

    .modal-box.campaign {
        width: 95vw;
    }

    .modal-box.campaign li {
        font-size: 13px;
    }
}

.addCampaign-button {
    height: 40px;
    padding: 8px;
}

.modal-box__top {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 70px;
    background: #6f7074;
}

.modal-box__top h3 {
    color: white;
    margin-left: 15px;
}

.modal-box__top button {
    margin-right: 8px;
}

.modal-box.small {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.modal-box .datepicker {
    z-index: 9999999;
    border-radius: 50px;
}

.search {
    background: #6f7074;
    color: white;
}

.modal-box::after {
    content: "";
    position: absolute;
    opacity: 0.3;
    /* background: url('/img/google-ads-01.png'), white; */
    filter: opacity(0.5);
    background-position: bottom center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 10px;
}

.modal-box.small::after {
    content: "";
    position: absolute;
    opacity: 0.3;
    background: white;
}

.modal-box button {
    height: 35px;
    width: 180px;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    /* z-index: 9999999; */
    margin-bottom: 8px;
}

.custom-modal h1 {
    font-size: 22px;
    font-weight: 900;
    margin-bottom: 15px;
    color: black;
    z-index: 9999999;
    text-align: center;
    width: 90%;
}

thead {
    position: sticky;
    top: 0;
    background: white;
    z-index: 9;
}

th {
    text-align: center;
}

.kpi {
    /* background: white; */
    /* color: #ef5032; */
}

.thirtyRed {
    color: red;
    font-weight: bolder;
}

.thirtyYellow {
    color: orange;
}

.thirtyGreen {
    color: green;
    font-weight: bolder;
}

.table-container {
    width: 100vw;
    overflow: unset;
}

@media screen and (max-width: 1000px) {
    .table-container {
        overflow: scroll;
    }
}

table {
    background-color: white;
    z-index: 2;
}

.table {
    position: relative;
}

.table::before {
    content: "";
    width: 100%;
    height: 15px;
    position: absolute;
    background: #6f7074;
    z-index: 9;
    top: -8px;
    border-radius: 100px;
}

thead {
    background: #6f7074;
    border-radius: 100px;
}

#app > div:nth-child(2) > div > div > div.box {
    padding: 0;
}

thead > tr > th {
    padding-top: 28px;
}

thead th {
    color: white;
}

tbody tr {
    border-right: 0.2px solid gray;
    /* position: relative; */
}

tbody tr:hover {
    border-left: 4px solid black;
    position: relative;
    color: black;
}

tbody tr.small:hover {
    position: unset;
    color: unset;
}

tbody tr::after {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    left: -2px;
    height: 1px;
    background: rgba(136, 108, 108, 0.05);
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

tbody tr.small::after {
    content: "";
    position: unset;
    display: unset;
    width: unset;
    bottom: unset;
    left: unset;
    height: unset;
    display: unset;
    justify-content: unset;
    align-items: unset;
}

/* tbody tr::after {
  content: '';
  position: absolute;

  width: 0;
  top: 0;
  left: -2px;
  opacity: .5;
  height: 0px;
  background: black;
  

  
} */

tbody tr:hover::before {
    height: 100%;
}

tbody tr:hover::after {
    content: url("/img/info.svg");
    height: 100%;
    color: black;
    font-style: italic;
    text-decoration: underline;
}

tbody tr.small:hover::after {
    content: unset;
    color: unset;
}

table.small {
    position: relative;
    z-index: 99999999999999;
    overflow: hidden;
    height: 100%;
    margin-top: 8px;
}

table.small tr {
    border-left: 1px solid black;
    border-bottom: 1px solid black;
}

button.small {
    margin-bottom: 8px;
}

th {
    font-size: 13px;
    max-width: 208px;
    font-weight: 600;
}

tr {
    height: 70px !important;
    cursor: pointer;
}

.input-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    background: whitesmoke;
    border-radius: 20px;
    padding: 15px;
    position: relative;
}

.addCampaignButton-container {
    width: 30%;
    display: flex;
    justify-content: flex-start;
}

.addCampaignButton {
    border-radius: 100px;
    padding: 15px;
    font-size: 12px;
    font-weight: 400px;
    color: white;
    background: #000;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    float: right;
    margin-top: 5px;
}

.addCampaignButton:hover {
    background: rgb(239, 80, 50);
    color: white;
}

@media screen and (max-width: 1000px) {
    .input-container {
        flex-direction: column-reverse;
    }

    .input-container input#dropdown-input {
        width: 100%;
    }

    .addCampaignButton-container {
        width: 70%;
        margin-top: 20px;
    }

    .addCampaignButton {
        width: 100%;
    }
}

.kpi-header {
    width: 30%;
    font-size: 30px;
    padding-left: 5px;
    font-weight: 500;
    visibility: hidden;
}

.input-container #dropdown-input {
    height: 45px;
    background: white;
    border-radius: 50px;
    color: black;
    width: 80%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 12px;
    border: 1px solid black;
}

.input-container #dropdown-input.small {
    width: 300px;
}

.generator {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    text-align: center;
}

.key-generator button {
    border-radius: 100px;
    padding: 10px;
    font-size: 12px;
    font-weight: 400px;
    color: black;
    background: white;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    float: right;
    margin-top: 5px;
}

.close {
    border-radius: 10px;
    padding: 5px;
    font-size: 12px;
    font-weight: 400px;
    color: black;
    background: white;
    border: 1px solid gray;
    cursor: pointer;
    transition-duration: 0.3s;
    margin-left: 8px;
    width: 100px;
}

.close:hover {
    background: black;
    color: white;
}

.key-generator button:hover {
    background: rgb(239, 80, 50);
    color: white;
}

.snippet-container {
    display: flex;
    align-items: center;
}

.snippet {
    background: whitesmoke;
    border: 1px solid lightgray;
    border-radius: 80px;
    padding: 2px;
}

.cost {
    position: relative;
    font-weight: 600;
}

.cost.seven::before {
    content: "7 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.thirty::before {
    content: "30 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.one::before {
    content: "7 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.one::before {
    content: "1 day cost";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.callthirty::before {
    content: "30 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}
.cost.callseven::before {
    content: "7 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.cost.callone::before {
    content: "1 day calls";
    font-size: 9px;
    position: absolute;
    top: 8px;
}

.data-text {
    color: black;
    font-size: 14px;
}
.custom-modal .modal-box.trakcers .tracker-text p {
    color: black;
    font-size: 18px;
}

.modal-box.campaign button {
    margin-bottom: 8px;
}

a {
    text-decoration: none;
    color: #000000;
}

a:hover {
    color: #222222;
}

/* Dropdown */

.dropdown-list {
    display: inline-block;
    position: relative;
    z-index: 99999999;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-input.small {
    width: 100%;
    height: 45px;
    position: fixed;
    top: 0;
    left: 0;
}

.dd-button {
    display: inline-block;
    border: 1px solid gray;
    border-radius: 4px;
    padding: 10px 30px 10px 20px;
    background-color: #ffffff;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    margin: 0 auto;
}

.dd-button:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.dd-button:hover {
    background-color: #eeeeee;
}

.dd-input {
    display: none;
}

.dd-menu {
    position: absolute;
    top: 100%;
    left: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0;
    margin: 2px 0 0 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    list-style-type: none;
    height: 300px;

    overflow: scroll;
}

.dd-input + .dd-menu {
    display: none;
}

.dd-input:checked + .dd-menu {
    display: block;
}

.dd-menu li {
    padding: 10px 20px;
    cursor: pointer;
    white-space: nowrap;
}

.dd-menu li:hover {
    background-color: #f6f6f6;
}

.dd-menu li a {
    display: block;
    margin: -10px -20px;
    padding: 10px 20px;
}

.dd-menu li.divider {
    padding: 0;
    border-bottom: 1px solid #cccccc;
}

button.get-data {
    height: 45px;
    border-radius: 10px;
}

.modal-button-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.modal-button-container button {
    background: #ef5032;
    color: white;
    height: 45px;
    border-radius: 50px;
    width: 350px;
}

.send {
    position: relative;
    cursor: pointer;
    z-index: 999999999999999999999;
    background: black;
    color: white;
}

.update-text {
    height: 45px;
    display: flex;
    align-items: center;
}

.add-account {
    position: relative;
    padding: 8px;
    border-radius: 10px;
    z-index: 99999;
}

.tracker-container {
    width: 100%;
    background: #ef5032;
    color: white;
    text-align: center;
}

.tracking-info {
    width: 98%;
    display: flex;
    font-size: 13px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
}

.choose-campaign {
    position: relative;
    z-index: 9999;
    width: 250px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    text-align: center;
    border: none;
    background: whitesmoke;
}

.campaign-text {
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.campaign-container {
    position: relative;
    z-index: 9999;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 80%;
}

.dd-menu.campaign {
    position: static;
    z-index: 999999;
    width: 380px;
    height: 200px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.dd-menu.campaign .modal-box__top {
    min-height: 45px;
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.full {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.full #loading {
    display: inline-block;
    width: 150px;
    height: 150px;
    border: 5px solid #ef5032;
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

#loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #ef5032;
    border-radius: 50%;
    border-top-color: #eee;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    to {
        -webkit-transform: rotate(360deg);
    }
}

.message {
    height: 200px;
    width: 95%;
    text-align: center;
}

.tracker-text {
    font-size: 14px;
    width: 85%;
    text-align: center;
    margin: 0 auto;
}

.sure-buttons {
    margin: 0 auto;
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.right-buttons button.delete {
    margin-bottom: 0;
}

.back-button {
    position: relative;
    z-index: 99999;
    cursor: pointer;
    fill: white;
    width: 25px;
    height: 25px;
    margin-right: 15px;
}

.no-audit {
    font-size: 40px;
    font-weight: bolder;
}
</style>