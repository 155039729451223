<template>
    <!-- THIS DIV IS SACRED -->

    <div>
        <!-- CONTAINER -->
        <div class="container mb-2">
            <div class="box">
                <!-- HEADING -->
                <p class="title is-4">Good List</p>
                <p class="subtitle is-6">
                    A list of all the Good actions in the system
                </p>
                <div class="dropdown" id="dropdown" v-on:click="toggleDropdown">
                    <div class="dropdown-trigger">
                        <button
                            class="button"
                            aria-haspopup="true"
                            aria-controls="dropdown-menu3"
                        >
                            <span v-if="setClientName.length > 0">{{
                                setClientName
                            }}</span>
                            <span v-else>Get Good List By Client Name</span>

                            <span class="icon is-small">
                                <i
                                    class="fas fa-angle-down"
                                    aria-hidden="true"
                                ></i>
                            </span>
                        </button>

                    </div>

                    <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                        <div class="dropdown-content">
                           
                            <div
                                v-for="customer in filteredClients(customers)"
                                v-bind:key="customer.id"
                            >
                                <a
                                    @click="
                                        setClientName = customer.customerName
                                    "
                                    v-on:click="
                                        getGoodListByCustomerName(
                                            `${customer.customerName}`
                                        )
                                    "
                                    href="#"
                                    class="dropdown-item"
                                >
                                    <span>[{{ customer.customerName }}]</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                       <input v-model="searchHolder" id="dropdown-input" class="dropdown-input inactive" placeholder="Enter client name"/>
                <button
                    class="button is-danger is-pulled-right"
                    v-on:click="getAllGoodList"
                >
                    All of Good List
                </button>

                <div v-if="goodList.length > 0" class="">
                    <!-- TABLE -->
                    <table class="table is-fullwidth is-hoverable is-narrow">
                        <thead>
                            <tr>
                                <th
                                    title="Sort alphabetically by Text"
                                    @click="filteredByName()"
                                >
                                    text*
                                </th>
                                <th>destination</th>
                                <th @click="filteredByDate()">created</th>

                                <!-- <th>match type</th>
                                <th>status</th>
                                <th>manage</th> -->
                            </tr>
                        </thead>
                        <tfoot>
                            <tr>
                                <th
                                    title="Sort alphabetically by Text"
                                    @click="sortColumnText()"
                                >
                                    text*
                                </th>
                                <!-- <th>match type</th>
                                <th>action type</th>
                                <th>destination</th>
                                <th>status</th>
                                <th>manage</th> -->
                            </tr>
                        </tfoot>

                        <tbody>
                            <!-- TR FOR NEGATIVE ACTION -->
                            <tr v-for="list of goodList" v-bind:key="list.id">
                                <!-- TEXT -->
                                <!-- <th> {{ goodList.text }} </th> -->
                                <th>[{{ list.searchTerm.text }}]</th>
                                <!-- MATCH TYPE -->
                                <th>[{{ list.destination }}]</th>
                                <th>[{{ list.createdAt }}]</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <p>
                        It look's like there aren't any
                        <strong>Good Lists </strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { searchTermYesterday } from "../../apiClient/searchTermEndpoints";
import store from "../../store/index";

// import API


export default  ({
    
methods: {

 filteredClients(customers) {
    const search = this.searchHolder.toLowerCase().trim();

   if (!search) return customers;

   return customers.filter(c => c.customerName.toLowerCase().indexOf(search) > -1);
},
        filteredByName() {
            if (!this.sortedAlph) {
             this.sortedAlph = true;
            return this.goodList
                .sort((a, b) => {
                    if (a.searchTerm.text < b.searchTerm.text)
                        return -1;
                    if (a.searchTerm.text > b.searchTerm.text)
                        return 1;
                    return 0;
            })} else {
                this.sortedAlph = false;
             return this.goodList
                .sort((a, b) => {
                    if (a.searchTerm.text > b.searchTerm.text)
                        return -1;
                    if (a.searchTerm.text < b.searchTerm.text)
                        return 1;
                    return 0;
 
            })
            
        }
        },
            filteredByDate() {
            if (!this.sortedByDate) {
            this.sortedByDate = true;

            return this.goodList
                .sort((a, b) => {
                    if (a.searchTerm.createdAt < b.searchTerm.createdAt)
                        return -1;
                    if (a.searchTerm.createdAt > b.searchTerm.createdAt)
                        return 1;
                    return 0;
            })
            } else {
           this.sortedByDate = false;

            return this.goodList
                .sort((a, b) => {
                    if (a.searchTerm.createdAt > b.searchTerm.createdAt)
                        return -1;
                    if (a.searchTerm.createdAt < b.searchTerm.createdAt)
                        return 1;
                    return 0;
            })
    
            }
        },


    toggleDropdown() {
        document.getElementById('dropdown').classList.toggle('is-active');
        document.getElementById('dropdown-input').classList.toggle('inactive');

    },

    getAllGoodList() {
            fetch(`${store.state.serverAddress}api/v1/searchterm/goodlistAll`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(list => {
          this.goodList = list.data
        
            })
        
        .catch(err => {
            console.log(err)
        })

        

    },
        getGoodListByCustomerName(name) {
            fetch(`${store.state.serverAddress}api/v1/searchterm/getgoodlistbycustomername/${name}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },

        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(list => {
          this.goodList = list.data

            })
        
        .catch(err => {
            console.log(err)
        })

        

    }

},

    data() {

       return {
           goodList: [],
           customers: [],
           setClientName: '',
           sortedAlph: false,
           sortedByDate: false,
           searchHolder: ''
          
       }
},
         mounted() {

        // MAKE REQUEST
       fetch(`${store.state.serverAddress}api/v1/searchterm/goodlist`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(list => {
          this.goodList = list.data
        
            })
        
        .catch(err => {
            console.log(err)
        })

       fetch(`${store.state.serverAddress}api/v1/customer/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            }
        })
        // PARSE RESPONSE BODY
        .then(res => res.json())
        .then(list => {
          this.customers = list.data
        
            })
        
        .catch(err => {
            console.log(err)
        })


    }


})


</script>

<style scoped>
.dropdown {
    position: relative;
}

#dropdown-input.dropdown-input{
   margin-left: 10px;
   height: 35px;

}

#dropdown-input.dropdown-input.inactive{
    display: none;
}

.dropdown-menu {
    max-height: 500px;
    overflow: scroll;
}
</style>