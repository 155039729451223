



















import { Component, Vue } from 'vue-property-decorator';
import store from "../../store/index";

@Component
export default class UserLogin extends Vue {

    // credentials
    private email: string = "";

    public mounted() {
        let inputEmail = document.getElementById("inputEmail");
        inputEmail?.addEventListener("keydown", (e) => {
            if ((e.key === "Enter")||(e.code === "Enter")) {
                this.login();
            }
        });
    }


    // BUTTONS
    public async login() {
    
        this.$store.state.user
               await fetch(`${store.state.serverAddress}api/v1/auth/readUsers`)
                .then(res => res.json())
                .then(res => {
                
                    this.$store.state.allUsers = res.data
                    let currEmail = this.email

                    console.log('ALL USERS', res.data, currEmail)
                   const user = res.data.find((item:any) => {
                       return item.email === this.email
                    })
                      console.log('USER!!!', user)              
                    this.$store.state.currUser = user                   
                }) 
                .catch(err => {
                    console.log('error reading users')
                })

console.log('this.email', this.email)
        // GET BUTTON
        const btnLogin = document.getElementById("btnLogin");
        // SHOW LOADING
        btnLogin?.classList.add("is-loading");
        
        try {
            // PRE LOGIN
            let allowed = await this.$store.dispatch("preLogin", this.email);
            if (allowed) {
                // ACTUAL LOGIN
                await this.$store.dispatch("login", this.email);
                // CLEAR EMAIL
                this.email = "";
            }
            else {
                // TODO: notify the customer
            }
        }
        catch (error) {
            console.log("ERROR", error);
        }

        // LOADING COMPLETE
        btnLogin?.classList.remove("is-loading");
              await localStorage.setItem('access', this.$store.state.currUser.access )

    }
}
