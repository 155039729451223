















































import { Component, Vue, Watch } from "vue-property-decorator";

// import API
import {
    masterListReadAll
} from "../../apiClient/masterListEndpoints";

@Component
export default class MasterListList extends Vue {

    private masterLists = [];

    // MOUNTED
    public async mounted() {
        console.log("mounted");

        this.masterLists = await masterListReadAll();
        console.log(this.masterLists)
        console.log("mounted complete");

    }

    public async viewMasterList(masterListId: number) {
        console.log("viewMasterList()");
        this.$store.commit("updateMasterListCache", {
            masterListId: masterListId
        });
        console.log("masterListId", masterListId, this.$store.state.masterListCache.masterListId);
    }


    @Watch('$store.state.masterListCache.masterListList')
    public internalUpdate(newValue: number, oldValue: number) {
        if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
            return;
        }
        masterListReadAll().then((masterListList) => {
            this.masterLists = masterListList;
        });
    }

}
