










import { Component, Vue } from 'vue-property-decorator';

// import components
import Paused from '../components/paused/Paused.vue';

@Component({
    components: {
        Paused,
    },
})
export default class PausedPage extends Vue {};
