







































import { Component, Prop, Vue } from 'vue-property-decorator';

// import API
import { adGroupTypeReadAll } from '@/apiClient/adGroupTypeEndpoints';

@Component
export default class AdGroupTypeList extends Vue {

    @Prop()
    private adGroupTypeId!: string;

    private adGroup: any = {};

    // MOUNTED
    public mounted() {
        console.log("mounted()");
        this.readAdGroupTypeList()
    }
    public async readAdGroupTypeList() {
        await adGroupTypeReadAll();
    }

    public initEdit(adGroupTypeId: number) {
        this.$store.commit("updateAdGroupTypeCache", {
            adGroupTypeId: adGroupTypeId
        })
    };
};
