











import { Component, Vue } from 'vue-property-decorator';

// import components
import NegativeActionCreate from '@/components/negativeAction/NegativeActionCreate.vue';
import NegativeActionList from '@/components/negativeAction/NegativeActionList.vue';
// import NegativeActionManager from '@/components/negativeAction/NegativeActionManager.vue';

@Component({
    components: {
        NegativeActionCreate,
        NegativeActionList,
        // NegativeActionManager,
    },
})
export default class NegativeActionPage extends Vue {};
