






































import { masterListCreate, masterListReadAll } from "@/apiClient/masterListEndpoints";
import { Component, Prop, Vue } from "vue-property-decorator";

// import API

@Component
export default class MasterListCreate extends Vue {

    // input
    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public mounted() {
        console.log("mounted");
    }

    public async createMasterList() {
        console.log(this.inputName, this.inputDescription);
        let result = await masterListCreate(this.inputName, this.inputDescription);
        await masterListReadAll();
        console.log(result);
        this.inputName = "";
        this.inputDescription = "";
    }

}
