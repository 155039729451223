










import { Component, Vue } from 'vue-property-decorator';

// import components
import Audit from '../components/audit/Audit.vue';

@Component({
    components: {
        Audit,
    },
})
export default class AuditPage extends Vue {};
