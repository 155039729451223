








import { Component, Vue } from 'vue-property-decorator';

// import components
import CustomerView from '@/components/customer/CustomerView.vue';
import CustomerList from '@/components/customer/CustomerList.vue';
import CampaignView from '@/components/campaign/CampaignView.vue';

@Component({
    components: {
        CustomerView,
        CustomerList,
        CampaignView
    },
})
export default class CustomerPage extends Vue {};
