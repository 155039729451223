










import { Component, Vue } from 'vue-property-decorator';

// import components
import Kpi from '../components/kpi/Kpi.vue';

@Component({
    components: {
        Kpi,
    },
})
export default class KpiPage extends Vue {};
