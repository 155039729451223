







import { Component, Vue } from 'vue-property-decorator';

// import components
import UserLogin from '@/components/user/UserLogin.vue';

@Component({
    components: {
        UserLogin
    },
})
export default class LoginPage extends Vue {};
