


















































import { Component, Vue, Watch } from 'vue-property-decorator';

// import API
import { campaignTypeUpdate, campaignTypeReadAll, campaignTypeRead } from '@/apiClient/campaignTypeEndpoints';

@Component
export default class CampaignTypeEdit extends Vue {

    // private categoryId;// = this.$store.state.categoryCache.categroyId;
    private loaded: boolean = false;

    // private categoryId: number = 0;
    private inputNameCategory: string = "";
    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public async mounted() {
        // this.categoryId = this.$store.state.categoryCache.categoryId
        console.log("mounted()");
        this.resetInput();
    };

    @Watch('$store.state.campaignTypeCache.campaignTypeId')
    public internalUpdate(newValue: number, oldValue: number) {
        campaignTypeRead(newValue).then((campaignType) => {
            this.loaded = true;

            this.inputName = campaignType.name.split(":")[1];
            this.inputNameCategory = campaignType.name.split(":")[0];
            this.inputDescription = campaignType.description;
            // scroll up
            window.scrollTo(0,0);
        });

    }
    public resetInput() {
        this.inputNameCategory = "";
        this.inputName = "";
        this.inputDescription = "";
    }
    public async updateCategory() {
        try {
            if (this.inputName.length === 0) {
                return;
            }
            let fullCampaignTypeName = this.inputNameCategory+ ":" + this.inputName;
            await campaignTypeUpdate(this.$store.state.campaignTypeCache.campaignTypeId, fullCampaignTypeName, this.inputDescription);
            this.resetInput();
            this.$store.commit("setCampaignTypeCache", {
                campaignTypeId: null,
                campaignType: null,
                campaignTypeList: []
            });
            await campaignTypeReadAll();
        }
        catch(error) {
            console.log("EW")
        }

    }
};
