










import { Component, Vue } from 'vue-property-decorator';

// import components
import Process from '../components/process/Process.vue';

@Component({
    components: {
        Process,
    },
})
export default class ProcessPage extends Vue {};
