<template>
    <div id="app">
        <!-- NAVGATION -->
        <div v-if="$store.state.auth.key" class="container" id="navbar">
            <nav
                class="navbar"
                role="navigation"
                aria-label="dropdown navigation"
            >
                <div class="navbar-brand">
                    <!-- MAIN LOGO -->
                    <router-link class="navbar-item" to="/user">
                        <img
                            src="nekeyto-logo.svg"
                            height="100vh"
                            width="100vw"
                        />
                    </router-link>
                    <!-- BURGER MENU BUTTONS -->
                    <a
                        role="button"
                        @click="openNav()"
                        class="navbar-burger"
                        aria-label="menu"
                        aria-expanded="false"
                        data-target="navbarBasicExample"
                    >
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>

                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.home.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.home.includes(access)
                    "
                    class="navbar-item is-hoverable"
                >
                    <a class="navbar-link solo">
                        <router-link
                            v-if="
                                ($store.state.currUser &&
                                    navbarAccess.home.includes(
                                        $store.state.currUser.access.toString()
                                    )) ||
                                navbarAccess.home.includes(access)
                            "
                            class="navbar-item"
                            to="/home"
                            >Home</router-link
                        >
                    </a>
                </div>

                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.status.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.status.includes(access)
                    "
                    class="navbar-item is-hoverable"
                >
                    <a class="navbar-link solo">
                        <router-link
                            v-if="
                                ($store.state.currUser &&
                                    navbarAccess.status.includes(
                                        $store.state.currUser.access.toString()
                                    )) ||
                                navbarAccess.status.includes(access)
                            "
                            class="navbar-item"
                            to="/status"
                            >Status</router-link
                        >
                    </a>
                </div>
                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.negativeActions.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.negativeActions.includes(access)
                    "
                    class="navbar-item is-hoverable"
                >
                    <a class="navbar-link solo">
                        <router-link
                            v-if="
                                ($store.state.currUser &&
                                    navbarAccess.negativeActions.includes(
                                        $store.state.currUser.access.toString()
                                    )) ||
                                navbarAccess.negativeActions.includes(access)
                            "
                            class="navbar-item"
                            to="/negativeaction"
                            >Negative Actions</router-link
                        >
                    </a>
                </div>
                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.masterList.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.masterList.includes(access)
                    "
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link">
                        <router-link class="navbar-item" to="/masterlist"
                            >Master List</router-link
                        >
                    </a>

                    <div class="navbar-dropdown">
                        <a class="navbar-item">
                            <router-link class="navbar-item" to="/goodlist"
                                >Good List</router-link
                            >
                        </a>
                        <a class="navbar-item">
                            <router-link class="navbar-item" to="/badlist"
                                >Bad List</router-link
                            >
                        </a>
                    </div>
                </div>

                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.masterList.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.masterList.includes(access)
                    "
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link">
                        <router-link class="navbar-item" to="/customer"
                            >Accounts</router-link
                        >
                    </a>

                    <div class="navbar-dropdown">
                        <a class="navbar-item">
                            <router-link class="navbar-item" to="/alert"
                                >Alert</router-link
                            >
                        </a>
                        <a class="navbar-item">
                            <router-link
                                class="navbar-item"
                                to="/systemmanagement"
                                >System Management</router-link
                            >
                        </a>
                    </div>
                </div>

                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.localSeo.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.localSeo.includes(access)
                    "
                    class="navbar-item has-dropdown is-hoverable"
                >
                    <a class="navbar-link">
                        <router-link class="navbar-item" to="/kpi"
                            >KPI</router-link
                        >
                    </a>

                    <div class="navbar-dropdown">
                        <a class="navbar-item">
                            <router-link class="navbar-item" to="/audit"
                                >Audit</router-link
                            >
                        </a>
                        <a class="navbar-item">
                            <router-link class="navbar-item" to="/paused"
                                >Paused</router-link
                            >
                        </a>
                    </div>
                </div>

                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.process.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.process.includes(access)
                    "
                    class="navbar-item is-hoverable"
                >
                    <a class="navbar-link solo">
                        <router-link
                            v-if="
                                ($store.state.currUser &&
                                    navbarAccess.process.includes(
                                        $store.state.currUser.access.toString()
                                    )) ||
                                navbarAccess.process.includes(access)
                            "
                            class="navbar-item"
                            to="/process"
                            >Process</router-link
                        >
                    </a>
                </div>

                <div
                    v-if="
                        ($store.state.currUser &&
                            navbarAccess.localSeo.includes(
                                $store.state.currUser.access.toString()
                            )) ||
                        navbarAccess.localSeo.includes(access)
                    "
                    class="navbar-item is-hoverable"
                >
                    <a class="navbar-link solo">
                        <router-link
                            v-if="
                                ($store.state.currUser &&
                                    navbarAccess.localSeo.includes(
                                        $store.state.currUser.access.toString()
                                    )) ||
                                navbarAccess.localSeo.includes(access)
                            "
                            class="navbar-item"
                            to="/localseo"
                            >Local SEO</router-link
                        >
                    </a>
                </div>
                <div class="navbar-end">
                    <div class="navbar-item">
                        <div class="buttons">
                            <!-- HELP BUTTON -->
                            <router-link
                                class="button is-light is-hidden-touch"
                                to="/help"
                                >HELP</router-link
                            >
                            <router-link class="button is-light" to="/"
                                >USER</router-link
                            >
                            <div
                                class="button is-danger"
                                style="background-color: #ef5032"
                                @click="$store.dispatch('logout')"
                            >
                                LOGOUT
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
        <!-- ROUTER VIEW -->
        <router-view v-if="!$store.state.initialLoad" />
        <!-- VERSION -->
        <!-- <div class="versionInfo">BETA v0.2.8 [Changing History]</div> -->
    </div>
</template>
<script>
import store from "./store/index";

export default {
    async mounted() {
        try {
            this.access = localStorage.getItem("access");
        } catch {
            null;
        }
        console.log("ACCESS", this.access);
        //    fetch(`${store.state.serverAddress}api/v1/auth/readUsers`)
        // .then(res => res.json())
        // .then(res => {
        //     getEmail
        //     this.allUsers = res.data
        //     let currEmail = this.email

        //     console.log('ALL USERS', res.data, currEmail)
        //    const user = res.data.find(item => {
        //        return item.email === localStorage.getItem('email')
        //     })
        //       console.log('USER!!!', user)
        //    this.currUser = user
        // })
        // .catch(err => {
        //     console.log('error reading users')
        // })
    },

    data() {
        return {
            email: null,
            allUsers: null,
            currUser: null,
            navbarAccess: {
                home: ["1", "5"],
                status: ["1", "5"],
                negativeActions: ["1", "5"],
                masterList: ["1", "5"],
                accounts: ["1", "3", "5"],
                kpi: ["1", "3", "5"],
                localSeo: ["1", "3", "4", "5"],
                process: ["5"],
            },
            access: 0,
        };
    },

    methods: {
    },
};

</script>
<style>

@media screen and (min-width: 1025px) {
    .navbar-item.is-hoverable:hover .navbar-dropdown {
        display: block !important;
    }
    .navbar-item.is-hoverable:focus-within .navbar-dropdown {
        display: none;
    }
}

@media screen and (min-width: 1023px) {
    .navbar-item {
        font-size: 14px;
        height: 70px;
    }

    .navbar-link {
        padding: 8px;
    }

    .navbar-link.solo {
        padding: 4px;
    }

    .navbar-link.solo:not(.is-arrowless)::after {
        display: none;
    }

    .navbar-item:focus {

    }
}
</style>




