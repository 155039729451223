



















































































import { statusStatistics } from '@/apiClient/statusEndpoints';
import { Component, Prop, Vue } from 'vue-property-decorator';

// import API

@Component
export default class StatusStatistics extends Vue {

    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;

    // BUTTON  
    private btnLoad?: HTMLButtonElement;

    // MOUNTED
    public mounted() {
        console.log("mounted()");

        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement> document.getElementById("btnLoad");

        this.readStatistics();
    };

    // BUTTON BINDS
    public async readStatistics() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            // await apiCall here
            await statusStatistics();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    };

};
