










import { Component, Vue } from 'vue-property-decorator';

// import components
import Nightwatch from '../components/nightwatch/Nightwatch.vue';

@Component({
    components: {
        Nightwatch,
    },
})
export default class NightwatchPage extends Vue {};
