var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[(_vm.$store.state.auth.key)?_c('div',{staticClass:"container",attrs:{"id":"navbar"}},[_c('nav',{staticClass:"navbar",attrs:{"role":"navigation","aria-label":"dropdown navigation"}},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/user"}},[_c('img',{attrs:{"src":"nekeyto-logo.svg","height":"100vh","width":"100vw"}})]),_c('a',{staticClass:"navbar-burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"false","data-target":"navbarBasicExample"},on:{"click":function($event){return _vm.openNav()}}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.home.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.home.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item is-hoverable"},[_c('a',{staticClass:"navbar-link solo"},[(
                            (_vm.$store.state.currUser &&
                                _vm.navbarAccess.home.includes(
                                    _vm.$store.state.currUser.access.toString()
                                )) ||
                            _vm.navbarAccess.home.includes(_vm.access)
                        )?_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/home"}},[_vm._v("Home")]):_vm._e()],1)]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.status.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.status.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item is-hoverable"},[_c('a',{staticClass:"navbar-link solo"},[(
                            (_vm.$store.state.currUser &&
                                _vm.navbarAccess.status.includes(
                                    _vm.$store.state.currUser.access.toString()
                                )) ||
                            _vm.navbarAccess.status.includes(_vm.access)
                        )?_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/status"}},[_vm._v("Status")]):_vm._e()],1)]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.negativeActions.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.negativeActions.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item is-hoverable"},[_c('a',{staticClass:"navbar-link solo"},[(
                            (_vm.$store.state.currUser &&
                                _vm.navbarAccess.negativeActions.includes(
                                    _vm.$store.state.currUser.access.toString()
                                )) ||
                            _vm.navbarAccess.negativeActions.includes(_vm.access)
                        )?_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/negativeaction"}},[_vm._v("Negative Actions")]):_vm._e()],1)]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.masterList.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.masterList.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable"},[_c('a',{staticClass:"navbar-link"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/masterlist"}},[_vm._v("Master List")])],1),_c('div',{staticClass:"navbar-dropdown"},[_c('a',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/goodlist"}},[_vm._v("Good List")])],1),_c('a',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/badlist"}},[_vm._v("Bad List")])],1)])]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.masterList.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.masterList.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable"},[_c('a',{staticClass:"navbar-link"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/customer"}},[_vm._v("Accounts")])],1),_c('div',{staticClass:"navbar-dropdown"},[_c('a',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/alert"}},[_vm._v("Alert")])],1),_c('a',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/systemmanagement"}},[_vm._v("System Management")])],1)])]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.localSeo.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.localSeo.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item has-dropdown is-hoverable"},[_c('a',{staticClass:"navbar-link"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/kpi"}},[_vm._v("KPI")])],1),_c('div',{staticClass:"navbar-dropdown"},[_c('a',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/audit"}},[_vm._v("Audit")])],1),_c('a',{staticClass:"navbar-item"},[_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/paused"}},[_vm._v("Paused")])],1)])]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.process.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.process.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item is-hoverable"},[_c('a',{staticClass:"navbar-link solo"},[(
                            (_vm.$store.state.currUser &&
                                _vm.navbarAccess.process.includes(
                                    _vm.$store.state.currUser.access.toString()
                                )) ||
                            _vm.navbarAccess.process.includes(_vm.access)
                        )?_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/process"}},[_vm._v("Process")]):_vm._e()],1)]):_vm._e(),(
                    (_vm.$store.state.currUser &&
                        _vm.navbarAccess.localSeo.includes(
                            _vm.$store.state.currUser.access.toString()
                        )) ||
                    _vm.navbarAccess.localSeo.includes(_vm.access)
                )?_c('div',{staticClass:"navbar-item is-hoverable"},[_c('a',{staticClass:"navbar-link solo"},[(
                            (_vm.$store.state.currUser &&
                                _vm.navbarAccess.localSeo.includes(
                                    _vm.$store.state.currUser.access.toString()
                                )) ||
                            _vm.navbarAccess.localSeo.includes(_vm.access)
                        )?_c('router-link',{staticClass:"navbar-item",attrs:{"to":"/localseo"}},[_vm._v("Local SEO")]):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item"},[_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"button is-light is-hidden-touch",attrs:{"to":"/help"}},[_vm._v("HELP")]),_c('router-link',{staticClass:"button is-light",attrs:{"to":"/"}},[_vm._v("USER")]),_c('div',{staticClass:"button is-danger",staticStyle:{"background-color":"#ef5032"},on:{"click":function($event){return _vm.$store.dispatch('logout')}}},[_vm._v(" LOGOUT ")])],1)])])])]):_vm._e(),(!_vm.$store.state.initialLoad)?_c('router-view'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }