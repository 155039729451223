
































































import { adGroupTypeReadAll } from '@/apiClient/adGroupTypeEndpoints';
import { campaignTypeReadAll } from '@/apiClient/campaignTypeEndpoints';
import { categoryReadAll } from '@/apiClient/categoryEndpoints';
import { customerReadAllPretty } from '@/apiClient/customerEndpoints';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class HelpBasic extends Vue {

    // MOUNTED
    public async mounted() {
        console.log("mounted()");
        let allDataPromises = [];
        allDataPromises.push(campaignTypeReadAll());
        allDataPromises.push(categoryReadAll());
        allDataPromises.push(customerReadAllPretty());
        allDataPromises.push(adGroupTypeReadAll());
        // @ts-ignore
        await Promise.all(allDataPromises);
    }

};
