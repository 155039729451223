// import store
import store from "../store/index";

// GET api/v1/campaign/read/:campaignId
const campaignRead = async (campaignId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/read/${campaignId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaign: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.state.newestCampaigns = jsonResponse.data
    }
}
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaign: null
        });
    }
};
// GET api/v1/campaign/readall
const campaignReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaignList: []
        });
    }
};
// POST api/v1/campaign/settype
const campaignSetType = async (campaignId: string, campaignTypeId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/settype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId,
                campaignTypeId: campaignTypeId
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaignList: []
        });
    }
};
// POST api/v1/campaign/unsettype
const campaignUnsetType = async (campaignId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/unsettype`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId
            })
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: jsonResponse.data
            });
        }
        else {
            // MANIPULATE STORE
            store.commit("updateCampaignCache", {
                campaignList: []
            });
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateCampaignCache", {
            campaignList: []
        });
    }
};

// POST api/v1/campaign/getnegativekeywords
const campaignGetNegativeKeywordsCount = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/getnegativekeywords`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return 
        return false;
    }
};


const newestCampaigns = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/campaign/getNewestCampaigns`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE REPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
          console.log('yo', jsonResponse.data)
            store.state.newestCampaigns = jsonResponse.data
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return 
        return false;
    }


}

const auditList = async () => {
    fetch(
        `${store.state.serverAddress}api/v1/campaign/getThirtyDayCostForKPI/10`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        }
    )
        // PARSE RESPONSE BODY
        .then((res) => res.json())
        .then((list) => {
            console.log("list", list.data);
            store.state.auditList = list.data

        })

        .catch((err) => {
            console.log(err);
        });
}

//Newest 3/23
const updateCPLTableColumns = () => {
    fetch(`${store.state.serverAddress}api/v1/updateOneCampaign`, {
    method: "PUT",
    mode: "cors",
    headers: {
        Authorization: `Bearer ${store.state.auth.key}`,
        "Content-Type": "application/json"

    },
    body: JSON.stringify({
        id: store.state.campaignNumber,
    })
})

}


const getAcctNum = async () => {
    let id = await store.state.campaignNumber;
    fetch(
        `${store.state.serverAddress}api/v1/campaign/getAcctNum/${id}`,
        {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        }
    )
        // PARSE RESPONSE BODY
        .then((res) => res.json())
        .then((data) => {
            //
            store.state.currAccountNum = data.acctNum;
            store.state.currTracker = data.tracker;
        })

        .catch((err) => {
            console.log(
                "failed to update account number on campaign",
                err
            );
        });
}








// export
export {
    getAcctNum,
    updateCPLTableColumns,
    campaignRead,
    campaignReadAll,
    campaignSetType,
    campaignUnsetType,
    campaignGetNegativeKeywordsCount,
    newestCampaigns,
    auditList
};