





































import { Component, Vue, Watch } from 'vue-property-decorator';

// import API
import { categoryUpdate, categoryReadAll, categoryRead } from '@/apiClient/categoryEndpoints';

@Component
export default class CategoryEdit extends Vue {

    // private categoryId;// = this.$store.state.categoryCache.categroyId;
    private categoryId?: number;

    private loaded: boolean = false;

    // private categoryId: number = 0;
    private inputName: string = "";
    private inputDescription: string = "";

    // MOUNTED
    public async mounted() {
        // this.categoryId = this.$store.state.categoryCache.categoryId
        console.log("mounted()");
        this.resetInput();
    };

    @Watch('$store.state.categoryCache.categoryId')
    public internalUpdate(newValue: number, oldValue: number) {
        categoryRead(newValue).then((category) => {
            this.loaded = true;

            this.inputName = category.name;
            this.inputDescription = category.description;
            // scroll up
            window.scrollTo(0,0);
        });

    }
    public resetInput() {
        this.inputName = "";
        this.inputDescription = "";
    }
    public async updateCategory() {
        try {
            await categoryUpdate(this.$store.state.categoryCache.categoryId, this.inputName, this.inputDescription);
            this.resetInput();
            this.$store.commit("setCategoryCache", {
                categoryId: null,
                category: null,
                categoryList: []
            });
            this.loaded = false;
            await categoryReadAll();
        }
        catch(error) {
            console.log("EW")
        }

    }
};
