


































































































import { statusClear, statusList, statusReadAll } from '@/apiClient/statusEndpoints';
import { Component, Vue } from 'vue-property-decorator';

import { CONSTANTS } from "@/apiClient/constants"

// import API

@Component
export default class StatuList extends Vue {

    // LOADING
    private isLoading: boolean = false;
    private hasLoaded: boolean = false;

    private CONSTANTS = CONSTANTS;

    private lastLoadedList?: number = undefined;

    // BUTTON  
    private btnLoad?: HTMLButtonElement;

    private columnTextOrder: number = 1;

    // MOUNTED
    public mounted() {
        console.log("mounted()");

        this.hasLoaded = false;
        this.btnLoad = <HTMLButtonElement> document.getElementById("btnLoad");

        this.readALL();
    };

    // BUTTON BINDS
    public async readALL() {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            // await apiCall here
            await statusReadAll();
            this.lastLoadedList = undefined;
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    };
    public async readList(answerType: number) {
        this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            // await apiCall here
            await statusList(answerType);
            this.lastLoadedList = answerType;
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    };
    public async clearStatus(statusId: number) {this.isLoading = true;
        this.hasLoaded = false;
        this.btnLoad?.classList.add("is-loading");
        // @ts-ignore
        this.btnLoad?.disabled = true;
        try {
            // await apiCall here
            await statusClear(statusId);
            if (this.lastLoadedList) {
                await statusList(this.lastLoadedList);
            }
            else {
                await statusReadAll();
            }
            // await statusReadAll();
            this.hasLoaded = true;
        } catch (error) {
            console.log(error);
            this.hasLoaded = false;
        }
        this.isLoading = false;
        this.btnLoad?.classList.remove("is-loading");
        // @ts-ignore:
        this.btnLoad?.disabled = false;
    };

    public getCategory(categoryId: string) {
        for (let category of this.$store.state.categoryCache.categoryList) {
            // console.log(category)
            if (category.id === parseInt(categoryId)) return category.name;
        }
        return "NO CATEGORY";
    };
    public getCampaignType(campaignTypeId: string) {
        for (let campaignType of this.$store.state.campaignTypeCache.campaignTypeList) {
            if (campaignType.id === parseInt(campaignTypeId)) return campaignType.name;
        }
        return "NO CAMPAIGN TYPE";
    };
    public getAdGroupType(adGroupTypeId: string) {
        for (let adGroupType of this.$store.state.adGroupTypeCache.adGroupTypeList) {
            if (adGroupType.id === parseInt(adGroupTypeId)) return adGroupType.name;
        }
        return "NO AD GROUP TYPE";

    }

    public sortColumnText() {
        this.columnTextOrder *= -1;
        let cacheCopy = this.$store.state.statusCache.statusList;
        let compare = (a: any, b: any) => {
            if (a["searchTerm"]["text"] < b["searchTerm"]["text"]) {
                return (-1 * this.columnTextOrder);
            }
            if (a["searchTerm"]["text"] > b["searchTerm"]["text"]) {
                return (1 * this.columnTextOrder);
            }
            return 0;
        }
        cacheCopy.sort(compare);
    }

};
