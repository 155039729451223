










import { Component, Vue } from 'vue-property-decorator';

// import components
import BadList from '../components/badlist/BadList.vue';

@Component({
    components: {
        BadList,
    },
})
export default class BadListPage extends Vue {};
